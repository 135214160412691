import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { Button, H2 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import report_background from "../assets/report_background.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { PermissionsContext, PermissionsContextProps } from "@ucl/library";
import { wildfireRoutePrefix } from "./index/wildfireRouteConfig";
import { wildfireReportApiClient } from "../lib/apiClients/wildfireReportApiClient";
import { ReportReadDTO } from "../../common/types/reports";
import {
  errorStore,
  genericErrorMessage,
} from "../../common/Components/Error/ErrorStore";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

export interface WildfireReportsPageProps {}

const WildfireReportsPageComponent: React.FC<WildfireReportsPageProps> = () => {
  const navigate = useNavigate();

  const { hasPermission } = useContext(
    PermissionsContext
  ) as PermissionsContextProps<PermissionsKey>;

  const [areReportsLoading, setAreReportsLoading] = useState(true);
  const [reports, setReports] = useState<ReportReadDTO[]>();

  useEffect(() => {
    wildfireReportApiClient
      .getReports()
      .then((response) => {
        setReports(response);
      })
      .catch((error) => {
        console.log(error);
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setAreReportsLoading(false);
      });
  }, []);

  if (!hasPermission("CanViewReportsPanel")) {
    errorStore.setErrorMessage(genericErrorMessage);
    return null;
  }

  return (
    <div className="reports-page">
      {areReportsLoading || !reports ? (
        <Loading />
      ) : (
        <div className="reports-page__reports">
          {reports.map((report) => {
            return (
              <div
                key={report.slug}
                className="reports-page__reports__report-item"
              >
                <img
                  src={report_background}
                  width={492}
                  height={331}
                  alt="Report Background"
                />
                <div className="reports-page__reports__report-item__overlay-container">
                  <H2>{report.title}</H2>
                  <Button
                    minimal
                    intent="primary"
                    outlined
                    text="View Report"
                    icon={IconNames.EYE_OPEN}
                    onClick={() => {
                      navigate(`${wildfireRoutePrefix}/reports/${report.slug}`);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const WildfireReportsPage = withAITracking(
  reactPlugin,
  WildfireReportsPageComponent,
  "WildfireReportsPage",
  "tracking-container"
);
