import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts } from "../../../../../common/types/BaseHomeEvaluationInstallerComplianceAttachedStructureForm";
import { HomeStandardInstallerComplianceAttachedStructureFormContainerProps } from "./HomeStandardInstallerComplianceAttachedStructureFormContainer";

function useHomeStandardInstallerComplianceAttachedStructureFormContainer(
  props: HomeStandardInstallerComplianceAttachedStructureFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const formModel =
    evaluationFormModel?.homeStandardEvaluation
      .installerComplianceAttachedStructureForm;

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isGeneralInformationComplete) {
      return BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormAttachedStructureGeneralInformation;
    } else if (!formModel.isEngineeredAttachedStructureComplete) {
      return BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormEngineeredAttachedStructure;
    } else if (!formModel.isPrescriptiveAttachedStructureComplete) {
      return BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormPrescriptiveAttachedStructure;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.formParts) {
        return props.formParts;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormAttachedStructureGeneralInformation:
        return formModel?.isGeneralInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormEngineeredAttachedStructure:
        return formModel?.isEngineeredAttachedStructureComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormPrescriptiveAttachedStructure:
        return formModel?.isPrescriptiveAttachedStructureComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormAttachedStructureGeneralInformation,
        displayName: "Installer Compliance Form: General Information",
        requiredFieldCount: formModel?.generalInformationRequiredFieldCount,
        disabled: formModel?.isGeneralInformationComplete !== true,
      },
      {
        formPart:
          BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormEngineeredAttachedStructure,
        displayName: "Installer Compliance Form: Engineered AttachedStructure",
        requiredFieldCount:
          formModel?.engineeredAttachedStructureRequiredFieldCount,
        disabled: formModel?.isEngineeredAttachedStructureComplete !== true,
      },
      {
        formPart:
          BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts.InstallerComplianceFormPrescriptiveAttachedStructure,
        displayName:
          "Installer Compliance Form: Prescriptive AttachedStructure",
        requiredFieldCount:
          formModel?.prescriptiveAttachedStructureRequiredFieldCount,
        disabled: formModel?.isPrescriptiveAttachedStructureComplete !== true,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeStandardInstallerComplianceAttachedStructureFormContainer;
