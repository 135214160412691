import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardInstallerComplianceChimneyFormContainer from "../components/Forms/InstallerComplianceChimneyForm/Container/HomeStandardInstallerComplianceChimneyFormContainer";
import { BaseHomeEvaluationInstallerComplianceChimneyFormParts } from "../../common/types/BaseHomeEvaluationInstallerComplianceChimneyForm";

export interface HomeStandardInstallerComplianceChimneyPageProps {
  evaluationId?: string;
  installerComplianceChimneyFormPart?: BaseHomeEvaluationInstallerComplianceChimneyFormParts;
}

const HomeStandardInstallerComplianceChimneyPageComponent: FC<
  HomeStandardInstallerComplianceChimneyPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardInstallerComplianceChimneyFormContainer
            evaluationId={props.evaluationId}
            formParts={props.installerComplianceChimneyFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardInstallerComplianceChimneyPage = withAITracking(
  reactPlugin,
  HomeStandardInstallerComplianceChimneyPageComponent,
  "HomeStandardInstallerComplianceChimneyPage",
  "tracking-container"
);
