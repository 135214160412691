import { ReportReadDTO } from "../../../common/types/reports";
import baseApiClient from "./baseApiClient";

class FortifiedReportApiClient {
  getReports = async (): Promise<ReportReadDTO[]> => {
    return await baseApiClient.get<ReportReadDTO[]>(`fortified/reports`);
  };

  getReport = async (slug: string): Promise<ReportReadDTO> => {
    return await baseApiClient.get<ReportReadDTO>(`fortified/reports/${slug}`);
  };
}

export const fortifiedReportApiClient = new FortifiedReportApiClient();
