import {
  DateFieldValue,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  EnumMultiSelectFieldValue,
  NumericFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts {
  BasicInformation = "basic-information",
  PermitInformation = "permit-information",
  SiteConditions = "site-conditions",
  RoofStructure = "roof-structure",
  RoofSheathing = "roof-sheathing",
  SealedRoofDeck = "sealed-roof-deck",
  DripEdge = "drip-edge",
  AtticVents = "attic-vents",
  PhotoVoltaicPanels = "photovoltaic-panels",
  SkyLights = "skylights",
}

export interface BaseHomeEvaluationRoofingComplianceGeneralInformationForm
  extends BasicInformation,
    PermitInformation,
    SiteConditions,
    RoofStructure,
    RoofSheathing,
    SealedRoofDeck,
    DripEdge,
    AtticVents,
    PhotoVoltaicPanels,
    SkyLights {}

interface BasicInformation {
  roofInstallationDate?: DateFieldValue;
  distanceToSaltWaterShoreline?: EnumSingleSelectFieldValue;
  numberOfDifferentRoofCoverTypes?: NumericFieldValue;
  wasPermitIssued?: EnumRadioFieldValue;
}

interface PermitInformation {
  permitNumber?: TextFieldValue;
  permitDate?: DateFieldValue;
  permitIssuedBy?: TextFieldValue;
  dateOfFinalBldgDeptInspection?: DateFieldValue;
}

interface SiteConditions {
  buildingCodeAndEdition?: TextFieldValue;
  asce7Edition?: EnumSingleSelectFieldValue;
  ultimateSiteDesignWindSpeed?: NumericFieldValue;
  siteExposureCategory?: EnumSingleSelectFieldValue;
  meanRoofHeight?: NumericFieldValue;
}

interface RoofStructure {
  maxRoofStructuralMemberSpacing?: NumericFieldValue;
  maximumRoofStructuralMemberSpacingPhoto?: WildfireBoxContentExplorerValue;
}

interface RoofSheathing {
  roofSheathingType?: EnumMultiSelectFieldValue;
  roofSheathingTypeWhenOther?: TextFieldValue;
  minimumRoofSheathingThickness?: NumericFieldValue;
  minimumRoofSheathingThicknessPhotos?: WildfireBoxContentExplorerValue;
  selectedStandardDetailsThatMatchesInstalledSheathingAttachment?: EnumMultiSelectFieldValue;
  roofSheathingFastenerTypePhoto?: WildfireBoxContentExplorerValue;
  roofSheathingAttachmentPhoto?: WildfireBoxContentExplorerValue;
  doesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements?: SingleCheckboxFieldValue;
  roofDeckAttachmentEngineeringAnalysisRequiredDocumentation?: WildfireBoxContentExplorerValue;
}

interface SealedRoofDeck {
  isEntireRoofLowSloped?: EnumRadioFieldValue;
  selectedStandardDetailsThatMatchesSealedRoofDeck?: EnumSingleSelectFieldValue;
  doesSealedRoofDeckNotMatchFortifiedPrescribedRequirements?: SingleCheckboxFieldValue;
  sealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeck2PlyInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckSelfAdheredMembraneInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckSprayFoamInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckTapedSeamsWithTileInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckUnderlaymentLabelPhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckNoneOfTheAbovePhotos?: WildfireBoxContentExplorerValue;
  sealedRoofDeckClosedCellFoamDocumentation?: WildfireBoxContentExplorerValue;
  fullyInstalledRoofCoverPhotos?: WildfireBoxContentExplorerValue;
}

interface DripEdge {
  areInstalledDripEdgeNewAndCodeCompliantMaterials?: EnumRadioFieldValue;
  standardDetailThatMatchesDripEdgeInstallation?: EnumSingleSelectFieldValue;
  newDripEdgeInstallationPhotos?: WildfireBoxContentExplorerValue;
  wasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications?: EnumRadioFieldValue;
  isDripEdgeInstalled?: EnumRadioFieldValue;
  existingDripEdgeInstallationPhotos?: WildfireBoxContentExplorerValue;
}

interface AtticVents {
  areRoofMountedVentsOrGableEndsPresent?: EnumRadioFieldValue;
  roofVentilation?: EnumMultiSelectFieldValue;
  doAllRoofMountedVentsMeetTAS100A?: EnumRadioFieldValue;
  roofVentilationPhoto?: WildfireBoxContentExplorerValue;
  areAllRoofMountedVentsInstalledPerManufacturerRequirements?: EnumRadioFieldValue;
  doAllGableEndVentsHaveARemovablePerFortified2020HomeStandard?: EnumRadioFieldValue;
  gableEndsVentsPhoto?: WildfireBoxContentExplorerValue;
}

interface PhotoVoltaicPanels {
  areRoofMountedPhotoVoltaicPanelsPresent?: EnumRadioFieldValue;
  werePVPanelsInstalledAndAnchoredPerAnchorageDetails?: EnumRadioFieldValue;
  pvPanelImpactRating?: EnumSingleSelectFieldValue;
  pvAnchorageDocumentation?: WildfireBoxContentExplorerValue;
  pvHailRatingDocumentation?: WildfireBoxContentExplorerValue;
}

interface SkyLights {
  areSkylightsPresent?: EnumRadioFieldValue;
  skylightExistsAndRatingsAndInstallationAreUnknown?: SingleCheckboxFieldValue;
  wereSkylightsInstalledPerManufacturerInstructions?: EnumRadioFieldValue;
  skylightImpactRating?: EnumSingleSelectFieldValue;
  skylightHailRatingPhotos?: WildfireBoxContentExplorerValue;
  skylightHailRatingDocumentation?: WildfireBoxContentExplorerValue;
}
