import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationInstallingContractorConfirmationFormParts } from "../../common/types/BaseHomeEvaluationInstallingContractorConfirmationForm";
import HomeStandardInstallingContractorConfirmationFormContainer from "../components/Forms/InstallingContractorConfirmationForm/Container/HomeStandardInstallingContractorConfirmationFormContainer";

export interface HomeStandardInstallingContractorConfirmationPageProps {
  evaluationId?: string;
  installingContractorConfirmationFormPart?: BaseHomeEvaluationInstallingContractorConfirmationFormParts;
}

const HomeStandardInstallingContractorConfirmationPageComponent: FC<
  HomeStandardInstallingContractorConfirmationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardInstallingContractorConfirmationFormContainer
            evaluationId={props.evaluationId}
            installingContractorConfirmationFormPart={
              props.installingContractorConfirmationFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardInstallingContractorConfirmationPage = withAITracking(
  reactPlugin,
  HomeStandardInstallingContractorConfirmationPageComponent,
  "HomeStandardInstallingContractorConfirmationPage",
  "tracking-container"
);
