import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_CommercialEvaluationFormModel } from "../../types/CommercialEvaluationFormModel";
import { Evaluation_CommercialEvaluation } from "../../types/CommercialEvaluation";

const baseCommercialURL = "commercial/evaluation";

class CommercialEvaluationAPIClient {
  createCommercialEvaluation = async () => {
    return await baseApiClient.post<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}`
    );
  };

  getCommercialEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}`
    );
  };

  getCommercialEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_CommercialEvaluationFormModel>(
      `${baseCommercialURL}/${evaluationId}`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `${baseCommercialURL}/${evaluationId}/payment/application-fee/credit-details`
    );
  };

  getCertificateFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `${baseCommercialURL}/${evaluationId}/payment/certificate-fee/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/payment/application-fee/credit`
    );
  };

  applyCertificateFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/payment/certificate-fee/credit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `${baseCommercialURL}/${evaluationId}/payment/application-fee/stripe-checkout`
    );
  };

  getCertificateFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `${baseCommercialURL}/${evaluationId}/payment/certificate-fee/stripe-checkout`
    );
  };

  moveToEvaluatorAssignmentStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/evaluator-assignment`
    );
  };

  moveToComplianceFormsStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/compliance-forms`
    );
  };

  moveToFieldInspectionsStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/field-inspections`
    );
  };

  moveToComplianceLetterStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/compliance-letter`
    );
  };

  moveToIBHSReviewStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/ibhs-review`
    );
  };

  moveToCertificateFeeStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${evaluationId}/certificate-fee`
    );
  };

  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `${baseCommercialURL}/${evaluationId}/requested-changes-email`
    );
  };
  sendChangesForReviewEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `${baseCommercialURL}/${evaluationId}/changes-for-review-email`
    );
  };

  //Complex Commercial Evaluation / Parent Child
  getComplexCommercialEvaluationBuildings = async (
    complexCommercialEvaluationId: string
  ) => {
    return await baseApiClient.get<Evaluation_CommercialEvaluation[]>(
      `${baseCommercialURL}/${complexCommercialEvaluationId}/complex-commercial-evaluation-buildings`
    );
  };

  getComplexCommercialEvaluationBuildingIds = async (
    complexCommercialEvaluationId: string
  ) => {
    return await baseApiClient.get<string[]>(
      `${baseCommercialURL}/${complexCommercialEvaluationId}/complex-commercial-evaluation-building-ids`
    );
  };
  createComplexCommercialEvaluationBuilding = async (
    complexCommercialEvaluationId: string
  ) => {
    return await baseApiClient.post<Evaluation_CommercialEvaluation>(
      `${baseCommercialURL}/${complexCommercialEvaluationId}/complex-commercial-evaluation-building`
    );
  };
  convertMultifamilyEvaluationToCommercialEvaluation = async (
    multifamilyEvaluationId: string
  ) => {
    return await baseApiClient.post(
      `${baseCommercialURL}/${multifamilyEvaluationId}/convert`
    );
  };
}

export const commercialEvaluationAPIClient =
  new CommercialEvaluationAPIClient();
