import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts {
  SilverGoldComplianceGeneralInformation = "silver-gold-compliance-general-information",
}

export interface BaseHomeEvaluationSilverGoldComplianceGeneralInformationForm
  extends ISilverGoldComplianceGeneralInformation {}

export interface ISilverGoldComplianceGeneralInformation {
  // Silver Compliance Information
  SiteDesignInformation?: string;
  DidEngineerSpecifyWindPressuresForZone4And5?: EnumRadioFieldValue;
  Zone4PositiveRequiredWallWindPressure?: number;
  Zone4NegativeRequiredWallWindPressure?: number;
  Zone5PositiveRequiredWallWindPressure?: number;
  Zone5NegativeRequiredWallWindPressure?: number;
  WallWindPressuresDocumentation?: WildfireBoxContentExplorerValue;
  WindowsAndSkylights?: string;
  AreSkylightsOrLightTunnelsPresent?: EnumRadioFieldValue;
  AreSkylightsImpactRated?: EnumRadioFieldValue;
  ShowWarning_AreSkylightsImpactRated?: boolean;
  SkylightDebrisImpactRatingPhotos?: WildfireBoxContentExplorerValue;
  SkylightDebrisImpactRatingDocumentation?: WildfireBoxContentExplorerValue;
  AreWindowsImpactRatedOrProtected?: EnumRadioFieldValue;
  ShowWarning_AreWindowsImpactRatedOrProtected?: boolean;
  WindowLabelsAndImpactRatedCoverPhotos?: WildfireBoxContentExplorerValue;
  WindowRatingAndImpactRatedCoverDocumentation?: WildfireBoxContentExplorerValue;

  // Show this section when HAZARD_TYPE = HURRICANE
  EntryDoors?: string;
  AreEntryDoorsImpactRatedOrProtected?: EnumRadioFieldValue;
  ShowWarning_AreEntryDoorsImpactRatedOrProtected?: boolean;
  EntryDoorLabelAndImpactRatedCoverPhotos?: WildfireBoxContentExplorerValue;
  EntryDoorRatingAndImpactRatedCoverDocumentation?: WildfireBoxContentExplorerValue;

  // General Section 2
  Garage?: string;
  DoesHomeHaveGarageDoors?: EnumRadioFieldValue;
  DoesGarageDoorsHaveGlazing?: EnumRadioFieldValue;
  AreGarageDoorsImpactRatedOrProtected?: EnumRadioFieldValue;
  ShowWarning_AreGarageDoorsImpactRatedOrProtected?: boolean;
  IsGarageDoorDesignPressureRated?: EnumRadioFieldValue;
  DesignPressureRatingOfGarageDoorsPositive?: number;
  DesignPressureRatingOfGarageDoorsNegative?: number;
  DoesGarageDoorPressureRatingMeetOrExceed?: EnumRadioFieldValue;
  ShowWarning_DoesGarageDoorPressureRatingMeetOrExceed?: boolean;
  GarageDoorLabelAndImpactRatedCoverPhotos?: WildfireBoxContentExplorerValue;
  GarageDoorRatingAndImpactRatedCoverDocumentation?: WildfireBoxContentExplorerValue;

  // Show this section when BUILDING_ELEMENTS_INCLUDED_ON_HOME = Gables Over 3ft OR Gable Overhangs
  GableEnds?: string;
  GableEndSheathing?: WildfireBoxContentExplorerValue;
  GableEndBracing?: WildfireBoxContentExplorerValue;
  GableEndOverhangs?: WildfireBoxContentExplorerValue;

  // Show this section when HAZARD_TYPE = HURRICANE
  Soffits?: string;
  SoffitCoverType?: EnumSingleSelectFieldValue;
  FlexibleSoffitCoverPhoto?: WildfireBoxContentExplorerValue;
  RigidSoffitCoverPhoto?: WildfireBoxContentExplorerValue;
  DoesSoffitOverhangExtendMoreThan12Inches?: EnumRadioFieldValue;
  SoffitBracingPhoto?: WildfireBoxContentExplorerValue;

  // Show this section when BUILDING_ELEMENTS_INCLUDED_ON_HOME = Chimneys (Silver)
  Chimneys?: string;
  ChimneyDetails?: WildfireBoxContentExplorerValue;
  ChimneyLocation?: WildfireBoxContentExplorerValue;
  ChimneyFramingAndAnchorage?: WildfireBoxContentExplorerValue;

  // Show this section when BUILDING_ELEMENTS_INCLUDED_ON_HOME = Single Level or Multi Level Attached Structures (Silver)
  AttachedStructures?: string;
  AttachedStructureRoofToBeam?: WildfireBoxContentExplorerValue;
  SingleLevelAttachedStructureBeamToColumn?: WildfireBoxContentExplorerValue;
  SingleLevelAttachedStructureColumnToFoundation?: WildfireBoxContentExplorerValue;
  MultiLevelAttachedStructureRoofBeamToUpperLevelColumns?: WildfireBoxContentExplorerValue;
  MultiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams?: WildfireBoxContentExplorerValue;
  MultiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn?: WildfireBoxContentExplorerValue;
  MultiLevelAttachedStructureLowerLevelColumnToFoundation?: WildfireBoxContentExplorerValue;

  // Gold Compliance Information
  DesignPressureRatedOpenings?: string;
  DoWindowPressureRatingsMeetOrExceed?: EnumRadioFieldValue;
  ShowWarning_DoWindowPressureRatingsMeetOrExceed?: boolean;
  DoEntryDoorPressureRatingsMeetOrExceed?: EnumRadioFieldValue;
  ShowWarning_DoEntryDoorPressureRatingsMeetOrExceed?: boolean;
  AdditionalDesignPressureRatingDocumentationForOpenings?: WildfireBoxContentExplorerValue;
  DoSkylightsDesignPressuresMeetOrExceed?: EnumRadioFieldValue;
  ShowWarning_DoSkylightsDesignPressuresMeetOrExceed?: boolean;
  WallSheathing?: string;
  WallSheathingTypeAndThickness?: WildfireBoxContentExplorerValue;
  ContinuousLoadPath?: string;
  RoofToWallConnection?: WildfireBoxContentExplorerValue;
  WallAboveToWallBelowConnection?: WildfireBoxContentExplorerValue;
  LowerWallToFoundationConnection?: WildfireBoxContentExplorerValue;
  HoldDownsTensionTiesAtBuildingCorners?: WildfireBoxContentExplorerValue;
}
