import { Button } from "@blueprintjs/core";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { reactPlugin } from "../../AppInsights";
import variables from "../../common/config/variables";
import { useFortifiedGridViewModule } from "../../common/customHooks/useFortifiedGridViewModule";
import { FortifiedGrid } from "../components/FortifiedGrid/FortifiedGrid";
import { FortifiedGridFiltersModule } from "../components/FortifiedGridFiltersModule/FortifiedGridFiltersModule";
import { FortifiedGridViewPicker } from "../components/FortifiedGridViewsModule/FortifiedGridViewPicker";
import { Header } from "../components/Header/Header";
import { IterationEngineActions } from "../components/IterationEngine/IterationEngineActions";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import { getGridColumnsConfigurationByRole } from "../lib/gridColumnConfigurations/boardColumnsConfiguration";
import { getGridDefaultViewByRole } from "../lib/gridColumnConfigurations/boardViewsConfiguration";
import { dialogsViewerStore } from "../stores/DialogsViewerStore";
import { evaluationStore } from "../stores/EvaluationStore";
import { fortifiedGridViewModuleStore } from "../stores/FortifiedGridViewModuleStore";
import { permissionStore } from "../stores/PermissionStore";
import { EvaluationReadDTO } from "../types/dtos/EvaluationReadDTO";
import {
  EvaluationStep_AsString,
  ProductTypes_AsString,
} from "../types/evaluation/Evaluation";
import "./styles.scss";
import { fortifiedUserStore } from "../stores/FortifiedUserStore";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";
import { Dialogs } from "@ucl/library";
import { openCreateFCFMFEvaluationDialog } from "../../fortifiedV2/FCFMF/common/components/Dialogs/CreateFCFMFEvaluation/CreateFCFMFEvaluationDialog";
import { openCreateHomeEvaluationDialog } from "../../fortifiedV2/Home/common/components/Dialogs/CreateHomeEvaluation/Dialogs/CreateFCFMFEvaluation/CreateHomeEvaluationDialog";
import { fortifiedCommercialMultifamilyRoutePrefix } from "./index/fortifiedCommercialMultifamilyRouteConfig";
import { fortifiedHomeRoutePrefix } from "./index/fortifiedHomeRouteConfig";
import { featureToggleApiClient } from "../../common/Components/FeatureToggle/class/featureToggleApiClient";
import { FeatureToggleSet } from "../../common/Components/FeatureToggle/FeatureToggles";
import { FortifiedGridMetrics } from "../components/FortifiedGrid/FortifiedGridMetrics";
import { FortifiedEvaluationMetricReadDTO } from "../types/dtos/FortifiedEvaluationMetricReadDTO";

export interface BoardProps {}

const BoardComponent: React.FC<BoardProps> = () => {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggleSet>();

  const [isInitialized, setIsInitialized] = useState(false);

  const getFeatureToggles = async () => {
    const toggles = await featureToggleApiClient.getFeatureToggleSet();

    setFeatureToggles(toggles);
    setIsInitialized(true);
  };

  useEffect(() => {
    getFeatureToggles();
  }, []);

  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  const goToEvaluation = (rowData: EvaluationReadDTO) => {
    const route = getRoute(rowData);
    navigate(route);
  };

  const shouldNavigateToChecklistPage = (
    productType: string,
    evaluationStatus: string
  ) => {
    const inChecklistStatus =
      evaluationStatus === EvaluationStep_AsString.Application ||
      evaluationStatus === EvaluationStep_AsString.ApplicationForms ||
      evaluationStatus === EvaluationStep_AsString.Payment;
    return (
      productType === ProductTypes_AsString.FortifiedHome && inChecklistStatus
    );
  };

  const shouldNaviateToComplexEvaluation = (rowData: EvaluationReadDTO) => {
    return (
      rowData.isComplexCommercialEvaluationBuilding === true &&
      !!rowData.complexCommercialAndMultifamilyEvaluationId
    );
  };

  const getIterationEngineRoute = (evaluationId: string) => {
    return `${routePrefix}/evaluation/${evaluationId}`;
  };

  const getRoute = (rowData: EvaluationReadDTO) => {
    if (rowData.isFortifiedVersionTwo) {
      return getFortifiedV2Route(rowData);
    }

    if (shouldNavigateToChecklistPage(rowData.productType, rowData.step)) {
      return `${routePrefix}/checklist?fortifiedId=${rowData.fortifiedId}`;
    }
    if (shouldNaviateToComplexEvaluation(rowData)) {
      return getIterationEngineRoute(
        rowData.complexCommercialAndMultifamilyEvaluationId
      );
    }
    return getIterationEngineRoute(rowData.id);
  };

  const getFortifiedV2Route = (rowData: EvaluationReadDTO) => {
    switch (rowData.productType) {
      case "FortifiedCommercial":
        if (rowData.isRedesignation) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial-redesignation/iteration-engine/${rowData.id}`;
        } else {
          if (rowData.step === EvaluationStep_AsString.Application) {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/${rowData.id}/application-form`;
          } else {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/commercial/iteration-engine/${rowData.id}`;
          }
        }

      case "FortifiedMultifamily":
        if (rowData.isRedesignation) {
          return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily-redesignation/iteration-engine/${rowData.id}`;
        } else {
          if (rowData.step === EvaluationStep_AsString.Application) {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/${rowData.id}/application-form`;
          } else {
            return `${fortifiedCommercialMultifamilyRoutePrefix}/multifamily/iteration-engine/${rowData.id}`;
          }
        }
      case "FortifiedHome":
        if (rowData.isRedesignation) {
          return `${fortifiedHomeRoutePrefix}/standard-redesignation/iteration-engine/${rowData.id}`;
        } else {
          return `${fortifiedHomeRoutePrefix}/standard/iteration-engine/${rowData.id}`;
        }

      default:
        return "";
    }
  };

  // Fortified Grid View Module
  useFortifiedGridViewModule(
    "board-grid",
    "Board",
    getGridColumnsConfigurationByRole,
    getGridDefaultViewByRole
  );

  return (
    <div className="fortified-page board-page">
      <Dialogs />
      {/* Dont want page level observer */}
      <Observer>
        {() => <Header renderActions={() => <IterationEngineActions />} />}
      </Observer>
      {isInitialized && (
        <div className="content">
          <PageSidebar activePage="home" />
          <div className="page-main-content">
            <section className="board-toolbar">
              <ul className="board-toolbar-left">
                <li>
                  {/* Fortified Grid Views Module - Picker */}
                  <FortifiedGridViewPicker
                    key={fortifiedGridViewModuleStore.currentGridViewKey}
                  />
                </li>
              </ul>
              <ul className="board-toolbar-right">
                <li>
                  {/* Fortified Grid Filters Module */}
                  <FortifiedGridFiltersModule />
                </li>
                {featureToggles?.LegacyFortified &&
                  permissionStore.canCreateNewEvaluation && (
                    <li>
                      <Button
                        className="evaluation-button"
                        text={
                          evaluationStore.getProductTypeByUserRole ===
                          "commercial"
                            ? "New Application"
                            : "New Evaluation"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() =>
                          dialogsViewerStore.setIsEvaluationCreationDialog(true)
                        }
                      />
                    </li>
                  )}
                {featureToggles?.FormstackMigration &&
                  permissionStore.canCreateNewEvaluation &&
                  evaluationStore.getProductTypeByUserRole === "commercial" && (
                    <li>
                      <Button
                        text={"New Application V2"}
                        icon="add"
                        intent="primary"
                        onClick={() => openCreateFCFMFEvaluationDialog()}
                      />
                    </li>
                  )}
                {featureToggles?.FormstackMigration &&
                  permissionStore.canCreateNewEvaluation &&
                  evaluationStore.getProductTypeByUserRole === "home" && (
                    <li>
                      <Button
                        text={"New Evaluation V2"}
                        icon="add"
                        intent="primary"
                        onClick={() => openCreateHomeEvaluationDialog()}
                      />
                    </li>
                  )}
              </ul>
            </section>
            <section className="board-grid-container">
              {/* Fortified Grid */}
              <Observer>
                {() => (
                  <>
                    {!!fortifiedGridViewModuleStore.currentGridView && (
                      <FortifiedGrid<EvaluationReadDTO>
                        key={fortifiedGridViewModuleStore.currentGridViewKey}
                        dataSource={`${
                          variables.apiBaseUrl as string
                        }odata/evaluationodatagrid`}
                        columnsConfiguration={[
                          ...fortifiedGridViewModuleStore.currentGridView
                            .columnConfiguration,
                        ]}
                        filterConfiguration={[
                          ...(fortifiedGridViewModuleStore.currentGridView
                            .filterConfiguration || []),
                        ]}
                        sortConfiguration={[
                          ...(fortifiedGridViewModuleStore.currentGridView
                            .sortConfiguration || []),
                        ]}
                        gridConfiguration={{
                          allowTextWrap: true,
                          textWrapSettings: { wrapMode: "Header" },
                          defaultSort: {
                            field: "createdAt",
                            direction: fortifiedUserStore.isAuditorTrainee
                              ? "Ascending"
                              : "Descending",
                          },
                          exportFilename: "Evaluations.xlsx",
                          onRecordClick: ({ rowData }) => {
                            goToEvaluation(rowData);
                          },
                        }}
                      />
                    )}
                  </>
                )}
              </Observer>
            </section>
            {featureToggles?.NewGridMetrics && (
              <section className="board-metrics-container">
                {/* Fortified Grid Metrics Module */}
                <Observer>
                  {() => (
                    <>
                      {!!fortifiedGridViewModuleStore.currentGridView && (
                        <FortifiedGridMetrics<FortifiedEvaluationMetricReadDTO>
                          gridKey="board-grid"
                          url={`evaluation`}
                          columns={4}
                          minimal={true}
                          languageMapping={{
                            evaluationCount: "Evaluation Count",
                            completedEvaluationsThisWeek:
                              "Completed Evaluations This Week",
                            redesignationsComingDueInSixMonths:
                              "Redesignations Coming Due in 6 Months",

                            auditQueueCount: "Audit Queue Count",
                            auditQueueAgedUpToThreeDays:
                              "Audit Queue Aged < 3 Days",
                            auditQueueAgedFourToSevenDays:
                              "Audit Queue Aged 4-7 Days",
                            auditQueueAgedEightToFourteenDays:
                              "Audit Queue Aged 8-14 Days",
                            auditQueueAgedOverFourteenDays:
                              "Audit Queue Aged > 14 Days",
                            auditQueueOldestAge:
                              "Audit Queue Oldest Submission",

                            statusApplicationCount: "Application Status Count",
                            statusIBHSEligibilityReviewCount:
                              "Eligibility Review Status Count",
                            statusChangesRequiredCount:
                              "Changes Required Status Count",
                            statusReadyForReReviewCount:
                              "Ready For Re-Review Status Count",
                            statusEvaluatorAssignmentCount:
                              "Evaluator Assignment Status Count",
                            statusComplianceFormsCount:
                              "Compliance Forms Status Count",
                            statusFieldInspectionsCount:
                              "Field Inspections Status Count",
                            statusComplianceLetterCount:
                              "Compliance Letter Status Count",
                            statusIBHSReviewCount: "IBHS Review Status Count",
                            statusApplicationFormsCount:
                              "Application Forms Status Count",
                            statusPaymentCount: "Payment Status Count",
                            statusApplicationFeeCount:
                              "Application Fee Status Count",
                            statusCertificateFeeCount:
                              "Certificate Fee Status Count",
                            statusApprovedCount: "Approved Status Count",
                            statusNotApprovedCount: "Not Approved Status Count",
                            statusWithdrawnCount: "Withdrawn Status Count",

                            specialProjectNCIUASYRCount:
                              "NCIUA/NCJUA SYR Count",
                            specialProjectNCIUASYCRCount:
                              "NCIUA/NCJUA SYCR Count",
                            specialProjectNCIUAClaimCount:
                              "NCIUA/NCJUA Claim Count",
                            specialProjectStrengthenALHomesCount:
                              "Strengthen AL Homes Count",
                            specialProjectSCSafeHomeCount: "SC Safe Home Count",
                            specialProjectLDIRoofGrantCount:
                              "LDI Roof Grant Count",
                            specialProjectFannieMaeCount: "Fannie Mae Count",
                          }}
                        />
                      )}
                    </>
                  )}
                </Observer>
              </section>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export const Board = withAITracking(
  reactPlugin,
  BoardComponent,
  "Board",
  "tracking-container"
);
