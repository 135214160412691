import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationInstallerComplianceGablesFormParts } from "../../../common/types/BaseHomeEvaluationInstallerComplianceGablesForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationInstallerComplianceGablesFormModel } from "../../types/HomeStandardEvaluationInstallerComplianceGablesFormModel";

class HomeStandardEvaluationInstallerComplianceGablesAPIClient {
  updateHomeStandardInstallerComplianceGablesForm = async (
    evaluationId: string,
    installerComplianceGablesForm: HomeStandardEvaluationInstallerComplianceGablesFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-gables-form/${evaluationId}`,
      {
        installerComplianceGablesForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardInstallerComplianceGablesForm = async (
    evaluationId: string,
    installerComplianceGablesForm: HomeStandardEvaluationInstallerComplianceGablesFormModel,
    formPart?: BaseHomeEvaluationInstallerComplianceGablesFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationInstallerComplianceGablesFormParts
      ).pop() as BaseHomeEvaluationInstallerComplianceGablesFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-gables-form/${evaluationId}/${formPartValue}`,
      installerComplianceGablesForm
    );
  };
}

export const homeStandardEvaluationInstallerComplianceGablesAPIClient =
  new HomeStandardEvaluationInstallerComplianceGablesAPIClient();
