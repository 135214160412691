import {
  DateFieldValue,
  EnumRadioFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationInstallerComplianceChimneyFormParts {
  InstallerComplianceFormChimneyGeneralInformation = "installer-compliance-form-chimney-general-information",
  InstallerComplianceFormEngineeredChimney = "installer-compliance-form-engineered-chimney",
  InstallerComplianceFormPrescriptiveChimney = "installer-compliance-form-prescriptive-chimney",
}

export interface BaseHomeEvaluationInstallerComplianceChimneyForm
  extends IInstallerComplianceChimneyFormGeneralInformation,
    IInstallerComplianceFormEngineeredChimney,
    IInstallerComplianceFormPrescriptiveChimney {}

export interface IInstallerComplianceChimneyFormGeneralInformation {
  permitNumber?: TextFieldValue;
  dateInstallationWasCompleted?: DateFieldValue;
}

export interface IInstallerComplianceFormEngineeredChimney {
  attestation292?: SingleCheckboxFieldValue;
  attestation293?: SingleCheckboxFieldValue;
  attestation294?: SingleCheckboxFieldValue;
}

export interface IInstallerComplianceFormPrescriptiveChimney {
  areChimneysInstalledPerFCTD1?: EnumRadioFieldValue;
  attestation295?: SingleCheckboxFieldValue;
  attestation296?: SingleCheckboxFieldValue;
  attestation297?: SingleCheckboxFieldValue;
  showWarning_AreChimneysInstalledPerFCTD1?: boolean;
}
