import { Button, H2 } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PageSidebar } from "../components/PageSidebar/PageSidebar";
import report_background from "../assets/report_background.png";
import "./styles.scss";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../../AppInsights";
import { Header } from "../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { permissionStore } from "../stores/PermissionStore";
import { useProductTypeHandler } from "../providers/ProductTypeHandlerProvider";
import { ReportReadDTO } from "../../common/types/reports";
import { fortifiedReportApiClient } from "../lib/apiClients/fortifiedReportApiClient";
import {
  errorStore,
  genericErrorMessage,
} from "../../common/Components/Error/ErrorStore";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

const ReportsPageComponent: React.FC = () => {
  const navigate = useNavigate();
  const { routePrefix } = useProductTypeHandler();

  const [areReportsLoading, setAreReportsLoading] = useState(true);
  const [reports, setReports] = useState<ReportReadDTO[]>();

  useEffect(() => {
    fortifiedReportApiClient
      .getReports()
      .then((response) => {
        setReports(response);
      })
      .catch((error) => {
        console.log(error);
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setAreReportsLoading(false);
      });
  }, []);

  if (!permissionStore.canViewReportsPage) {
    errorStore.setErrorMessage(genericErrorMessage);
    return null;
  }

  return (
    <div className="fortified-page board-page reports-page">
      <Observer>{() => <Header />}</Observer>
      <div className="content">
        <PageSidebar activePage="reports" />
        <div className="page-main-content">
          {areReportsLoading || !reports ? (
            <Loading />
          ) : (
            <div className="reports-page__reports">
              {reports.map((report) => {
                return (
                  <div
                    key={report.slug}
                    className="reports-page__reports__report-item"
                  >
                    <img src={report_background} alt="Report Background" />
                    <div className="reports-page__reports__report-item__overlay-container">
                      <H2>{report.title}</H2>
                      <Button
                        minimal
                        intent="primary"
                        outlined
                        text="View Report"
                        icon={IconNames.EYE_OPEN}
                        onClick={() => {
                          navigate(`${routePrefix}/reports/${report.slug}`);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ReportsPage = withAITracking(
  reactPlugin,
  ReportsPageComponent,
  "ReportsPage",
  "tracking-container"
);
