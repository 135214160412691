import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { HomeEligibilityFormParts } from "../../common/types/BaseHomeEvaluationEligibilityForm";
import HomeStandardEligibilityFormContainer from "../components/Forms/EligibilityForm/Container/HomeStandardEligibilityFormContainer";

export interface HomeStandardEligibilityPageProps {
  evaluationId?: string;
  formPart?: HomeEligibilityFormParts;
}

const HomeStandardEligibilityPageComponent: FC<
  HomeStandardEligibilityPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardEligibilityFormContainer
            evaluationId={props.evaluationId}
            formPart={props.formPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardEligibilityPage = withAITracking(
  reactPlugin,
  HomeStandardEligibilityPageComponent,
  "HomeStandardEligibilityPage",
  "tracking-container"
);
