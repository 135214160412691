import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { Evaluation_HomeSCSHEvaluation } from "../types/HomeSCSHEvaluation";
import { homeSCSHEvaluationAPIClient } from "../lib/apiClients/homeSCSHEvaluationAPIClient";

export const useHomeSCSHEvaluation = (evaluationId: string) => {
  const [homeSCSHEvaluation, setHomeSCSHEvaluation] =
    useState<Evaluation_HomeSCSHEvaluation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getSCSHEvaluation(evaluationId);
  }, [evaluationId]);

  const getSCSHEvaluation = async (evaluationId: string) => {
    await homeSCSHEvaluationAPIClient
      .getHomeSCSHEvaluation(evaluationId)
      .then((response) => {
        setHomeSCSHEvaluation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshHomeSCSHEvaluation = async () => {
    setIsApplicationLoading(true);
    if (homeSCSHEvaluation?.id) {
      await homeSCSHEvaluationAPIClient
        .getHomeSCSHEvaluation(homeSCSHEvaluation?.id)
        .then((response) => {
          setHomeSCSHEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    homeSCSHEvaluation,
    setHomeSCSHEvaluation,
    refreshHomeSCSHEvaluation,
    isApplicationLoading,
  };
};
