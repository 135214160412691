import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../../common/types/BaseHomeEvaluationHomeownerAuthorizationForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationHomeownerAuthorizationFormModel } from "../../types/HomeStandardEvaluationHomeownerAuthorizationFormModel";

class HomeStandardEvaluationHomeownerAuthorizationAPIClient {
  updateHomeStandardHomeownerAuthorizationForm = async (
    evaluationId: string,
    homeownerAuthorizationForm: HomeStandardEvaluationHomeownerAuthorizationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/homeowner-authorization-form/${evaluationId}`,
      {
        homeownerAuthorizationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardHomeownerAuthorizationForm = async (
    evaluationId: string,
    homeownerAuthorizationForm: HomeStandardEvaluationHomeownerAuthorizationFormModel,
    formPart?: BaseHomeEvaluationHomeownerAuthorizationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationHomeownerAuthorizationFormParts
      ).pop() as BaseHomeEvaluationHomeownerAuthorizationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/homeowner-authorization-form/${evaluationId}/${formPartValue}`,
      homeownerAuthorizationForm
    );
  };
}

export const homeStandardEvaluationHomeownerAuthorizationAPIClient =
  new HomeStandardEvaluationHomeownerAuthorizationAPIClient();
