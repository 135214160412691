import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationAPIClient";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEvaluationRoofingComplianceRoofCoverFormProps } from "./HomeStandardEvaluationRoofingComplianceRoofCoverForm";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverForm";
import { homeStandardEvaluationRoofingComplianceRoofCoverAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationRoofingComplianceRoofCoverAPIClient";

function useHomeStandardEvaluationRoofingComplianceRoofCoverForm(
  props: HomeStandardEvaluationRoofingComplianceRoofCoverFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeStandardEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart ===
    Object.values(BaseHomeRoofingComplianceRoofCoverFormParts)[0];

  const isLastPartOfForm =
    props.formPart ===
    Object.values(BaseHomeRoofingComplianceRoofCoverFormParts).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeStandardEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeStandardIterationEngineRoofingComplianceRoofCoverForm;
    }
    switch (props.formPart) {
      case BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation:
        return builders?.HomeStandardRoofCoverInformation;
      case BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails:
        return builders?.HomeStandardAsphaltShingleRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails:
        return builders?.HomeStandardMetalRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails:
        return builders?.HomeStandardClayConcreteTileRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails:
        return builders?.HomeStandardWoodShakeSingleRoofCoverDetails;
      case BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails:
        return builders?.HomeStandardLowSlopeRoofCoverDetails;
      default:
        return builders?.HomeStandardRoofingComplianceRoofCoverForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);
    await (shouldSubmit
      ? homeStandardEvaluationRoofingComplianceRoofCoverAPIClient.submitHomeStandardRoofingComplianceRoofCoverForm(
          form.id,
          form.homeStandardEvaluation.roofingComplianceRoofCoverForm,
          props.formPart
        )
      : homeStandardEvaluationRoofingComplianceRoofCoverAPIClient.updateHomeStandardRoofingComplianceRoofCoverForm(
          form.id,
          form.homeStandardEvaluation.roofingComplianceRoofCoverForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);

      if (isLastPartOfForm) {
      } else {
        // Navigate to next part of form
        const nextFormPart = Object.values(
          BaseHomeRoofingComplianceRoofCoverFormParts
        )[
          Object.values(BaseHomeRoofingComplianceRoofCoverFormParts).indexOf(
            props.formPart!
          ) + 1
        ].valueOf();

        navigate(
          `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/roofing-compliance-roof-cover/${nextFormPart}`
        );
      }

      // }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        areAllRequiredFieldsComplete,
        roofCoverInformationRequiredFieldCount,
        roofCoverInformationLastSubmittedAt,
        roofCoverInformationHasBeenSubmitted,
        isRoofCoverInformationComplete,
        asphaltShingleRoofCoverDetailsRequiredFieldCount,
        asphaltShingleRoofCoverDetailsLastSubmittedAt,
        asphaltShingleRoofCoverDetailsHasBeenSubmitted,
        isAsphaltShingleRoofCoverDetailsComplete,
        metalRoofCoverDetailsRequiredFieldCount,
        metalRoofCoverDetailsLastSubmittedAt,
        metalRoofCoverDetailsHasBeenSubmitted,
        isMetalRoofCoverDetailsComplete,
        clayConcreteTileRoofCoverDetailsRequiredFieldCount,
        clayConcreteTileRoofCoverDetailsLastSubmittedAt,
        clayConcreteTileRoofCoverDetailsHasBeenSubmitted,
        isClayConcreteTileRoofCoverDetailsComplete,
        woodShakeSingleRoofCoverDetailsRequiredFieldCount,
        woodShakeSingleRoofCoverDetailsLastSubmittedAt,
        woodShakeSingleRoofCoverDetailsHasBeenSubmitted,
        isWoodShakeSingleRoofCoverDetailsComplete,
        lowSlopeRoofCoverDetailsRequiredFieldCount,
        lowSlopeRoofCoverDetailsLastSubmittedAt,
        lowSlopeRoofCoverDetailsHasBeenSubmitted,
        isLowSlopeRoofCoverDetailsComplete,
        roofCoverType,
        roofCoverTypeMinSlope,
        roofCoverTypeMinSlopePhoto,
        requiredWindRatingZone1,
        requiredWindRatingZone2,
        requiredWindRatingZone3,
        allZonesRequiredWindRatingDocumentation,
        areAsphaltShingleManufacturerAndProductDetailsKnown,
        asphaltShingleManufacturerName,
        asphaltShingleProductName,
        shingleManufacturerAndProductPhoto,
        doesAsphaltShingleWindRatingMeetASTMClassFOrClassH,
        asphaltShingleWindRatingType,
        shingleWindRatingPhoto,
        asphaltShingleHailRatingType,
        shingleHailRatingPhoto,
        doAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements,
        areAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions,
        numberOfInstalledNailsPerAsphaltShingle,
        startStripInstallationAtEavesType,
        startStripInstallationAtGlobeRakesType,
        starterStripInstallationPhotos,
        areMetalPanelManufacturerAndProductDetailsKnown,
        metalPanelManufacturerName,
        metalPanelProductName,
        metalPanelCertifiedTestReportProductApprovalNumber,
        metalPanelCertifiedTestReportDocumentation,
        metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport,
        metalPanelFirstInstalledSystemNumberPerCertifiedTestReport,
        matelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating,
        metalPanelSecondInstalledSystemNumberPerCertifiedTestReport,
        metalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating,
        metalPanelThirdInstalledSystemNumberPerCertifiedTestReport,
        metalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating,
        metalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
        doMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
        installedMetalPanelMaterial,
        installedMetalPanelGaugeThickness,
        installedMetalPanelFastenerTypeAndSize,
        metalPanelFastenersPhoto,
        installedMetalPanelFastenerPatternAndSpacingZone1,
        installedMetalPanelFastenerPatternAndSpacingZone2,
        installedMetalPanelFastenerPatternAndSpacingZone3,
        metalPanelInstalledFastenerPatternAndSpacingPhotos,
        wasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards,
        doesMetalPanelSystemHaveUL2218Class4ImpactRating,
        areTheClayConcreteTileManufacturerAndProductDetailsKnown,
        clayConcreteTileManufacturerName,
        clayConcreteTileProductName,
        clayConcreteTileTestReportApprovalNumber,
        clayConcreteTileCertifiedTestReportDocumentation,
        clayConcreteTileNumberOfInstalledSystemsFromTestReport,
        clayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport,
        clayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating,
        clayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport,
        clayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating,
        clayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport,
        clayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating,
        clayConcreteTileSystemsIdentifiedFromTestReport,
        clayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
        doesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating,
        clayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports,
        tileStructuralSupportsCertifiedTestReportDocumentation,
        mechanicalAdhesiveRoofTileAttachmentType,
        mechanicalFastenerType,
        roofTileFastenersPhoto,
        mechanicalFastenerSize,
        mechanicalFastenerQuantityPerTile,
        doMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements,
        adhesiveFoamManufacturerName,
        adhesiveFoamProductName,
        adhesiveFoamTestReportApprovalNumber,
        isAdhesiveFoamInstallerCertifiedByFoamManufacturer,
        adhesiveFoamInstallerCertificationNumber,
        tileUnderlaymentCertifiedTestReportNumber,
        isTileAndFlashingInstalledPerFRSATRIInstructions,
        isTileAndFlashingInstalledPerTRIWSRCAInstructions,
        areTheWoodShakeShingleManufacturerAndProductDetailsKnown,
        woodShakeShingleManufacturerName,
        woodShakeShingleProductName,
        woodShakeShingleTestReportApprovalNumber,
        woodShakeShingleCertifiedTestReportDocumentation,
        woodShakeShingleDesignPressureWindRatingOfInstalledSystem,
        woodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications,
        woodShakeShingleMeanRoofHeightLimitation,
        woodShakeShingleRequiredNumberOfNailsPerShingle,
        woodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions,
        doesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating,
        woodShakeShingleFastenerType,
        woodShakeShingleFastenersPhoto,
        woodShakeShingleFastenerSize,
        wereStaplesUsedToInstallWoodShakesShingles,
        woodShakeShingleAreStainlessSteelFastenersRequired,
        wereStainlessFastenersUsedToInstallWoodShakesShingles,
        isSealedRoofDeckMaterialCompatibleWithWoodShakeShingle,
        wasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport,
        areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown,
        lowSlopeRoofCoverManufacturerName,
        lowSlopeRoofCoverProductName,
        lowSlopeRoofCoverTestReportApprovalNumber,
        lowSlopeRoofCoverCertifiedTestReportDocumentation,
        lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport,
        lowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport,
        lowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating,
        lowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport,
        lowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating,
        lowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport,
        lowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating,
        lowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
        lowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
        doesLowSlopeRoofCoverSystemHaveADesignatedImpactRating,
        lowSlopeRoofCoverFastenerTypeAndSize,
        lowSlopeRoofCoverFastenerSpacingZone1,
        lowSlopeRoofCoverFastenerSpacingZone2,
        lowSlopeRoofCoverFastenerSpacingZone3,
        wasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport,
      } = response.homeStandardEvaluation.roofingComplianceRoofCoverForm;

      const { canProceedToApplicationFee } = response.homeStandardEvaluation;

      return {
        ...prevState,
        homeStandardEvaluation: {
          ...prevState.homeStandardEvaluation,
          canProceedToApplicationFee,
          roofingComplianceRoofCoverForm: {
            ...prevState.homeStandardEvaluation.roofingComplianceRoofCoverForm,
            areAllRequiredFieldsComplete,
            roofCoverInformationRequiredFieldCount,
            roofCoverInformationLastSubmittedAt,
            roofCoverInformationHasBeenSubmitted,
            isRoofCoverInformationComplete,
            asphaltShingleRoofCoverDetailsRequiredFieldCount,
            asphaltShingleRoofCoverDetailsLastSubmittedAt,
            asphaltShingleRoofCoverDetailsHasBeenSubmitted,
            isAsphaltShingleRoofCoverDetailsComplete,
            metalRoofCoverDetailsRequiredFieldCount,
            metalRoofCoverDetailsLastSubmittedAt,
            metalRoofCoverDetailsHasBeenSubmitted,
            isMetalRoofCoverDetailsComplete,
            clayConcreteTileRoofCoverDetailsRequiredFieldCount,
            clayConcreteTileRoofCoverDetailsLastSubmittedAt,
            clayConcreteTileRoofCoverDetailsHasBeenSubmitted,
            isClayConcreteTileRoofCoverDetailsComplete,
            woodShakeSingleRoofCoverDetailsRequiredFieldCount,
            woodShakeSingleRoofCoverDetailsLastSubmittedAt,
            woodShakeSingleRoofCoverDetailsHasBeenSubmitted,
            isWoodShakeSingleRoofCoverDetailsComplete,
            lowSlopeRoofCoverDetailsRequiredFieldCount,
            lowSlopeRoofCoverDetailsLastSubmittedAt,
            lowSlopeRoofCoverDetailsHasBeenSubmitted,
            isLowSlopeRoofCoverDetailsComplete,
            roofCoverType,
            roofCoverTypeMinSlope,
            roofCoverTypeMinSlopePhoto,
            requiredWindRatingZone1,
            requiredWindRatingZone2,
            requiredWindRatingZone3,
            allZonesRequiredWindRatingDocumentation,
            areAsphaltShingleManufacturerAndProductDetailsKnown,
            asphaltShingleManufacturerName,
            asphaltShingleProductName,
            shingleManufacturerAndProductPhoto,
            doesAsphaltShingleWindRatingMeetASTMClassFOrClassH,
            asphaltShingleWindRatingType,
            shingleWindRatingPhoto,
            asphaltShingleHailRatingType,
            shingleHailRatingPhoto,
            doAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements,
            areAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions,
            numberOfInstalledNailsPerAsphaltShingle,
            startStripInstallationAtEavesType,
            startStripInstallationAtGlobeRakesType,
            starterStripInstallationPhotos,
            areMetalPanelManufacturerAndProductDetailsKnown,
            metalPanelManufacturerName,
            metalPanelProductName,
            metalPanelCertifiedTestReportProductApprovalNumber,
            metalPanelCertifiedTestReportDocumentation,
            metalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport,
            metalPanelFirstInstalledSystemNumberPerCertifiedTestReport,
            matelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating,
            metalPanelSecondInstalledSystemNumberPerCertifiedTestReport,
            metalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating,
            metalPanelThirdInstalledSystemNumberPerCertifiedTestReport,
            metalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating,
            metalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
            doMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
            installedMetalPanelMaterial,
            installedMetalPanelGaugeThickness,
            installedMetalPanelFastenerTypeAndSize,
            metalPanelFastenersPhoto,
            installedMetalPanelFastenerPatternAndSpacingZone1,
            installedMetalPanelFastenerPatternAndSpacingZone2,
            installedMetalPanelFastenerPatternAndSpacingZone3,
            metalPanelInstalledFastenerPatternAndSpacingPhotos,
            wasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards,
            doesMetalPanelSystemHaveUL2218Class4ImpactRating,
            areTheClayConcreteTileManufacturerAndProductDetailsKnown,
            clayConcreteTileManufacturerName,
            clayConcreteTileProductName,
            clayConcreteTileTestReportApprovalNumber,
            clayConcreteTileCertifiedTestReportDocumentation,
            clayConcreteTileNumberOfInstalledSystemsFromTestReport,
            clayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport,
            clayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating,
            clayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport,
            clayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating,
            clayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport,
            clayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating,
            clayConcreteTileSystemsIdentifiedFromTestReport,
            clayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
            doesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating,
            clayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports,
            tileStructuralSupportsCertifiedTestReportDocumentation,
            mechanicalAdhesiveRoofTileAttachmentType,
            mechanicalFastenerType,
            roofTileFastenersPhoto,
            mechanicalFastenerSize,
            mechanicalFastenerQuantityPerTile,
            doMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements,
            adhesiveFoamManufacturerName,
            adhesiveFoamProductName,
            adhesiveFoamTestReportApprovalNumber,
            isAdhesiveFoamInstallerCertifiedByFoamManufacturer,
            adhesiveFoamInstallerCertificationNumber,
            tileUnderlaymentCertifiedTestReportNumber,
            isTileAndFlashingInstalledPerFRSATRIInstructions,
            isTileAndFlashingInstalledPerTRIWSRCAInstructions,
            areTheWoodShakeShingleManufacturerAndProductDetailsKnown,
            woodShakeShingleManufacturerName,
            woodShakeShingleProductName,
            woodShakeShingleTestReportApprovalNumber,
            woodShakeShingleCertifiedTestReportDocumentation,
            woodShakeShingleDesignPressureWindRatingOfInstalledSystem,
            woodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications,
            woodShakeShingleMeanRoofHeightLimitation,
            woodShakeShingleRequiredNumberOfNailsPerShingle,
            woodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions,
            doesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating,
            woodShakeShingleFastenerType,
            woodShakeShingleFastenersPhoto,
            woodShakeShingleFastenerSize,
            wereStaplesUsedToInstallWoodShakesShingles,
            woodShakeShingleAreStainlessSteelFastenersRequired,
            wereStainlessFastenersUsedToInstallWoodShakesShingles,
            isSealedRoofDeckMaterialCompatibleWithWoodShakeShingle,
            wasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport,
            areTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown,
            lowSlopeRoofCoverManufacturerName,
            lowSlopeRoofCoverProductName,
            lowSlopeRoofCoverTestReportApprovalNumber,
            lowSlopeRoofCoverCertifiedTestReportDocumentation,
            lowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport,
            lowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport,
            lowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating,
            lowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport,
            lowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating,
            lowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport,
            lowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating,
            lowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport,
            lowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements,
            doesLowSlopeRoofCoverSystemHaveADesignatedImpactRating,
            lowSlopeRoofCoverFastenerTypeAndSize,
            lowSlopeRoofCoverFastenerSpacingZone1,
            lowSlopeRoofCoverFastenerSpacingZone2,
            lowSlopeRoofCoverFastenerSpacingZone3,
            wasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeStandardEvaluationRoofingComplianceRoofCoverForm;
