import {
  EmailFieldProps,
  EnumMultiSelectFieldV2Props,
  EnumRadioFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  TextFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import {
  DesignationLevelTypes,
  NewOrExistingRoofTypes,
  requiredBuildingElementTypesForSilverAndGold,
} from "../../../../common/types/BaseHomeEvaluationEligibilityForm";
import { YesOrNoSelection } from "../../../../../../wildfire/types/FormFields";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EligibilityForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const designationTypeAndLevelInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel
) => [
  buildFieldKey("NewConstructionOrExistingHome"),
  buildFieldKey("NewOrExistingRoof"),
  {
    fieldKey: buildFieldKey("RooferEmail"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof !=
      NewOrExistingRoofTypes.NewRoof,
  } as EmailFieldProps,
  buildFieldKey("HasHailSupplement"),
  buildFieldKey("DesignationLevel"),
  {
    fieldKey: buildFieldKey("BuildingElementTypes"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Silver &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Gold,
  } as EnumMultiSelectFieldV2Props,
  buildFieldKey("DistanceToSaltWaterShorelineType"),
  {
    fieldKey: buildFieldKey("InstallingContractorEmail"),
    hidden: !requiredBuildingElementTypesForSilverAndGold.some((type) =>
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        type
      )
    ),
  } as EmailFieldProps,
  {
    fieldKey: buildFieldKey(
      "IsEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements"
    ),
    hidden: !requiredBuildingElementTypesForSilverAndGold.some((type) =>
      form.homeStandardEvaluation.eligibilityForm.buildingElementTypes?.includes(
        type
      )
    ),
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("EngineerEmail"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements !=
      YesOrNoSelection.Yes,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey(
      "BuildingElementsEngineerIsProvidingComplianceDesignInfoFor"
    ),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements !=
      YesOrNoSelection.Yes,
  } as EnumMultiSelectFieldV2Props,
  {
    fieldKey: buildFieldKey("ContinuousLoadPathPathwayType"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
      DesignationLevelTypes.Gold,
  } as EnumMultiSelectFieldV2Props,
];

const designationTypeAndLevelInformationRequiredFields = [
  buildFieldKey("NewConstructionOrExistingHome"),
  buildFieldKey("NewOrExistingRoof"),
  buildFieldKey("RooferEmail"),
  buildFieldKey("HasHailSupplement"),
  buildFieldKey("DesignationLevel"),
  buildFieldKey("BuildingElementTypes"),
  buildFieldKey("DistanceToSaltWaterShorelineType"),
  buildFieldKey("InstallingContractorEmail"),
  buildFieldKey(
    "IsEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements"
  ),
  buildFieldKey("EngineerEmail"),
  buildFieldKey("BuildingElementsEngineerIsProvidingComplianceDesignInfoFor"),
  buildFieldKey("ContinuousLoadPathPathwayType"),
];

export const homeStandardDesignationTypeAndLevelInformationFieldsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        designationTypeAndLevelInformationFields(form),
        onValueChange,
        form,
        errors,
        designationTypeAndLevelInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

//Part 2: Property Details

const eligibilityInformationFields = (
  form: Evaluation_HomeStandardEvaluationFormModel,
  errors:
    | {
        [key: string]: string[];
      }
    | undefined
) => [
  buildFieldKey("DwellingType"),
  {
    fieldKey: buildFieldKey("DwellingTypePhotos"),
    defaultToUpload: true,
    errorMessages: get(errors, "dwellingTypePhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("IsHomeOnADryStackOrHudFoundation"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
      DesignationLevelTypes.Roof,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("IsHomeConnectedToFoundation"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Silver &&
      form.homeStandardEvaluation.eligibilityForm.designationLevel !=
        DesignationLevelTypes.Gold,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("FoundationEligibilityPhotos"),
    defaultToUpload: true,
    errorMessages: get(errors, "foundationEligibilityPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  buildFieldKey("IsProfessionalEngineerProvidingCAndCRoofUplifDesingPressures"),
  buildFieldKey("IsProjectAHomeBuiltByNonProfit"),
  {
    fieldKey: buildFieldKey("NonProfitEIN"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProjectAHomeBuiltByNonProfit != YesOrNoSelection.Yes,
  } as TextFieldProps,
  {
    fieldKey: buildFieldKey("LetterFromNonProfit"),
    defaultToUpload: true,
    errorMessages: get(errors, "letterFromNonProfit.FileCount"),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isProjectAHomeBuiltByNonProfit != YesOrNoSelection.Yes,
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "IsThisProjectReleatedToFloridaExistingHomesProject"
    ),
    hidden:
      form.homeStandardEvaluation.eligibilityForm.newOrExistingRoof !=
      NewOrExistingRoofTypes.ExistingRoof,
  } as EnumRadioFieldProps,
  {
    fieldKey: buildFieldKey("FloridaExistingHomeProjectEligibilityDocuments"),
    defaultToUpload: true,
    errorMessages: get(
      errors,
      "floridaExistingHomeProjectEligibilityDocuments.FileCount"
    ),
    hidden:
      form.homeStandardEvaluation.eligibilityForm
        .isThisProjectReleatedToFloridaExistingHomesProject !=
      YesOrNoSelection.Yes,
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("OtherDocuments"),
    defaultToUpload: true,
    errorMessages: get(errors, "otherDocuments.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const eligibilityInformationRequiredFields = [
  buildFieldKey("DwellingType"),
  buildFieldKey("DwellingTypePhotos"),
  buildFieldKey("IsHomeOnADryStackOrHudFoundation"),
  buildFieldKey("IsHomeConnectedToFoundation"),
  buildFieldKey("FoundationEligibilityPhotos"),
  buildFieldKey("IsProfessionalEngineerProvidingCAndCRoofUplifDesingPressures"),
  buildFieldKey("IsProjectAHomeBuiltByNonProfit"),
  buildFieldKey("NonProfitEIN"),
  buildFieldKey("LetterFromNonProfit"),
  buildFieldKey("IsThisProjectReleatedToFloridaExistingHomesProject"),
  buildFieldKey("FloridaExistingHomeProjectEligibilityDocuments"),
];

export const homeStandardEligibilityInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        eligibilityInformationFields(form, errors),
        onValueChange,
        form,
        errors,
        eligibilityInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardEligibilityFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...designationTypeAndLevelInformationFields(form),
          ...eligibilityInformationFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...designationTypeAndLevelInformationRequiredFields,
          ...eligibilityInformationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineEligibilityFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...designationTypeAndLevelInformationFields(form),
          ...eligibilityInformationFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...designationTypeAndLevelInformationRequiredFields,
          ...eligibilityInformationRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
