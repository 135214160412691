import {
  EvaluationStatus,
  EvaluationStep,
} from "../../../../foritfied/types/evaluation/Evaluation";

export enum ProductSubtypes {
  CommercialEvaluation = 1,
  CommercialRedesignation = 2,
  MultifamilyEvaluation = 3,
  MultifamilyRedesignation = 4,
  HomeStandardEvaluation = 5,
  HomeStandardRedesignation = 6,
  HomeSCSHEvaluation = 7,
  HomeSCSHRedesignation = 8,
  HomeNCIUAEvaluation = 9,
  HomeNCIUARedesignation = 10,
}

export interface BaseEvaluation {
  id: string;
  step: EvaluationStep;
  status_AsString: EvaluationStatus;
  fortifiedId?: string;
  approvedAt?: Date;
  isApproved?: boolean;
  expiresAt?: Date;
  auditorId?: string;
  externalFolderId?: string;
  isWithdrawn?: boolean;
  hasFinalDetermination?: boolean;
  auditorNotes: string;

  //FC FMF Fields
  isPaymentApplicable: boolean;
  hasPaymentBeenSubmitted: boolean;
  isComplexCommercialEvaluation: boolean;
  hasFCFMFPaymentWorkflow: boolean;
  isComplexCommercialEvaluationBuilding: boolean;
  complexCommercialAndMultifamilyEvaluationId: string;
  areBuildingsFinishedBeingProcessed: boolean;
}
