import {
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  NumericFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts {
  EngineeringComplianceGeneralInformation = "engineering-compliance-general-information",
}

export interface BaseHomeEvaluationEngineeringComplianceGeneralInformationForm
  extends IEngineeringComplianceGeneralInformation {}

interface IEngineeringComplianceGeneralInformation {
  BuildingCodeAndEdition?: TextFieldValue;
  ASCE7Edition?: EnumSingleSelectFieldValue;
  DesignWindSpeed?: NumericFieldValue;
  ShowWarning_DesignWindSpeedGreaterThan200?: boolean;
  ShowWarning_DesignWindSpeedBelow115?: boolean;
  ExposureCategory?: EnumSingleSelectFieldValue;
  Zone1CAndCRoofPressuresPositive?: NumericFieldValue;
  Zone1CAndCRoofPressuresNegative?: NumericFieldValue;
  Zone2CAndCRoofPressuresPositive?: NumericFieldValue;
  Zone2CAndCRoofPressuresNegative?: NumericFieldValue;
  Zone3CAndCRoofPressuresPositive?: NumericFieldValue;
  Zone3CAndCRoofPressuresNegative?: NumericFieldValue;
  CAndCRoofDesignUpliftPressuresDocumentation?: WildfireBoxContentExplorerValue;
  DidTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5?: EnumRadioFieldValue;
  Zone4CAndCWallWindPressuresPositive?: NumericFieldValue;
  Zone4CAndCWallWindPressuresNegative?: NumericFieldValue;
  Zone5CAndCWallWindPressuresPositive?: NumericFieldValue;
  Zone5CAndCWallWindPressuresNegative?: NumericFieldValue;
  CAndCWallDesignWindPressuresDocumentation?: WildfireBoxContentExplorerValue;
}
