import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { MultifamilyRedesignationFieldInspectionFormModel } from "../../types/MultifamilyRedesignationFieldInspectionFormModel";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../types/MultifamilyRedesignationFormModel";

class MultifamilyRedesignationFieldInspectionFormAPIClient {
  updateCommercialEvaluationFieldInspectionForm = async (
    evaluationId: string,
    fieldInspectionForm: MultifamilyRedesignationFieldInspectionFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    console.log("fieldInspectionForm", fieldInspectionForm);
    return baseApiClient.put<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-field-inspection-form/${evaluationId}`,
      {
        fieldInspectionForm,
        fieldKey,
      }
    );
  };

  submitCommercialEvaluationFieldInspectionForm = async (
    evaluationId: string,
    fieldInspectionForm: MultifamilyRedesignationFieldInspectionFormModel
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    return baseApiClient.post<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-field-inspection-form/${evaluationId}`,
      fieldInspectionForm
    );
  };
}

export const multifamilyRedesignationFieldInspectionFormAPIClient =
  new MultifamilyRedesignationFieldInspectionFormAPIClient();
