import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationEngineeringComplianceGablesFormParts } from "../../../common/types/BaseHomeEvaluationEngineeringComplianceGablesForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineeringComplianceGablesFormModel } from "../../types/HomeStandardEvaluationEngineeringComplianceGablesFormModel";

class HomeStandardEvaluationEngineeringComplianceGablesAPIClient {
  updateHomeStandardEngineeringComplianceGablesForm = async (
    evaluationId: string,
    engineeringComplianceGablesForm: HomeStandardEvaluationEngineeringComplianceGablesFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-gables-form/${evaluationId}`,
      {
        engineeringComplianceGablesForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardEngineeringComplianceGablesForm = async (
    evaluationId: string,
    engineeringComplianceGablesForm: HomeStandardEvaluationEngineeringComplianceGablesFormModel,
    formPart?: BaseHomeEvaluationEngineeringComplianceGablesFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineeringComplianceGablesFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceGablesFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-gables-form/${evaluationId}/${formPartValue}`,
      engineeringComplianceGablesForm
    );
  };
}

export const homeStandardEvaluationEngineeringComplianceGablesAPIClient =
  new HomeStandardEvaluationEngineeringComplianceGablesAPIClient();
