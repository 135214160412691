import {
  EnumRadioFieldProps,
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
  ReadOnlyFieldProps,
} from "@ucl/library";
import { FC, useRef, useState } from "react";
import "./styles.scss";
import { PaymentCreditResponseReadDTO } from "../../types/PaymentCredit/PaymentCreditResponseReadDTO";
import WildfireSecondaryButton from "../../../../wildfire/components/Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../../../wildfire/components/Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { closePaymentCreditDialogV2 } from "./PaymentCreditDialogV2";
import { EvaluationStep } from "../../../../foritfied/types/evaluation/Evaluation";
import { commercialEvaluationAPIClient } from "../../../FCFMF/commercial/lib/apiClients/commercialEvaluationAPIClient";
import { multifamilyEvaluationAPIClient } from "../../../FCFMF/multifamily/lib/apiClients/multifamilyEvaluationAPIClient";
import { useNavigate } from "react-router";
import { getFortifiedPaymentPageRoute } from "../../util/paymentRouteUtils";
import { ProductSubtypes } from "../../types/Evaluation/BaseEvaluation";
import { homeStandardEvaluationAPIClient } from "../../../Home/standard/lib/apiClients/homeStandardEvaluationAPIClient";
import { homeSCSHEvaluationAPIClient } from "../../../Home/scsh/lib/apiClients/homeSCSHEvaluationAPIClient";

interface PaymentCreditFormModel {
  creditType: number;
}

export interface PaymentCreditFormProps {
  paymentCredit: PaymentCreditResponseReadDTO;
  fortifiedId: string;
  productSubtype: ProductSubtypes;
  step: EvaluationStep;
  setEvaluation: (evaluation: any) => void;
  evaluationId: string;
}

const optionValues = [
  {
    label: "IBHS Credit",
    value: 1,
  },
  {
    label: "Credit or Debit Card",
    value: 2,
  },
];

export const PaymentCreditForm: FC<PaymentCreditFormProps> = (props) => {
  const paymentCreditFormRef =
    useRef<FieldFormV2Module<PaymentCreditFormModel>>(null);

  const [paymentCreditFormModel, setPaymentCreditFormModel] =
    useState<PaymentCreditFormModel>();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const paymentCreditFormBuilder: FieldSchemaFormBuilderV2<
    PaymentCreditFormModel
  > = (onValueChange, form, _) => {
    return [
      {
        type: "EnumRadio",
        fieldName: "paymentCreditType",
        fieldKey: "paymentCreditType",
        label: "How would you like to pay?",
        value: form.creditType,
        optionValues: optionValues,
        onSubmit: function (value) {
          onValueChange({ ...form, creditType: value as number }, value, this);
        },
      } as EnumRadioFieldProps,
      {
        type: "ReadOnly",
        fieldName: "paymentCreditTypeNote",
        fieldKey: "paymentCreditTypeNote",
        value:
          "* After payment is submitted, this Evaluation will be automatically sent to IBHS for review.",
        hidden:
          props.step !== EvaluationStep.ApplicationFee &&
          props.step !== EvaluationStep.Payment,
      } as ReadOnlyFieldProps,
    ];
  };

  const submitHomeStandardPaymentCredit = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .applyApplicationFeePaymentCredit(props.evaluationId)
      .then((evaluation) => {
        props.setEvaluation(evaluation);
        closePaymentCreditDialogV2();
        navigate(`/fh`);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitHomeSCSHPaymentCredit = async () => {
    setIsLoading(true);
    await homeSCSHEvaluationAPIClient
      .applyApplicationFeePaymentCredit(props.evaluationId)
      .then((evaluation) => {
        props.setEvaluation(evaluation);
        closePaymentCreditDialogV2();
        navigate(`/fh`);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitCommercialPaymentCredit = async () => {
    switch (props.step) {
      case EvaluationStep.ApplicationFee:
        setIsLoading(true);
        await commercialEvaluationAPIClient
          .applyApplicationFeePaymentCredit(props.evaluationId)
          .then((evaluation) => {
            props.setEvaluation(evaluation);
            closePaymentCreditDialogV2();
            navigate(`/fc-fmf`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case EvaluationStep.CertificateFee:
        setIsLoading(true);
        await commercialEvaluationAPIClient
          .applyCertificateFeePaymentCredit(props.evaluationId)
          .then((evaluation) => {
            props.setEvaluation(evaluation);
            closePaymentCreditDialogV2();
            navigate(`/fc-fmf`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }
  };

  const submitMultifamilyPaymentCredit = async () => {
    switch (props.step) {
      case EvaluationStep.ApplicationFee:
        setIsLoading(true);
        await multifamilyEvaluationAPIClient
          .applyApplicationFeePaymentCredit(props.evaluationId)
          .then((evaluation) => {
            props.setEvaluation(evaluation);
            closePaymentCreditDialogV2();
            navigate(`/fc-fmf`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case EvaluationStep.CertificateFee:
        setIsLoading(true);
        await multifamilyEvaluationAPIClient
          .applyCertificateFeePaymentCredit(props.evaluationId)
          .then((evaluation) => {
            props.setEvaluation(evaluation);
            closePaymentCreditDialogV2();
            navigate(`/fc-fmf`);
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
    }
  };

  const submitPaymentCreditSelection = async (form: PaymentCreditFormModel) => {
    if (form.creditType === 1) {
      switch (props.productSubtype) {
        case ProductSubtypes.HomeStandardEvaluation:
          await submitHomeStandardPaymentCredit();
          break;
        case ProductSubtypes.HomeStandardRedesignation:
          // TODO - When Home V2 is ready
          break;
        case ProductSubtypes.HomeSCSHEvaluation:
          await submitHomeSCSHPaymentCredit();
          break;
        case ProductSubtypes.HomeSCSHRedesignation:
          // TODO - When Home V2 is ready
          break;
        case ProductSubtypes.HomeNCIUAEvaluation:
          // TODO - When Home V2 is ready
          break;
        case ProductSubtypes.HomeNCIUARedesignation:
          // TODO - When Home V2 is ready
          break;
        case ProductSubtypes.CommercialEvaluation:
          await submitCommercialPaymentCredit();
          break;
        case ProductSubtypes.CommercialRedesignation:
          // TODO - When Commercial Redesignations are ready
          break;
        case ProductSubtypes.MultifamilyEvaluation:
          await submitMultifamilyPaymentCredit();
          break;
        case ProductSubtypes.MultifamilyRedesignation:
          // TODO - When Multifamily Redesignations are ready
          break;
      }
    } else {
      closePaymentCreditDialogV2();

      const route = getFortifiedPaymentPageRoute(
        props.productSubtype,
        props.evaluationId,
        props.step
      );
      if (route) {
        navigate(route);
      }
    }
  };

  return (
    <div className="apply-payment-credit-dialog-v2">
      <FieldFormV2<PaymentCreditFormModel>
        ref={paymentCreditFormRef}
        value={paymentCreditFormModel}
        fieldSchemaFormBuilder={paymentCreditFormBuilder}
        onFormSubmit={async (form) => {
          await submitPaymentCreditSelection(form);
        }}
        onFieldChanged={(form) => {
          setPaymentCreditFormModel(form);
        }}
      />
      <Footer>
        <WildfireSecondaryButton
          buttonText="Cancel"
          onClick={() => closePaymentCreditDialogV2()}
          disabled={isLoading}
        />
        <WildfireSubmitButton
          buttonText="Submit"
          onClick={() => paymentCreditFormRef.current?.submit()}
          isLoading={isLoading}
          disabled={paymentCreditFormModel?.creditType === undefined}
        />
      </Footer>
    </div>
  );
};
