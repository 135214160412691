import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEngineeringComplianceGeneralInformationFormContainerProps } from "./HomeStandardEngineeringComplianceGeneralInformationFormContainer";
import { BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceGeneralInformationForm";

function useHomeStandardEngineeringComplianceGeneralInformationFormContainer(
  props: HomeStandardEngineeringComplianceGeneralInformationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.engineeringComplianceGeneralInformationFormPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    | BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
    | undefined
  >(props.engineeringComplianceGeneralInformationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const EngineeringComplianceGeneralInformationForm =
    evaluationFormModel?.homeStandardEvaluation
      .engineeringComplianceGeneralInformationForm;

  const getNextUnsubmittedFormPart = () => {
    if (!EngineeringComplianceGeneralInformationForm) {
      return null;
    }

    if (
      !EngineeringComplianceGeneralInformationForm.isEngineeringComplianceGeneralInformationComplete
    ) {
      return BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts.EngineeringComplianceGeneralInformation;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.engineeringComplianceGeneralInformationFormPart) {
        return props.engineeringComplianceGeneralInformationFormPart;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts.EngineeringComplianceGeneralInformation:
        return EngineeringComplianceGeneralInformationForm?.isEngineeringComplianceGeneralInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts.EngineeringComplianceGeneralInformation,
        displayName: "General Information",
        requiredFieldCount:
          EngineeringComplianceGeneralInformationForm?.EngineeringComplianceGeneralInformationRequiredCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart: formPart,
    getBestFormPart,
    commercialSchemaFactory,
  };
}

export default useHomeStandardEngineeringComplianceGeneralInformationFormContainer;
