import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts } from "../../../common/types/BaseHomeEvaluationInstallerComplianceContinuousLoadPathForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationInstallerComplianceContinuousLoadPathFormModel } from "../../types/HomeStandardEvaluationInstallerComplianceContinuousLoadPathFormModel";

class HomeStandardEvaluationInstallerComplianceContinuousLoadPathAPIClient {
  updateHomeStandardInstallerComplianceContinuousLoadPathForm = async (
    evaluationId: string,
    installerComplianceContinuousLoadPathForm: HomeStandardEvaluationInstallerComplianceContinuousLoadPathFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-continuous-load-path-form/${evaluationId}`,
      {
        installerComplianceContinuousLoadPathForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardInstallerComplianceContinuousLoadPathForm = async (
    evaluationId: string,
    installerComplianceContinuousLoadPathForm: HomeStandardEvaluationInstallerComplianceContinuousLoadPathFormModel,
    formPart?: BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts
      ).pop() as BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installer-compliance-continuous-load-path-form/${evaluationId}/${formPartValue}`,
      installerComplianceContinuousLoadPathForm
    );
  };
}

export const homeStandardEvaluationInstallerComplianceContinuousLoadPathAPIClient =
  new HomeStandardEvaluationInstallerComplianceContinuousLoadPathAPIClient();
