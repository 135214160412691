import {
  Footer,
  RichTextEditorField,
  RichTextEditorValueType,
} from "@ucl/library";
import { FC, useState } from "react";
import "./styles.scss";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";

export interface AuditorNotesUpdateFormProps {
  onCancel: () => void;
  onSubmit: (auditorNotes: string) => Promise<void>;
  auditorNotes?: string;
  canViewAuditorNotes: boolean;
  submitButtonText?: string;
  cancelButtonText?: string;
}

export const AuditorNotesUpdateForm: FC<AuditorNotesUpdateFormProps> = ({
  onCancel,
  onSubmit,
  auditorNotes,
  submitButtonText,
  cancelButtonText,
  canViewAuditorNotes,
}) => {
  const [localAuditorNotes, setAuditorNotes] = useState<string | undefined>(
    auditorNotes
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const localsubmitButtonText = submitButtonText || "Submit";
  const localCancelButtonText = cancelButtonText || "Cancel";

  return (
    <div className="wildfire-auditor-notes-form">
      {canViewAuditorNotes && (
        <RichTextEditorField
          valueType={RichTextEditorValueType.html}
          value={localAuditorNotes}
          disabled={isSaving}
          onSubmit={(value) => {
            setAuditorNotes(value ?? "");
          }}
          richTextEditorSettings={{
            height: "200px",
            enableHtmlSanitizer: true,
            autoSaveOnIdle: true,
            saveInterval: 50,
            toolbarSettings: {
              items: [
                "OrderedList",
                "UnorderedList",
                "Bold",
                "Underline",
                "Italic",
                "StrikeThrough",
                "Formats",
                "ClearFormat",
              ],
            } as ToolbarSettingsModel,
            pasteCleanupSettings: {
              prompt: false,
              plainText: false,
              keepFormat: true,
              allowedStyleProps: [""],
              deniedAttrs: ["class", "id", "style"],
            },
          }}
        />
      )}
      <Footer>
        <WildfireSecondaryButton
          buttonText={localCancelButtonText}
          onClick={onCancel}
          disabled={isSaving}
        />
        <WildfireSubmitButton
          buttonText={localsubmitButtonText}
          isLoading={isSaving}
          onClick={async () => {
            setIsSaving(true);
            await onSubmit(localAuditorNotes || "");
            setIsSaving(false);
          }}
        />
      </Footer>
    </div>
  );
};
