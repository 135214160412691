import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_HomeNCIUAEvaluation } from "../../types/HomeNCIUAEvaluation";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";

class HomeNCIUAEvaluationAPIClient {
  createHomeNCIUAEvaluation = async () => {
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluation>(
      `home-nciua/evaluation`
    );
  };

  getHomeNCIUAEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUAEvaluation>(
      `home-nciua/evaluation/${evaluationId}`
    );
  };

  getHomeNCIUAEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUAEvaluationFormModel>(
      `home-nciua/evaluation/${evaluationId}`
    );
  };
}

export const homeNCIUAEvaluationAPIClient = new HomeNCIUAEvaluationAPIClient();
