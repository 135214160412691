import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { PagesLayout } from "./PagesLayout";
import { createBrowserRouter } from "react-router-dom";
import { utils, WithParams } from "@ucl/library";
import { Board } from "../Board";
import { Evaluation } from "../Evaluation";
import { Checklist } from "../Checklist";
import { ReportsPage } from "../ReportsPage";
import { AdminPage } from "../AdminPage";
import { HelpPage } from "../HelpPage";
import { EvaluationBidPage } from "../EvaluationBidPage";
import { ExternalEvaluationSummaryPage } from "../ExternalEvaluationSummaryPage";
import { ReportViewerPage } from "../reports/ReportViewerPage";
import { fortifiedHomeLogout } from "../../../common/lib/msal/fortifiedHomeMsal";
import { HomeBasicInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationBasicInformationForm";
import { HomePagesLayout } from "../../../fortifiedV2/Home/common/pages/index/HomePagesLayout";
import { HomeStandardBasicInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardBasicInformationPage";
import { HomeStandardRoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardRoofingComplianceGeneralInformationPage";
import { HomeStandardIterationEnginePage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardIterationEnginePage";
import {
  faChartSimple,
  faList,
  faSliders,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { HomeSCSHBasicInformationFormPage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHBasicInformationFormPage";
import { HomeSCSHIterationEnginePage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHIterationEnginePage";
import { HomeSCSHEvaluationFormPage } from "../../../fortifiedV2/Home/scsh/pages/HomeSCSHEvaluationFormPage";
import { SCSHEvaluationForm_FormParts } from "../../../fortifiedV2/Home/scsh/types/HomeSCSHEvaluation_EvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../fortifiedV2/Home/standard/lib/apiClients/homeStandardEvaluationAPIClient";
import { PaymentRedirectPage } from "../../../fortifiedV2/Common/components/Pages/PaymentRedirectPage";
import { homeSCSHEvaluationAPIClient } from "../../../fortifiedV2/Home/scsh/lib/apiClients/homeSCSHEvaluationAPIClient";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRooferConfirmationForm";
import { HomeStandardRooferConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardRooferConfirmationPage";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationForm";
import { BaseHomeEvaluationHomeownerAuthorizationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationHomeownerAuthorizationForm";
import { HomeStandardHomeownerAuthorizationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardHomeownerAuthorizationPage";
import { HomeStandardRoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardRoofingComplianceRoofCoverPage";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRoofingComplianceRoofCoverForm";
import { BaseHomeEvaluationEngineerConfirmationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineerConfirmationForm";
import { HomeStandardEngineerConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineerConfirmationPage";
import { HomeStandardSilverGoldComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardSilverGoldComplianceGeneralInformationPage";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationForm";
import { HomeStandardInstallingContractorConfirmationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallingContractorConfirmationPage";
import { BaseHomeEvaluationInstallingContractorConfirmationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallingContractorConfirmationForm";
import { HomeStandardInstallerComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceGablesPage";
import { BaseHomeEvaluationInstallerComplianceGablesFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceGablesForm";
import { HomeStandardInstallerComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceContinuousLoadPathPage";
import { BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceContinuousLoadPathForm";
import { HomeStandardInstallerComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceChimneyPage";
import { BaseHomeEvaluationInstallerComplianceChimneyFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceChimneyForm";
import { HomeStandardInstallerComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardInstallerComplianceAttachedStructurePage";
import { BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationInstallerComplianceAttachedStructureForm";
import { HomeStandardEngineeringComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceGeneralInformationPage";
import { BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceGeneralInformationForm";
import { BaseHomeEvaluationEngineeringComplianceGablesFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceGablesForm";
import { HomeStandardEngineeringComplianceGablesPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceGablesPage";
import { HomeStandardEngineeringComplianceContinuousLoadPathPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceContinuousLoadPathPage";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathForm";
import { BaseHomeEvaluationEngineeringComplianceChimneyFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceChimneyForm";
import { HomeStandardEngineeringComplianceChimneyPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceChimneyPage";
import { HomeStandardEngineeringComplianceAttachedStructurePage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEngineeringComplianceAttachedStructurePage";
import { BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEngineeringComplianceAttachedStructureForm";
import { HomeNCIUAEvaluationFormPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUAEvaluationFormPage";
import { NCIUAEvaluationForm_FormParts } from "../../../fortifiedV2/Home/nciua/evaluation/types/HomeNCIUAEvaluation_EvaluationFormModel";
import { HomeNCIUAIterationEnginePage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUAIterationEnginePage";
import { HomeNCIUARedesignationIterationEnginePage } from "../../../fortifiedV2/Home/nciuaRedesignation/pages/HomeNCIUARedesignationIterationEnginePage";
import { HomeNCIUARoofingComplianceRoofCoverPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUARoofingComplianceRoofCoverPage";
import { HomeNCIUARoofingComplianceGeneralInformationPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUARoofingComplianceGeneralInformationPage";
import { HomeNCIUARooferConfirmationPage } from "../../../fortifiedV2/Home/nciua/pages/HomeNCIUARooferConfirmationPage";
import { HomeNCIUARedesignationHomeownerAuthorizationPage } from "../../../fortifiedV2/Home/nciuaRedesignation/pages/HomeNCUIARedesignationHomeownerAuthorizationPage";
import { HomeNCIUARedesignationFormPage } from "../../../fortifiedV2/Home/nciuaRedesignation/pages/HomeNCUIARedesignationFormPage";
import { BaseHomeEvaluationRedesignationFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationRedesignationForm";
import { HomeStandardRedesignationIterationEnginePage } from "../../../fortifiedV2/Home/standardRedesignation/pages/HomeStandardRedesignationIterationEnginePage";
import { HomeStandardEligibilityPage } from "../../../fortifiedV2/Home/standard/pages/HomeStandardEligibilityPage";
import { HomeEligibilityFormParts } from "../../../fortifiedV2/Home/common/types/BaseHomeEvaluationEligibilityForm";

const errorWrapper = (
  <div className="fortified-page">
    <ErrorWrapper logOutUser={fortifiedHomeLogout} />
  </div>
);

export const fortifiedHomeRoutePrefix = "/fh";

export const fortifiedHomeStandardRoutePrefix = `${fortifiedHomeRoutePrefix}/standard`;
export const fortifiedHomeStandardRedesignationRoutePrefix = `${fortifiedHomeRoutePrefix}/standard-redesignation`;

export const fortifiedHomeSCSHRoutePrefix = `${fortifiedHomeRoutePrefix}/scsh`;

export const fortifiedHomeNCIUARoutePrefix = `${fortifiedHomeRoutePrefix}/nciua`;
export const fortifiedHomeNCIUARedesignationRoutePrefix = `${fortifiedHomeRoutePrefix}/nciua-redesignation`;

export const uclRouteObject = [
  {
    path: `${fortifiedHomeRoutePrefix}`,
    label: "Board",
    icon: faList,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <Board />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/evaluation/:id`,
    label: "Evaluation Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Evaluation Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <Evaluation />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/checklist`,
    label: "Checklist Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Checklist Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <Checklist />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/reports`,
    label: "Reports",
    icon: faChartSimple,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <ReportsPage />,
      },
      {
        path: ":slug",
        label: "Reports",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <ReportViewerPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/admin`,
    label: "Admin",
    icon: faSliders,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Admin Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: <AdminPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/help`,
    label: "Help",
    icon: faQuestionCircle,
    isAvailable: true,
    showInNavigation: true,
    showInSubNavigation: true,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Help",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: true,
        errorElement: errorWrapper,
        element: <HelpPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/bid/:id`,
    label: "Bids Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Bids Page",
        isAvailable: true,
        showInNavigation: false,
        errorElement: errorWrapper,
        showInSubNavigation: false,
        element: <EvaluationBidPage />,
      },
    ],
  },
  {
    path: `${fortifiedHomeRoutePrefix}/external/evaluation-summary/:id`,
    label: "Bids Page",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <PagesLayout redirectPath="/" errorWrapper={errorWrapper} />,
    children: [
      {
        path: "",
        label: "Bids Page",
        isAvailable: true,
        showInNavigation: false,
        errorElement: errorWrapper,
        showInSubNavigation: false,
        element: <ExternalEvaluationSummaryPage />,
      },
    ],
  },
  //Home V2 Routes
  {
    path: `${fortifiedHomeStandardRoutePrefix}/:evaluationId`,
    label: "Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "payment",
        label: "Payment",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              if (!evaluationId) {
                return errorWrapper;
              }

              return (
                <PaymentRedirectPage
                  getCheckoutUrl={async () =>
                    homeStandardEvaluationAPIClient.getApplicationFeeStripeCheckoutLink(
                      evaluationId
                    )
                  }
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "basic-information",
        label: "Basic Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardBasicInformationPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "basic-information/:homeStandardBasicInformationFormPart",
        label: "Application Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            homeStandardBasicInformationFormPart: HomeBasicInformationFormParts;
          }>>
            {({ evaluationId, homeStandardBasicInformationFormPart }) => {
              return (
                <HomeStandardBasicInformationPage
                  evaluationId={evaluationId}
                  homeStandardBasicInformationFormPart={
                    homeStandardBasicInformationFormPart
                  }
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "eligibility",
        label: "Eligibility Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardEligibilityPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "eligibility/:formPart",
        label: "Eligibility Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: HomeEligibilityFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardEligibilityPage
                  evaluationId={evaluationId}
                  formPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "roofing-compliance-general-information",
        label: "Roofing Compliance General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardRoofingComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "roofing-compliance-general-information/:formPart",
        label: "Roofing Compliance General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardRoofingComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                  formParts={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "roofing-compliance-roof-cover",
        label: "Roofing Compliance Roof Cover Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardRoofingComplianceRoofCoverPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "roofing-compliance-roof-cover/:formPart",
        label: "Roofing Compliance Roof Cover Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeRoofingComplianceRoofCoverFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardRoofingComplianceRoofCoverPage
                  evaluationId={evaluationId}
                  formParts={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "roofer-confirmation",
        label: "Roofer Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardRooferConfirmationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "roofer-confirmation/:formPart",
        label: "Roofer Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationRooferConfirmationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardRooferConfirmationPage
                  evaluationId={evaluationId}
                  rooferConfirmationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "homeowner-authorization",
        label: "Homeowner Authorization Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardHomeownerAuthorizationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "homeowner-authorization/:formPart",
        label: "Homeowner Authorization Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationHomeownerAuthorizationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardHomeownerAuthorizationPage
                  evaluationId={evaluationId}
                  homeownerAuthorizationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "engineer-confirmation",
        label: "Engineer Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardEngineerConfirmationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "engineer-confirmation/:formPart",
        label: "Engineer Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationEngineerConfirmationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardEngineerConfirmationPage
                  evaluationId={evaluationId}
                  engineerConfirmationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "silver-gold-compliance-general-information",
        label: "Silver/Gold Compliance General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardSilverGoldComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "silver-gold-compliance-general-information/:formPart",
        label: "Silver/Gold Compliance General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardSilverGoldComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                  silverGoldComplianceGeneralInformationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "installing-contractor-confirmation",
        label: "Installing Contractor Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardInstallingContractorConfirmationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "installing-contractor-confirmation/:formPart",
        label: "Installing Contractor Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationInstallingContractorConfirmationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardInstallingContractorConfirmationPage
                  evaluationId={evaluationId}
                  installingContractorConfirmationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "installer-compliance-gables",
        label: "Installer Compliance Gables Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardInstallerComplianceGablesPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "installer-compliance-gables/:formPart",
        label: "Installer Compliance Gables Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationInstallerComplianceGablesFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardInstallerComplianceGablesPage
                  evaluationId={evaluationId}
                  installerComplianceGablesFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "installer-compliance-continuous-load-path",
        label: "Installer Compliance Continuous Load Path Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardInstallerComplianceContinuousLoadPathPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "installer-compliance-continuous-load-path/:formPart",
        label: "Installer Compliance Continuous Load Path Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationInstallerComplianceContinuousLoadPathFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardInstallerComplianceContinuousLoadPathPage
                  evaluationId={evaluationId}
                  installerComplianceContinuousLoadPathFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "installer-compliance-chimney",
        label: "Installer Compliance Chimney Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardInstallerComplianceChimneyPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "installer-compliance-chimney/:formPart",
        label: "Installer Compliance Chimney Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationInstallerComplianceChimneyFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardInstallerComplianceChimneyPage
                  evaluationId={evaluationId}
                  installerComplianceChimneyFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "installer-compliance-attached-structure",
        label: "Installer Compliance Attached Structure Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardInstallerComplianceAttachedStructurePage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "installer-compliance-attached-structure/:formPart",
        label: "Installer Compliance Attached Structure Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardInstallerComplianceAttachedStructurePage
                  evaluationId={evaluationId}
                  installerComplianceAttachedStructureFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "engineering-compliance-general-information",
        label: "Engineering Compliance General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardEngineeringComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "engineering-compliance-general-information/:formPart",
        label: "Engineering Compliance General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardEngineeringComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                  engineeringComplianceGeneralInformationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "engineering-compliance-gables",
        label: "Engineering Compliance Gables Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardEngineeringComplianceGablesPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "engineering-compliance-gables/:formPart",
        label: "Engineering Compliance Gables Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationEngineeringComplianceGablesFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardEngineeringComplianceGablesPage
                  evaluationId={evaluationId}
                  engineeringComplianceGablesFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "engineering-compliance-continuous-load-path",
        label: "Engineering Compliance Continuous Load Path Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardEngineeringComplianceContinuousLoadPathPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "engineering-compliance-continuous-load-path/:formPart",
        label: "Engineering Compliance Continuous Load Path Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardEngineeringComplianceContinuousLoadPathPage
                  evaluationId={evaluationId}
                  engineeringComplianceContinuousLoadPathFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "engineering-compliance-chimney",
        label: "Engineering Compliance Chimney Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardEngineeringComplianceChimneyPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "engineering-compliance-chimney/:formPart",
        label: "Engineering Compliance Chimney Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationEngineeringComplianceChimneyFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardEngineeringComplianceChimneyPage
                  evaluationId={evaluationId}
                  engineeringComplianceChimneyFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "engineering-compliance-attached-structure",
        label: "Engineering Compliance Attached Structure Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardEngineeringComplianceAttachedStructurePage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "engineering-compliance-attached-structure/:formPart",
        label: "Engineering Compliance Attached Structure Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeStandardEngineeringComplianceAttachedStructurePage
                  evaluationId={evaluationId}
                  engineeringComplianceAttachedStructureFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  {
    path: `${fortifiedHomeStandardRoutePrefix}/iteration-engine/:evaluationId`,
    label: "Iteration Engine",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardIterationEnginePage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  //Home Standard redesignation routes
  {
    path: `${fortifiedHomeStandardRedesignationRoutePrefix}/iteration-engine/:evaluationId`,
    label: "Iteration Engine",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeStandardRedesignationIterationEnginePage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  //scsh routes
  {
    path: `${fortifiedHomeSCSHRoutePrefix}/:evaluationId`,
    label: "Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "payment",
        label: "Payment",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              if (!evaluationId) {
                return errorWrapper;
              }

              return (
                <PaymentRedirectPage
                  getCheckoutUrl={async () =>
                    homeSCSHEvaluationAPIClient.getApplicationFeeStripeCheckoutLink(
                      evaluationId
                    )
                  }
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "basic-information",
        label: "Basic Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeSCSHBasicInformationFormPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "basic-information/:homeSCSHBasicInformationFormPart",
        label: "Basic Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            homeSCSHBasicInformationFormPart: HomeBasicInformationFormParts;
          }>>
            {({ evaluationId, homeSCSHBasicInformationFormPart }) => {
              return (
                <HomeSCSHBasicInformationFormPage
                  evaluationId={evaluationId}
                  homeSCSHBasicInformationFormPart={
                    homeSCSHBasicInformationFormPart
                  }
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "evaluation-form",
        label: "SCSH Evaluation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return <HomeSCSHEvaluationFormPage evaluationId={evaluationId} />;
            }}
          </WithParams>
        ),
      },
      {
        path: "evaluation-form/:homeSCSHEvaluationFormPart",
        label: "SCSH Evaluation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            homeSCSHEvaluationFormPart: SCSHEvaluationForm_FormParts;
          }>>
            {({ evaluationId, homeSCSHEvaluationFormPart }) => {
              return (
                <HomeSCSHEvaluationFormPage
                  evaluationId={evaluationId}
                  homeSCSHEvaluationFormPart={homeSCSHEvaluationFormPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  {
    path: `${fortifiedHomeSCSHRoutePrefix}/iteration-engine/:evaluationId`,
    label: "Iteration Engine",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeSCSHIterationEnginePage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  //nciua routes
  {
    path: `${fortifiedHomeNCIUARoutePrefix}/:evaluationId`,
    label: "Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "evaluation-form",
        label: "NCIUA Evaluation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUAEvaluationFormPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "evaluation-form/:homeNCIUAEvaluationFormPart",
        label: "NCIUA Evaluation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            homeNCIUAEvaluationFormPart: NCIUAEvaluationForm_FormParts;
          }>>
            {({ evaluationId, homeNCIUAEvaluationFormPart }) => {
              return (
                <HomeNCIUAEvaluationFormPage
                  evaluationId={evaluationId}
                  homeNCIUAEvaluationFormPart={homeNCIUAEvaluationFormPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "roofing-compliance-roof-cover",
        label: "Roofing Compliance Roof Cover Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUARoofingComplianceRoofCoverPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "roofing-compliance-roof-cover/:formPart",
        label: "Roofing Compliance Roof Cover Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeRoofingComplianceRoofCoverFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeNCIUARoofingComplianceRoofCoverPage
                  evaluationId={evaluationId}
                  formParts={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "roofing-compliance-general-information",
        label: "Roofing Compliance: General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUARoofingComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "roofing-compliance-general-information/:formPart",
        label: "Roofing Compliance: General Information Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeNCIUARoofingComplianceGeneralInformationPage
                  evaluationId={evaluationId}
                  formParts={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "roofer-confirmation",
        label: "Roofer Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUARooferConfirmationPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "roofer-confirmation/:formPart",
        label: "Roofer Confirmation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationRooferConfirmationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeNCIUARooferConfirmationPage
                  evaluationId={evaluationId}
                  formParts={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  {
    path: `${fortifiedHomeNCIUARoutePrefix}/iteration-engine/:evaluationId`,
    label: "Iteration Engine",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUAIterationEnginePage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  //nciua redesignation routes
  {
    path: `${fortifiedHomeNCIUARedesignationRoutePrefix}/:evaluationId`,
    label: "Forms",
    icon: undefined,
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "homeowner-authorization",
        label: "Homeowner Authorization Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUARedesignationHomeownerAuthorizationPage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "homeowner-authorization/:formPart",
        label: "Homeowner Authorization Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationHomeownerAuthorizationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeNCIUARedesignationHomeownerAuthorizationPage
                  evaluationId={evaluationId}
                  homeownerAuthorizationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
      {
        path: "redesignation-form",
        label: "Redesignation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        errorElement: errorWrapper,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUARedesignationFormPage evaluationId={evaluationId} />
              );
            }}
          </WithParams>
        ),
      },
      {
        path: "redesignation-form/:formPart",
        label: "Redesignation Form Page",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
            formPart: BaseHomeEvaluationRedesignationFormParts;
          }>>
            {({ evaluationId, formPart }) => {
              return (
                <HomeNCIUARedesignationFormPage
                  evaluationId={evaluationId}
                  redesignationFormPart={formPart}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
  {
    path: `${fortifiedHomeNCIUARedesignationRoutePrefix}/iteration-engine/:evaluationId`,
    label: "Iteration Engine",
    isAvailable: true,
    showInNavigation: false,
    showInSubNavigation: false,
    errorElement: errorWrapper,
    element: <HomePagesLayout redirectPath={`${fortifiedHomeRoutePrefix}`} />,
    children: [
      {
        path: "",
        label: "",
        isAvailable: true,
        showInNavigation: false,
        showInSubNavigation: false,
        element: (
          <WithParams<{
            evaluationId: string;
          }>>
            {({ evaluationId }) => {
              return (
                <HomeNCIUARedesignationIterationEnginePage
                  evaluationId={evaluationId}
                />
              );
            }}
          </WithParams>
        ),
        errorElement: errorWrapper,
      },
    ],
  },
] as utils.routes.UclRouteObject[];

export const fortified_Home_browserRouter = createBrowserRouter(
  uclRouteObject,
  {
    basename: process.env.REACT_APP_PATH_PREFIX,
  }
);
