import { PrimaryCard } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardEvaluationBasicInformationForm from "../BasicInformationForm/Form/HomeStandardEvaluationBasicInformationForm";
import HomeStandardEvaluationRooferConfirmationForm from "../RooferConfirmationForm/Form/HomeStandardEvaluationRooferConfirmationForm";
import HomeStandardEvaluationRoofingComplianceGeneralInformationForm from "../RoofingComplianceGeneralInformationForm/Form/HomeStandardEvaluationRoofingComplianceGeneralInformationForm";
import HomeStandardEvaluationRoofingComplianceRoofCoverForm from "../RoofingComplianceRoofCoverForm/Form/HomeStandardEvaluationRoofingComplianceRoofCoverForm";
import HomeStandardEvaluationHomeownerAuthorizationForm from "../HomeownerAuthorizationForm/Form/HomeStandardEvaluationHomeownerAuthorizationForm";
import HomeStandardEvaluationEngineerConfirmationForm from "../EngineerConfirmationForm/Form/HomeStandardEvaluationEngineerConfirmationForm";
import HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm from "../SilverGoldComplianceGeneralInformationForm/Form/HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm";
import HomeStandardEvaluationInstallingContractorConfirmationForm from "../InstallingContractorConfirmationForm/Form/HomeStandardEvaluationInstallingContractorConfirmationForm";
import HomeStandardEvaluationInstallerComplianceGablesForm from "../InstallerComplianceGablesForm/Form/HomeStandardEvaluationInstallerComplianceGablesForm";
import HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm from "../InstallerComplianceContinuousLoadPathForm/Form/HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm";
import HomeStandardEvaluationInstallerComplianceChimneyForm from "../InstallerComplianceChimneyForm/Form/HomeStandardEvaluationInstallerComplianceChimneyForm";
import HomeStandardEvaluationInstallerComplianceAttachedStructureForm from "../InstallerComplianceAttachedStructureForm/Form/HomeStandardEvaluationInstallerComplianceAttachedStructureForm";
import HomeStandardEvaluationEngineeringComplianceGeneralInformationForm from "../EngineeringComplianceGeneralInformationForm/Form/HomeStandardEvaluationEngineeringComplianceGeneralInformationForm";
import HomeStandardEvaluationEngineeringComplianceGablesForm from "../EngineeringComplianceGablesForm/Form/HomeStandardEvaluationEngineeringComplianceGablesForm";
import HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm from "../EngineeringComplianceContinuousLoadPathForm/Form/HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm";
import HomeStandardEvaluationEngineeringComplianceChimneyForm from "../EngineeringComplianceChimneyForm/Form/HomeStandardEvaluationEngineeringComplianceChimneyForm";
import HomeStandardEvaluationEngineeringComplianceAttachedStructureForm from "../EngineeringComplianceAttachedStructureForm/Form/HomeStandardEvaluationEngineeringComplianceAttachedStructureForm";
import HomeStandardEvaluationEligibilityForm from "../EligibilityForm/Form/HomeStandardEvaluationEligibilityForm";

interface HomeStandardIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeStandardIterationEngineForms: React.FC<
  HomeStandardIterationEngineFormsProps
> = (props) => {
  const [applicationFormInitiaized, setApplicationFormInitiaized] =
    useState<boolean>();

  const [
    rooferConfirmationFormInitiaized,
    setRooferConfirmationFormInitiaized,
  ] = useState<boolean>();

  const [
    roofingComplianceGeneralInformationForm,
    setRoofingComplianceGeneralInformatinFormInitialized,
  ] = useState<boolean>(false);

  const [
    roofingComplianceRoofCoverForm,
    setRoofingComplianceRoofCoverFormInitialized,
  ] = useState<boolean>(false);

  const [
    homeownerAuthorizationFormInitiaized,
    setHomeownerAuthorizationFormInitiaized,
  ] = useState<boolean>();

  const [
    engineerConfirmationFormInitiaized,
    setEngineerConfirmationFormInitiaized,
  ] = useState<boolean>();

  const [
    silverGoldComplianceGeneralInformationFormInitiaized,
    setSilverGoldComplianceGeneralInformationFormInitiaized,
  ] = useState<boolean>();

  const [
    installingContractorConfirmationFormInitiaized,
    setInstallingContractorConfirmationFormInitiaized,
  ] = useState<boolean>();

  const [
    installerComplianceGablesFormInitiaized,
    setInstallerComplianceGablesFormInitiaized,
  ] = useState<boolean>();

  const [
    installerComplianceContinuousLoadPathFormInitiaized,
    setInstallerComplianceContinuousLoadPathFormInitiaized,
  ] = useState<boolean>();

  const [
    installerComplianceChimneyFormInitiaized,
    setInstallerComplianceChimneyFormInitiaized,
  ] = useState<boolean>();

  const [
    installerComplianceAttachedStructureFormInitiaized,
    setInstallerComplianceAttachedStructureFormInitiaized,
  ] = useState<boolean>();

  const [
    engineeringComplianceGeneralInformationFormInitiaized,
    setEngineeringComplianceGeneralInformationFormInitiaized,
  ] = useState<boolean>();

  const [
    engineeringComplianceGablesFormInitiaized,
    setEngineeringComplianceGablesFormInitiaized,
  ] = useState<boolean>();

  const [
    engineeringComplianceContinuousLoadPathFormInitiaized,
    setEngineeringComplianceContinuousLoadPathFormInitiaized,
  ] = useState<boolean>();

  const [
    engineeringComplianceChimneyFormInitiaized,
    setEngineeringComplianceChimneyFormInitiaized,
  ] = useState<boolean>();

  const [
    engineeringComplianceAttachedStructureFormInitiaized,
    setEngineeringComplianceAttachedStructureFormInitiaized,
  ] = useState<boolean>();

  const [eligibilityFormInitiaized, setEligibilityFormInitiaized] =
    useState<boolean>();

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      applicationFormInitiaized ||
      roofingComplianceGeneralInformationForm ||
      roofingComplianceRoofCoverForm ||
      rooferConfirmationFormInitiaized ||
      homeownerAuthorizationFormInitiaized ||
      engineerConfirmationFormInitiaized ||
      silverGoldComplianceGeneralInformationFormInitiaized ||
      installingContractorConfirmationFormInitiaized ||
      installerComplianceGablesFormInitiaized ||
      installerComplianceContinuousLoadPathFormInitiaized ||
      installerComplianceChimneyFormInitiaized ||
      installerComplianceAttachedStructureFormInitiaized ||
      engineeringComplianceGeneralInformationFormInitiaized ||
      engineeringComplianceGablesFormInitiaized ||
      engineeringComplianceContinuousLoadPathFormInitiaized ||
      engineeringComplianceChimneyFormInitiaized ||
      engineeringComplianceAttachedStructureFormInitiaized ||
      eligibilityFormInitiaized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    applicationFormInitiaized,
    roofingComplianceGeneralInformationForm,
    roofingComplianceRoofCoverForm,
    rooferConfirmationFormInitiaized,
    homeownerAuthorizationFormInitiaized,
    engineerConfirmationFormInitiaized,
    silverGoldComplianceGeneralInformationFormInitiaized,
    installingContractorConfirmationFormInitiaized,
    installerComplianceGablesFormInitiaized,
    installerComplianceContinuousLoadPathFormInitiaized,
    installerComplianceChimneyFormInitiaized,
    installerComplianceAttachedStructureFormInitiaized,
    engineeringComplianceGeneralInformationFormInitiaized,
    engineeringComplianceGablesFormInitiaized,
    engineeringComplianceContinuousLoadPathFormInitiaized,
    engineeringComplianceChimneyFormInitiaized,
    engineeringComplianceAttachedStructureFormInitiaized,
  ]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case "EligibilityForm":
        return {
          headerContent: "Eligibility Form",
          form: (
            <HomeStandardEvaluationEligibilityForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEligibilityFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RoofingComplianceGeneralInformationForm":
        return {
          headerContent: "Roofing Compliance General Information Form",
          form: (
            <HomeStandardEvaluationRoofingComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceGeneralInformatinFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RoofingComplianceRoofCoverForm":
        return {
          headerContent: "Roofing Compliance Roof Cover Form",
          form: (
            <HomeStandardEvaluationRoofingComplianceRoofCoverForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRoofingComplianceRoofCoverFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "RooferConfirmationForm":
        return {
          headerContent: "Roofer Confirmation Form",
          form: (
            <HomeStandardEvaluationRooferConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setRooferConfirmationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "HomeownerAuthorizationForm":
        return {
          headerContent: "Homeowner Authorization Form",
          form: (
            <HomeStandardEvaluationHomeownerAuthorizationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setHomeownerAuthorizationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineerConfirmationForm":
        return {
          headerContent: "Engineer Confirmation Form",
          form: (
            <HomeStandardEvaluationEngineerConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineerConfirmationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "SilverGoldComplianceGeneralInformationForm":
        return {
          headerContent: "Silver/Gold Compliance General Information Form",
          form: (
            <HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setSilverGoldComplianceGeneralInformationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallingContractorConfirmationForm":
        return {
          headerContent: "Installing Contractor Confirmation Form",
          form: (
            <HomeStandardEvaluationInstallingContractorConfirmationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallingContractorConfirmationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceGablesForm":
        return {
          headerContent: "Installer Compliance Gables Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceGablesForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceGablesFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceContinuousLoadPathForm":
        return {
          headerContent: "Installer Compliance Continuous Load Path Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceContinuousLoadPathForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceContinuousLoadPathFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceChimneyForm":
        return {
          headerContent: "Installer Compliance Chimney Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceChimneyForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceChimneyFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "InstallerComplianceAttachedStructureForm":
        return {
          headerContent: "Installer Compliance Attached Structure Form",
          form: (
            <HomeStandardEvaluationInstallerComplianceAttachedStructureForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setInstallerComplianceAttachedStructureFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceGeneralInformationForm":
        return {
          headerContent: "Engineering Compliance General Information Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceGeneralInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceGeneralInformationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceGablesForm":
        return {
          headerContent: "Engineering Compliance Gables Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceGablesForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceGablesFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceContinuousLoadPathForm":
        return {
          headerContent: "Engineering Compliance Continuous Load Path Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceContinuousLoadPathFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceChimneyForm":
        return {
          headerContent: "Engineering Compliance Chimney Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceChimneyForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceChimneyFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case "EngineeringComplianceAttachedStructureForm":
        return {
          headerContent: "Engineering Compliance Attached Structure Form",
          form: (
            <HomeStandardEvaluationEngineeringComplianceAttachedStructureForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEngineeringComplianceAttachedStructureFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Home Standard Basic Information Form",
          form: (
            <HomeStandardEvaluationBasicInformationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setApplicationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeStandardIterationEngineForms;
