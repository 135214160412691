import {
  EnumMultiSelectFieldValue,
  SingleCheckboxFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationEngineeringComplianceGablesFormParts {
  EngineeringComplianceGables = "engineering-compliance-gables",
}

export interface BaseHomeEvaluationEngineeringComplianceGablesForm
  extends IEngineeringComplianceGables {}

interface IEngineeringComplianceGables {
  HowAreGableEndWallsFramed?: EnumMultiSelectFieldValue;
  Attestation207?: SingleCheckboxFieldValue;
  Attestation208?: SingleCheckboxFieldValue;
  Attestation209?: SingleCheckboxFieldValue;
  Attestation210?: SingleCheckboxFieldValue;
  GableConnectedBraceTopType?: EnumMultiSelectFieldValue;
  Attestation212?: SingleCheckboxFieldValue;
  Attestation213?: SingleCheckboxFieldValue;
  Attestation214?: SingleCheckboxFieldValue;
  GableConnectedBraceBottomType?: EnumMultiSelectFieldValue;
  Attestation216?: SingleCheckboxFieldValue;
  GableStudsBracedType?: EnumMultiSelectFieldValue;
  Attestation218?: SingleCheckboxFieldValue;
}
