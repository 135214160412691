import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationForm";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { homeNCIUAEvaluationAPIClient } from "../../../../evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";
import { homeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient } from "../../../../evaluation/lib/apiClients/homeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient";
import { fortifiedHomeNCIUARoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormProps } from "./HomeNCIUAEvaluationRoofingComplianceGeneralInformationForm";

function useHomeNCIUAEvaluationRoofingComplianceGeneralInformationForm(
  props: HomeNCIUAEvaluationRoofingComplianceGeneralInformationFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeNCIUAEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
    )[0];

  const isLastPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
    ).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeNCIUAEvaluationAPIClient
      .getHomeNCIUAEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeNCIUAEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeNCIUAIterationEngineRoofingComplianceGeneralInformationForm;
    }
    switch (props.formPart) {
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.BasicInformation:
        return builders?.HomeNCIUARoofingComplianceBasicInformationForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PermitInformation:
        return builders?.HomeNCIUAPermitInformationForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SiteConditions:
        return builders?.HomeNCIUASiteConditionsForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofStructure:
        return builders?.HomeNCIUARoofStructureForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.RoofSheathing:
        return builders?.HomeNCIUARoofSheathingForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SealedRoofDeck:
        return builders?.HomeNCIUASealedRoofDeckForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.DripEdge:
        return builders?.HomeNCIUADripEdgeForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.AtticVents:
        return builders?.HomeNCIUAAtticVentsForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.PhotoVoltaicPanels:
        return builders?.HomeNCIUAPhotoVoltaicPanelsForm;
      case BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts.SkyLights:
        return builders?.HomeNCIUASkyLightsForm;
      default:
        return builders?.HomeNCIUARoofingComplianceGeneralInformationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeNCIUAEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);
    await (shouldSubmit
      ? homeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient.submitHomeNCIUARoofingComplianceGeneralInformationForm(
          form.id,
          form.homeNCIUAEvaluation.roofingComplianceGeneralInformationForm,
          props.formPart
        )
      : homeNCIUAEvaluationRoofingComplianceGeneralInformationAPIClient.updateHomeNCIUARoofingComplianceGeneralInformationForm(
          form.id,
          form.homeNCIUAEvaluation.roofingComplianceGeneralInformationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);

      if (isLastPartOfForm) {
      } else {
        // Navigate to next part of form
        const nextFormPart = Object.values(
          BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
        )[
          Object.values(
            BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts
          ).indexOf(props.formPart!) + 1
        ].valueOf();

        navigate(
          `${fortifiedHomeNCIUARoutePrefix}/${props.evaluationId}/roofing-compliance-general-information/${nextFormPart}`
        );
      }

      // }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeNCIUAEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        areAllRequiredFieldsComplete,
        basicInformationRequiredFieldCount,
        roofStructureRequiredFieldCount,
        roofSheathingRequiredFieldCount,
        dripEdgeRequiredFieldCount,
        photoVoltaicPanelsRequiredFieldCount,
        skyLightsRequiredFieldCount,
        permitInformationRequiredFieldCount,
        siteConditionsRequiredFieldCount,
        sealedRoofDeckRequiredFieldCount,
        atticVentsRequiredFieldCount,
        basicInformationHasBeenSubmitted,
        roofStructureHasBeenSubmitted,
        roofSheathingHasBeenSubmitted,
        dripEdgeHasBeenSubmitted,
        photoVoltaicPanelsHasBeenSubmitted,
        skyLightsHasBeenSubmitted,
        permitInformationHasBeenSubmitted,
        siteConditionsHasBeenSubmitted,
        sealedRoofDeckHasBeenSubmitted,
        atticVentsHasBeenSubmitted,
        isBasicInformationComplete,
        isRoofStructureComplete,
        isRoofSheathingComplete,
        isDripEdgeComplete,
        isPhotoVoltaicPanelsComplete,
        isSkyLightsComplete,
        isPermitInformationComplete,
        isSiteConditionsComplete,
        isSealedRoofDeckComplete,
        isAtticVentsComplete,
        roofInstallationDate,
        distanceToSaltWaterShoreline,
        numberOfDifferentRoofCoverTypes,
        wasPermitIssued,
        permitNumber,
        permitDate,
        permitIssuedBy,
        dateOfFinalBldgDeptInspection,
        buildingCodeAndEdition,
        asce7Edition,
        ultimateSiteDesignWindSpeed,
        siteExposureCategory,
        meanRoofHeight,
        maxRoofStructuralMemberSpacing,
        maximumRoofStructuralMemberSpacingPhoto,
        roofSheathingType,
        roofSheathingTypeWhenOther,
        minimumRoofSheathingThickness,
        minimumRoofSheathingThicknessPhotos,
        selectedStandardDetailsThatMatchesInstalledSheathingAttachment,
        roofSheathingFastenerTypePhoto,
        roofSheathingAttachmentPhoto,
        doesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements,
        roofDeckAttachmentEngineeringAnalysisRequiredDocumentation,
        isEntireRoofLowSloped,
        selectedStandardDetailsThatMatchesSealedRoofDeck,
        doesSealedRoofDeckNotMatchFortifiedPrescribedRequirements,
        sealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos,
        sealedRoofDeck2PlyInstallationPhotos,
        sealedRoofDeckSelfAdheredMembraneInstallationPhotos,
        sealedRoofDeckSprayFoamInstallationPhotos,
        sealedRoofDeckTapedSeamsWithTileInstallationPhotos,
        sealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos,
        sealedRoofDeckUnderlaymentLabelPhotos,
        sealedRoofDeckNoneOfTheAbovePhotos,
        sealedRoofDeckClosedCellFoamDocumentation,
        fullyInstalledRoofCoverPhotos,
        areInstalledDripEdgeNewAndCodeCompliantMaterials,
        standardDetailThatMatchesDripEdgeInstallation,
        newDripEdgeInstallationPhotos,
        wasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications,
        isDripEdgeInstalled,
        existingDripEdgeInstallationPhotos,
        areRoofMountedVentsOrGableEndsPresent,
        roofVentilation,
        doAllRoofMountedVentsMeetTAS100A,
        roofVentilationPhoto,
        areAllRoofMountedVentsInstalledPerManufacturerRequirements,
        doAllGableEndVentsHaveARemovablePerFortified2020HomeStandard,
        gableEndsVentsPhoto,
        areRoofMountedPhotoVoltaicPanelsPresent,
        werePVPanelsInstalledAndAnchoredPerAnchorageDetails,
        pvPanelImpactRating,
        pvAnchorageDocumentation,
        pvHailRatingDocumentation,
        areSkylightsPresent,
        skylightExistsAndRatingsAndInstallationAreUnknown,
        wereSkylightsInstalledPerManufacturerInstructions,
        skylightImpactRating,
        skylightHailRatingPhotos,
        skylightHailRatingDocumentation,
      } = response.homeNCIUAEvaluation.roofingComplianceGeneralInformationForm;

      const { canProceedToApplicationFee } = response.homeNCIUAEvaluation;

      return {
        ...prevState,
        homeNCIUAEvaluation: {
          ...prevState.homeNCIUAEvaluation,
          canProceedToApplicationFee,
          roofingComplianceGeneralInformationForm: {
            ...prevState.homeNCIUAEvaluation
              .roofingComplianceGeneralInformationForm,
            areAllRequiredFieldsComplete,
            basicInformationRequiredFieldCount,
            roofStructureRequiredFieldCount,
            roofSheathingRequiredFieldCount,
            dripEdgeRequiredFieldCount,
            photoVoltaicPanelsRequiredFieldCount,
            skyLightsRequiredFieldCount,
            permitInformationRequiredFieldCount,
            siteConditionsRequiredFieldCount,
            sealedRoofDeckRequiredFieldCount,
            atticVentsRequiredFieldCount,
            basicInformationHasBeenSubmitted,
            roofStructureHasBeenSubmitted,
            roofSheathingHasBeenSubmitted,
            dripEdgeHasBeenSubmitted,
            photoVoltaicPanelsHasBeenSubmitted,
            skyLightsHasBeenSubmitted,
            permitInformationHasBeenSubmitted,
            siteConditionsHasBeenSubmitted,
            sealedRoofDeckHasBeenSubmitted,
            atticVentsHasBeenSubmitted,
            isBasicInformationComplete,
            isRoofStructureComplete,
            isRoofSheathingComplete,
            isDripEdgeComplete,
            isPhotoVoltaicPanelsComplete,
            isSkyLightsComplete,
            isPermitInformationComplete,
            isSiteConditionsComplete,
            isSealedRoofDeckComplete,
            isAtticVentsComplete,
            roofInstallationDate,
            distanceToSaltWaterShoreline,
            numberOfDifferentRoofCoverTypes,
            wasPermitIssued,
            permitNumber,
            permitDate,
            permitIssuedBy,
            dateOfFinalBldgDeptInspection,
            buildingCodeAndEdition,
            asce7Edition,
            ultimateSiteDesignWindSpeed,
            siteExposureCategory,
            meanRoofHeight,
            maxRoofStructuralMemberSpacing,
            maximumRoofStructuralMemberSpacingPhoto,
            roofSheathingType,
            roofSheathingTypeWhenOther,
            minimumRoofSheathingThickness,
            minimumRoofSheathingThicknessPhotos,
            selectedStandardDetailsThatMatchesInstalledSheathingAttachment,
            roofSheathingFastenerTypePhoto,
            roofSheathingAttachmentPhoto,
            doesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements,
            roofDeckAttachmentEngineeringAnalysisRequiredDocumentation,
            isEntireRoofLowSloped,
            selectedStandardDetailsThatMatchesSealedRoofDeck,
            doesSealedRoofDeckNotMatchFortifiedPrescribedRequirements,
            sealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos,
            sealedRoofDeck2PlyInstallationPhotos,
            sealedRoofDeckSelfAdheredMembraneInstallationPhotos,
            sealedRoofDeckSprayFoamInstallationPhotos,
            sealedRoofDeckTapedSeamsWithTileInstallationPhotos,
            sealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos,
            sealedRoofDeckUnderlaymentLabelPhotos,
            sealedRoofDeckNoneOfTheAbovePhotos,
            sealedRoofDeckClosedCellFoamDocumentation,
            fullyInstalledRoofCoverPhotos,
            areInstalledDripEdgeNewAndCodeCompliantMaterials,
            standardDetailThatMatchesDripEdgeInstallation,
            newDripEdgeInstallationPhotos,
            wasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications,
            isDripEdgeInstalled,
            existingDripEdgeInstallationPhotos,
            areRoofMountedVentsOrGableEndsPresent,
            roofVentilation,
            doAllRoofMountedVentsMeetTAS100A,
            roofVentilationPhoto,
            areAllRoofMountedVentsInstalledPerManufacturerRequirements,
            doAllGableEndVentsHaveARemovablePerFortified2020HomeStandard,
            gableEndsVentsPhoto,
            areRoofMountedPhotoVoltaicPanelsPresent,
            werePVPanelsInstalledAndAnchoredPerAnchorageDetails,
            pvPanelImpactRating,
            pvAnchorageDocumentation,
            pvHailRatingDocumentation,
            areSkylightsPresent,
            skylightExistsAndRatingsAndInstallationAreUnknown,
            wereSkylightsInstalledPerManufacturerInstructions,
            skylightImpactRating,
            skylightHailRatingPhotos,
            skylightHailRatingDocumentation,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeNCIUAEvaluationRoofingComplianceGeneralInformationForm;
