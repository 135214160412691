/* eslint-disable react/display-name */

import { Button, Intent } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import "./styles.scss";
import { forwardRef, useState } from "react";
import { ComplexMultifamilyEvaluationBuildingFormV2 } from "./ComplexMultifamilyEvaluationBuildingFormV2";
import {
  FieldFormV2Module,
  SecondaryCard,
  SecondaryCardHeader,
} from "@ucl/library";
import { evaluationApiClient } from "../../../../../foritfied/lib/apiClients/evaluation/evaluationApiClient";
import { useMultifamilyEvaluation } from "../../customHooks/useMultifamilyEvaluation";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";

export interface ComplexMultifamilyEvaluationBuildingCardV2Props {
  id: string;
  buildingCount: number;
  isSaving: boolean;
  disabled?: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  isIterationEnginePage?: boolean;
  hideFormControls?: boolean;
  onInitialized?: () => void;
  onFormSave?: ((fieldKey: string, value: any) => Promise<void>) | undefined;
  getBuildingIds: () => Promise<void>;
  updateChildFormError: (formId: string, hasError: boolean) => void;
  updateChildRequiredFieldCount?: (childId: string, count: number) => void;
  isDeleteButtonDisabled: boolean;
}

const ComplexMultifamilyEvaluationBuildingCardV2 = forwardRef<
  FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>,
  ComplexMultifamilyEvaluationBuildingCardV2Props
>((props, ref) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteBuilding = async () => {
    setIsDeleting(true);
    await evaluationApiClient
      .deleteEvaluation(props.id)
      .then(async () => {
        await props.getBuildingIds();
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const { multifamilyEvaluation } = useMultifamilyEvaluation(props.id);

  return (
    <>
      {multifamilyEvaluation && (
        <SecondaryCard
          id={`complex-building-form-${props.id}`}
          key={props.id}
          isCollapsible
          headerContent={
            <SecondaryCardHeader label={`Building ${props.buildingCount}`}>
              <h4 className="bp4-heading status-heading">{`Status: ${multifamilyEvaluation.status_AsString}`}</h4>
              <Button
                intent={Intent.DANGER}
                icon={IconNames.Trash}
                loading={isDeleting}
                disabled={props.disabled || props.isDeleteButtonDisabled}
                minimal
                large
                onClick={async () => {
                  await deleteBuilding();
                }}
              />
            </SecondaryCardHeader>
          }
        >
          <ComplexMultifamilyEvaluationBuildingFormV2
            complexCommercialEvaluationBuildingId={props.id}
            ref={ref}
            isDeleting={isDeleting}
            isSaving={props.isSaving}
            setHasUnsavedChanges={props.setHasUnsavedChanges}
            isIterationEnginePage={props.isIterationEnginePage}
            hideFormControls={props.hideFormControls}
            onInitialized={props.onInitialized}
            onFormSave={props.onFormSave}
            disabled={props.disabled}
            updateChildFormError={props.updateChildFormError}
            updateChildRequiredFieldCount={props.updateChildRequiredFieldCount}
          />
        </SecondaryCard>
      )}
    </>
  );
});

export default ComplexMultifamilyEvaluationBuildingCardV2;

(
  ComplexMultifamilyEvaluationBuildingCardV2 as React.ForwardRefExoticComponent<ComplexMultifamilyEvaluationBuildingCardV2Props>
).displayName = "ComplexMultifamilyEvaluationBuildingCardV2";
