import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts } from "../../common/types/BaseHomeEvaluationEngineeringComplianceAttachedStructureForm";
import HomeStandardEngineeringComplianceAttachedStructureFormContainer from "../components/Forms/EngineeringComplianceAttachedStructureForm/Container/HomeStandardEngineeringComplianceAttachedStructureFormContainer";

export interface HomeStandardEngineeringComplianceAttachedStructurePageProps {
  evaluationId?: string;
  engineeringComplianceAttachedStructureFormPart?: BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts;
}

const HomeStandardEngineeringComplianceAttachedStructurePageComponent: FC<
  HomeStandardEngineeringComplianceAttachedStructurePageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardEngineeringComplianceAttachedStructureFormContainer
            evaluationId={props.evaluationId}
            formParts={props.engineeringComplianceAttachedStructureFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardEngineeringComplianceAttachedStructurePage =
  withAITracking(
    reactPlugin,
    HomeStandardEngineeringComplianceAttachedStructurePageComponent,
    "HomeStandardEngineeringComplianceAttachedStructurePage",
    "tracking-container"
  );
