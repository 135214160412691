import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts } from "../../../common/types/BaseHomeEvaluationEngineeringComplianceGeneralInformationForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel } from "../../types/HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel";

class HomeStandardEvaluationEngineeringComplianceGeneralInformationAPIClient {
  updateHomeStandardEngineeringComplianceGeneralInformationForm = async (
    evaluationId: string,
    engineeringComplianceGeneralInformationForm: HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-general-information-form/${evaluationId}`,
      {
        engineeringComplianceGeneralInformationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardEngineeringComplianceGeneralInformationForm = async (
    evaluationId: string,
    engineeringComplianceGeneralInformationForm: HomeStandardEvaluationEngineeringComplianceGeneralInformationFormModel,
    formPart?: BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceGeneralInformationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-general-information-form/${evaluationId}/${formPartValue}`,
      engineeringComplianceGeneralInformationForm
    );
  };
}

export const homeStandardEvaluationEngineeringComplianceGeneralInformationAPIClient =
  new HomeStandardEvaluationEngineeringComplianceGeneralInformationAPIClient();
