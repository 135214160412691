import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFComplianceLetterParts } from "../../../common/types/Evaluation/BaseFCFMFComplianceLetter";
import { CommercialRedesignationComplianceLetterFormModel } from "../../types/CommercialRedesignationComplianceLetterFormModel";
import { Evaluation_CommercialRedesignationFormModel } from "../../types/CommercialRedesignationFormModel";

class CommercialRedesignationComplianceLetterAPIClient {
  updateCommercialComplianceLetter = async (
    evaluationId: string,
    complianceLetter: CommercialRedesignationComplianceLetterFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-compliance-letter/${evaluationId}`,
      {
        complianceLetter,
        fieldKey,
      }
    );
  };

  submitCommercialComplianceLetter = async (
    wildfireApplicationId: string,
    complianceLetter: CommercialRedesignationComplianceLetterFormModel,
    complianceLetterPart?: FCFMFComplianceLetterParts
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    const complianceLetterPartValue = (
      complianceLetterPart ??
      (Object.values(
        FCFMFComplianceLetterParts
      ).pop() as FCFMFComplianceLetterParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-compliance-letter/${wildfireApplicationId}/${complianceLetterPartValue}`,
      complianceLetter
    );
  };
}

export const commercialRedesignationComplianceLetterAPIClient =
  new CommercialRedesignationComplianceLetterAPIClient();
