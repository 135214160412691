import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "InstallingContractorConfirmationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const installingContractorConfirmationFields = () => [
  buildFieldKey("CertifiedLicensedBuildingContractorClaim"),
  buildFieldKey("UnderstandFalseInformationSubjectToCriminalPenaltiesClaim"),
  buildFieldKey("PrintedName"),
  buildFieldKey("Company"),
  buildFieldKey("PhoneNumber"),
  buildFieldKey("LicenseNumber"),
];

const installingContractorConfirmationAddressDetailsFields = () => [
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,
];

const installingContractorConfirmationFieldsFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("SignedInstallerComplianceForms"),
    defaultToUpload: false,
    errorMessages: get(errors, "SignedInstallerComplianceForms.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const engineerConfirmationRequiredFields = [
  buildFieldKey("CertifiedLicensedBuildingContractorClaim"),
  buildFieldKey("UnderstandFalseInformationSubjectToCriminalPenaltiesClaim"),
  buildFieldKey("PrintedName"),
  buildFieldKey("Company"),
  buildFieldKey("PhoneNumber"),
  buildFieldKey("Address"),
  buildFieldKey("LicenseNumber"),
  buildFieldKey("SignedInstallerComplianceForms"),
];

export const installingContractorConfirmationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installingContractorConfirmationFields(),
          ...installingContractorConfirmationAddressDetailsFields(),
          ...installingContractorConfirmationFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        engineerConfirmationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const installingContractorConfirmationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installingContractorConfirmationFields(),
          ...installingContractorConfirmationAddressDetailsFields(),
          ...installingContractorConfirmationFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        engineerConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const installingContractorConfirmationFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installingContractorConfirmationFields(),
          ...installingContractorConfirmationAddressDetailsFields(),
          ...installingContractorConfirmationFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        engineerConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
