import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceGeneralInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const engineeringComplianceGeneralInformationFields = () => [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("ASCE7Edition"),
  buildFieldKey("DesignWindSpeed"),
  buildFieldKey("ExposureCategory"),
  buildFieldKey("Zone1CAndCRoofPressuresPositive"),
  buildFieldKey("Zone1CAndCRoofPressuresNegative"),
  buildFieldKey("Zone2CAndCRoofPressuresPositive"),
  buildFieldKey("Zone2CAndCRoofPressuresNegative"),
  buildFieldKey("Zone3CAndCRoofPressuresPositive"),
  buildFieldKey("Zone3CAndCRoofPressuresNegative"),
  buildFieldKey(
    "DidTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5"
  ),
  buildFieldKey("Zone4CAndCWallWindPressuresPositive"),
  buildFieldKey("Zone4CAndCWallWindPressuresNegative"),
  buildFieldKey("Zone5CAndCWallWindPressuresPositive"),
  buildFieldKey("Zone5CAndCWallWindPressuresNegative"),
];

const engineeringComplianceGeneralInformationFieldsFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("CAndCRoofDesignUpliftPressuresDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "CAndCRoofDesignUpliftPressuresDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("CAndCWallDesignWindPressuresDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "CAndCWallDesignWindPressuresDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const engineeringComplianceGeneralInformationRequiredFields = [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("ASCE7Edition"),
  buildFieldKey("DesignWindSpeed"),
  buildFieldKey("ShowWarning_DesignWindSpeedGreaterThan200"),
  buildFieldKey("ShowWarning_DesignWindSpeedBelow115"),
  buildFieldKey("ExposureCategory"),
  buildFieldKey("Zone1CAndCRoofPressuresPositive"),
  buildFieldKey("Zone1CAndCRoofPressuresNegative"),
  buildFieldKey("Zone2CAndCRoofPressuresPositive"),
  buildFieldKey("Zone2CAndCRoofPressuresNegative"),
  buildFieldKey("Zone3CAndCRoofPressuresPositive"),
  buildFieldKey("Zone3CAndCRoofPressuresNegative"),
  buildFieldKey(
    "DidTheEngineerSpecifyTheRequiredWallWindPressuresForZone4And5"
  ),
  buildFieldKey("Zone4CAndCWallWindPressuresPositive"),
  buildFieldKey("Zone4CAndCWallWindPressuresNegative"),
  buildFieldKey("Zone5CAndCWallWindPressuresPositive"),
  buildFieldKey("Zone5CAndCWallWindPressuresNegative"),
  buildFieldKey("CAndCRoofDesignUpliftPressuresDocumentation"),
  buildFieldKey("CAndCWallDesignWindPressuresDocumentation"),
];

// Full form builder with all parts included
export const engineeringComplianceGeneralInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...engineeringComplianceGeneralInformationFields(),
          ...engineeringComplianceGeneralInformationFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        engineeringComplianceGeneralInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const engineeringComplianceGeneralInformationFormIterationEngineBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...engineeringComplianceGeneralInformationFields(),
            ...engineeringComplianceGeneralInformationFieldsFileUploaderFields(
              form,
              errors
            ),
          ],
          onValueChange,
          form,
          errors,
          engineeringComplianceGeneralInformationRequiredFields,
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
