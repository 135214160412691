import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../../evaluation/types/HomeNCIUARedesignationFormModel_Model";
import { BaseHomeEvaluationRedesignationFormParts } from "../../../../../common/types/BaseHomeEvaluationRedesignationForm";
import { HomeNCIUARedesignationFormContainerProps } from "./HomeNCUIARedesignationFormContainer";

function useHomeNCIUARedesignationFormContainer(
  props: HomeNCIUARedesignationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.redesignationFormPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationRedesignationFormParts | undefined
  >(props.redesignationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeNCIUARedesignationFormModel>();

  const redesignationForm =
    evaluationFormModel?.homeNCIUARedesignation.redesignationForm;

  const getNextUnsubmittedFormPart = () => {
    if (!redesignationForm) {
      return null;
    }

    if (!redesignationForm.isRedesignationFormComplete) {
      return BaseHomeEvaluationRedesignationFormParts.RedesignationForm;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): BaseHomeEvaluationRedesignationFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(
      BaseHomeEvaluationRedesignationFormParts
    )[0];

    // If the application form part is provided (by URL), use it
    if (!!props.redesignationFormPart) {
      return props.redesignationFormPart;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationRedesignationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationRedesignationFormParts.RedesignationForm:
        return redesignationForm?.isRedesignationFormComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationRedesignationFormParts>[] =
    [
      {
        formPart: BaseHomeEvaluationRedesignationFormParts.RedesignationForm,
        displayName: "Redesignation Form",
        requiredFieldCount: redesignationForm?.RedesignationFormRequiredCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart: formPart,
    getBestFormPart,
    commercialSchemaFactory,
  };
}

export default useHomeNCIUARedesignationFormContainer;
