import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { CommercialRedesignationFieldInspectionFormModel } from "../../types/CommercialRedesignationFieldInspectionFormModel";
import { Evaluation_CommercialRedesignationFormModel } from "../../types/CommercialRedesignationFormModel";

class CommercialRedesignationFieldInspectionFormAPIClient {
  updateCommercialEvaluationFieldInspectionForm = async (
    evaluationId: string,
    fieldInspectionForm: CommercialRedesignationFieldInspectionFormModel,
    fieldKey?: string
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-field-inspection-form/${evaluationId}`,
      {
        fieldInspectionForm,
        fieldKey,
      }
    );
  };

  submitCommercialEvaluationFieldInspectionForm = async (
    evaluationId: string,
    fieldInspectionForm: CommercialRedesignationFieldInspectionFormModel
  ): Promise<Evaluation_CommercialRedesignationFormModel> => {
    return baseApiClient.post<Evaluation_CommercialRedesignationFormModel>(
      `/commercial/redesignation-field-inspection-form/${evaluationId}`,
      fieldInspectionForm
    );
  };
}

export const commercialRedesignationFieldInspectionFormAPIClient =
  new CommercialRedesignationFieldInspectionFormAPIClient();
