import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel } from "../../types/HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel";

class HomeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient {
  updateHomeStandardEngineeringComplianceContinuousLoadPathForm = async (
    evaluationId: string,
    engineeringComplianceContinuousLoadPathForm: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-continuous-load-path-form/${evaluationId}`,
      {
        engineeringComplianceContinuousLoadPathForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardEngineeringComplianceContinuousLoadPathForm = async (
    evaluationId: string,
    engineeringComplianceContinuousLoadPathForm: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormModel,
    formPart?: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
      ).pop() as BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineering-compliance-continuous-load-path-form/${evaluationId}/${formPartValue}`,
      engineeringComplianceContinuousLoadPathForm
    );
  };
}

export const homeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient =
  new HomeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient();
