import {
  EnumSingleSelectFieldProps,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../types/MultifamilyEvaluationFormModel";
import { MultifamilyFormNameTypes } from "../../../types/MultifamilyEvaluation";

const modelName = "Evaluation";
const subModelName = "MultifamilyEvaluation";
const formName = MultifamilyFormNameTypes.EvaluatorAssignmentForm.fieldKey;

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Evaluation Evaluator Assignment
const evaluationCompanyAssignmentFields = () => [
  {
    fieldKey: buildFieldKey("EvaluatorCompany"),
    placeholder: "Please select an option",
    hidden: false,
  } as EnumSingleSelectFieldProps,
];

const evaluationCompanyAssignmentRequiredFields = [
  buildFieldKey("EvaluatorCompany"),
];

// Full form builder with all parts included
export const multifamilyEvaluationCompanyAssignmentFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_MultifamilyEvaluationFormModel>(
        modelName,
        [...evaluationCompanyAssignmentFields()],
        onValueChange,
        form,
        errors,
        [...evaluationCompanyAssignmentRequiredFields]
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const multifamilyIterationEngineEvaluationCompanyAssignmentFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_MultifamilyEvaluationFormModel>(
          modelName,
          [...evaluationCompanyAssignmentFields()],
          onValueChange,
          form,
          errors,
          [...evaluationCompanyAssignmentRequiredFields]
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>;
  };
