import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { Evaluation_HomeNCIUAEvaluation } from "../evaluation/types/HomeNCIUAEvaluation";
import { homeNCIUAEvaluationAPIClient } from "../evaluation/lib/apiClients/homeNCIUAEvaluationAPIClient";

export const useHomeNCIUAEvaluation = (evaluationId: string) => {
  const [homeNCIUAEvaluation, setHomeNCIUAEvaluation] =
    useState<Evaluation_HomeNCIUAEvaluation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getNCIUAEvaluation(evaluationId);
  }, [evaluationId]);

  const getNCIUAEvaluation = async (evaluationId: string) => {
    await homeNCIUAEvaluationAPIClient
      .getHomeNCIUAEvaluation(evaluationId)
      .then((response) => {
        setHomeNCIUAEvaluation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshHomeNCIUAEvaluation = async () => {
    setIsApplicationLoading(true);
    if (homeNCIUAEvaluation?.id) {
      await homeNCIUAEvaluationAPIClient
        .getHomeNCIUAEvaluation(homeNCIUAEvaluation?.id)
        .then((response) => {
          setHomeNCIUAEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    homeNCIUAEvaluation,
    refreshHomeNCIUAEvaluation,
    isApplicationLoading,
  };
};
