import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardRoofingComplianceGeneralFormContainer from "../components/Forms/RoofingComplianceGeneralInformationForm/Container/HomeStandardRoofingComplianceGeneralFormContainer";
import { BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts } from "../../common/types/BaseHomeEvaluationRoofingComplianceGeneralInformationForm";

export interface HomeStandardRoofingComplianceGeneralInformationPageProps {
  evaluationId?: string;
  formParts?: BaseHomeEvaluationRoofingComplianceGeneralInformationFormParts;
}

const HomeStandardRoofingComplianceGeneralInformationPageComponent: FC<
  HomeStandardRoofingComplianceGeneralInformationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRoofingComplianceGeneralFormContainer
            evaluationId={props.evaluationId}
            formParts={props.formParts}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRoofingComplianceGeneralInformationPage =
  withAITracking(
    reactPlugin,
    HomeStandardRoofingComplianceGeneralInformationPageComponent,
    "HomeStandardRoofingComplianceGeneralInformationPage",
    "tracking-container"
  );
