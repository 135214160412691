import "./styles.scss";
import { EvaluationCommentV2 } from "../types/EvaluationCommentV2";
import { convertDateTo_EST_timeZone_AsString } from "../../../../common/utils/momentTimeZone";
import { Button } from "@blueprintjs/core";
import { useState } from "react";
import { wildfireUserStore } from "../../../stores/WildfireUserStore";
import { RichTextEditorField, RichTextEditorValueType } from "@ucl/library";
import { parseUntrustedHtml } from "../../../../common/utils/markup";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";

export interface ApplicantIterationEngineCommentPanelProps {
  comments: EvaluationCommentV2[];
  unsavedComments: EvaluationCommentV2[];
  setUnsavedComments: (comments: EvaluationCommentV2[]) => void;
  isDisabled?: boolean;
}

const ApplicantIterationEngineCommentPanel: React.FC<
  ApplicantIterationEngineCommentPanelProps
> = (props) => {
  const [inputComment, setInputComment] = useState<string>("");

  const sortedComments = [
    ...props.unsavedComments.map((comment) => ({
      ...comment,
      createdAt: convertDateTo_EST_timeZone_AsString(comment.createdAt),
    })),
    ...props.comments.map((comment) => ({
      ...comment,
      createdAt: convertDateTo_EST_timeZone_AsString(comment.createdAt),
    })),
  ].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  const addComment = async () => {
    props.setUnsavedComments([
      ...props.unsavedComments,
      {
        comment: inputComment,
        createdBy_AsName: wildfireUserStore.fullUserName,
        role_AsString: wildfireUserStore.displayRole,
        createdAt: new Date().toISOString(),
      } as EvaluationCommentV2,
    ]);
    setInputComment("");
  };

  return (
    <>
      <div className="comment-list-scroll-container">
        <div className="comment-list-view">
          <div className="comment-list-view-title">Comments</div>
          {sortedComments.map((comment) => (
            <div key={comment.id} className="comment-list-view_item">
              <div className="comment-list-view_item_line-1">
                <div className="comment-list-view_item_line-1_name">
                  {comment.createdBy_AsName}
                  {comment.role_AsString && ` (${comment.role_AsString})`}
                </div>
              </div>
              <div className="comment-list-view_item_line-1_time">
                {comment.createdAt}
              </div>
              <div className="comment-list-view_item_line-2">
                {parseUntrustedHtml(comment.comment)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="comment-input">
        <RichTextEditorField
          className="comment-textarea"
          valueType={RichTextEditorValueType.html}
          value={inputComment}
          onSubmit={(value) => {
            setInputComment(value ?? "");
          }}
          disabled={props.isDisabled}
          richTextEditorSettings={{
            height: "200px",
            placeholder: "Add a new comment...",
            enableHtmlSanitizer: true,
            autoSaveOnIdle: true,
            saveInterval: 50,
            toolbarSettings: {
              items: [
                "OrderedList",
                "UnorderedList",
                "Bold",
                "Underline",
                "Italic",
                "StrikeThrough",
                "Formats",
                "ClearFormat",
              ],
            } as ToolbarSettingsModel,
            pasteCleanupSettings: {
              prompt: false,
              plainText: false,
              keepFormat: true,
              allowedStyleProps: [""],
              deniedAttrs: ["class", "id", "style"],
            },
          }}
        />
        <div className="comment-input_actions">
          <Button
            text="Submit Comment"
            intent="primary"
            className="comment-input__submit-button"
            disabled={props.isDisabled}
            onClick={addComment}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicantIterationEngineCommentPanel;
