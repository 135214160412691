import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { HomeSCSHEvaluation_EvaluationFormModel } from "../../types/HomeSCSHEvaluation_EvaluationFormModel";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../types/HomeSCSHEvaluationFormModel";

class HomeSCSHEvaluationFormAPIClient {
  updateHomeSCSHEvaluationForm = async (
    evaluationId: string,
    homeSCSHEvaluationForm: HomeSCSHEvaluation_EvaluationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeSCSHEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeSCSHEvaluationFormModel>(
      `/home-scsh/evaluation-form/${evaluationId}`,
      {
        homeSCSHEvaluationForm,
        fieldKey,
      }
    );
  };

  submitHomeSCSHEvaluationForm = async (
    evaluationId: string,
    homeSCSHEvaluationForm: HomeSCSHEvaluation_EvaluationFormModel
  ): Promise<Evaluation_HomeSCSHEvaluationFormModel> => {
    return baseApiClient.post<Evaluation_HomeSCSHEvaluationFormModel>(
      `/home-scsh/evaluation-form/${evaluationId}`,
      homeSCSHEvaluationForm
    );
  };
}

export const homeSCSHEvaluationFormAPIClient =
  new HomeSCSHEvaluationFormAPIClient();
