import "./styles.scss";
import {
  FieldFormV2Module,
  PrimaryCard,
  RelativityTooltip,
} from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import AddBuildingsButtonV2 from "../../../common/components/ComplexCommercialEvaluationBuilding/Buttons/AddBuildingsButtonV2";
import { useComplexMultifamilyEvaluationBuildingsCardV2 } from "./useComplexMultifamilyEvaluationBuildingsCardV2";
import { Evaluation_MultifamilyEvaluation } from "../../types/MultifamilyEvaluation";
import ComplexMultifamilyEvaluationBuildingCardV2 from "./ComplexMultifamilyEvaluationBuildingCardV2";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";
import { createRef, useEffect } from "react";

export interface ComplexMultifamilyEvaluationBuildingsCardV2Props {
  evaluationId: string;
  evaluation:
    | Evaluation_MultifamilyEvaluation
    | Evaluation_MultifamilyEvaluationFormModel;
  setHasUnsavedChanges: (value: boolean) => void;
  isIterationEnginePage?: boolean;
  hideFormControls?: boolean;
  onInitialized?: () => void;
  onFormSave?: ((fieldKey: string, value: any) => Promise<void>) | undefined;
  buildingFormRefs: React.MutableRefObject<
    Map<
      string,
      React.RefObject<
        FieldFormV2Module<Evaluation_MultifamilyEvaluationFormModel>
      >
    >
  >;
  updateChildFormError: (formId: string, hasError: boolean) => void;
  updateChildRequiredFieldCount?: (childId: string, count: number) => void;
  isParentFormSubmitting: boolean;
}

const ComplexMultifamilyEvaluationBuildingsCardV2: React.FC<
  ComplexMultifamilyEvaluationBuildingsCardV2Props
> = (props) => {
  const {
    createBuilding,
    isSaving,
    isFetching,
    buildingIds,
    getBuildingIds,
    isBuildingDeleteButtonDisabled,
  } = useComplexMultifamilyEvaluationBuildingsCardV2(props);

  useEffect(() => {
    buildingIds?.forEach((id) => {
      if (!props.buildingFormRefs.current.has(id)) {
        props.buildingFormRefs.current.set(id, createRef());
      }
    });
  }, [buildingIds]);

  return (
    <div>
      {props.evaluation?.isComplexCommercialEvaluation && (
        <PrimaryCard
          id="complex-evaluation-buildings-card"
          className="complex-evaluation-buildings-card"
          headerContent={
            <>
              <h2>Buildings</h2>
              <AddBuildingsButtonV2
                isLoading={isSaving}
                onClick={async () => {
                  await createBuilding(props.evaluationId);
                }}
                disabled={
                  !props.evaluation.multifamilyEvaluation
                    ?.isComplexCommercialEvaluationBuildingFormAbleToBeModified ||
                  isFetching ||
                  props.isParentFormSubmitting
                }
              />
              <div className="flex-break"></div>
              <p className="primary-card-description">
                This section is where you can add, delete, and edit building
                information.
              </p>
            </>
          }
        >
          {!isFetching ? (
            buildingIds?.map((id, idx) => (
              <ComplexMultifamilyEvaluationBuildingCardV2
                key={id}
                id={id}
                buildingCount={idx + 1}
                isSaving={isSaving}
                disabled={
                  !props.evaluation.multifamilyEvaluation
                    ?.isComplexCommercialEvaluationBuildingFormAbleToBeModified ||
                  isSaving ||
                  props.isParentFormSubmitting
                }
                setHasUnsavedChanges={props.setHasUnsavedChanges}
                isIterationEnginePage={props.isIterationEnginePage}
                hideFormControls={props.hideFormControls}
                onInitialized={props.onInitialized}
                onFormSave={props.onFormSave}
                getBuildingIds={getBuildingIds}
                ref={props.buildingFormRefs.current.get(id)}
                updateChildFormError={props.updateChildFormError}
                updateChildRequiredFieldCount={
                  props.updateChildRequiredFieldCount
                }
                isDeleteButtonDisabled={isBuildingDeleteButtonDisabled}
              />
            ))
          ) : (
            <div className="buildings-loading-spinner">
              <RelativityTooltip
                placement="auto"
                compact={true}
                shouldShowTooltip={true}
                interactionKind={"hover"}
                content={"Buildings are being processed."}
              >
                <>
                  <Loading />
                </>
              </RelativityTooltip>
            </div>
          )}
        </PrimaryCard>
      )}
    </div>
  );
};

export default ComplexMultifamilyEvaluationBuildingsCardV2;
