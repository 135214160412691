import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../evaluation/types/HomeNCIUAEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "HomeNCIUAEvaluation";
const formName = "RoofingComplianceRoofCoverForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Roof Cover Information
const roofCoverInformationFields = () => [
  buildFieldKey("RoofCoverType"),
  buildFieldKey("RoofCoverTypeMinSlope"),
  buildFieldKey("RequiredWindRatingZone1"),
  buildFieldKey("RequiredWindRatingZone2"),
  buildFieldKey("RequiredWindRatingZone3"),
];

const roofCoverInformationRequiredFields = [
  buildFieldKey("RoofCoverType"),
  buildFieldKey("RoofCoverTypeMinSlope"),
  buildFieldKey("RequiredWindRatingZone1"),
  buildFieldKey("RequiredWindRatingZone2"),
  buildFieldKey("RequiredWindRatingZone3"),
];

const roofCoverInformationFileUploaderFields = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("RoofCoverTypeMinSlopePhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofCoverTypeMinSlopePhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("AllZonesRequiredWindRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "AllZonesRequiredWindRatingDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const roofingComplianceRoofCoverInformationBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...roofCoverInformationFields(),
          ...roofCoverInformationFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        roofCoverInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 2: Asphalt Shingle Roof Cover Details
const asphaltShingleRoofCoverDetailsFields = () => [
  buildFieldKey("AreAsphaltShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("AsphaltShingleManufacturerName"),
  buildFieldKey("AsphaltShingleProductName"),
  buildFieldKey("DoesAsphaltShingleWindRatingMeetASTMClassFOrClassH"),
  buildFieldKey("AsphaltShingleHailRatingType"),
  buildFieldKey(
    "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey(
    "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions"
  ),
  buildFieldKey("NumberOfInstalledNailsPerAsphaltShingle"),
  buildFieldKey("StartStripInstallationAtEavesType"),
  buildFieldKey("StartStripInstallationAtGlobeRakesType"),
];

const asphaltShingleRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreAsphaltShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("AsphaltShingleManufacturerName"),
  buildFieldKey("AsphaltShingleProductName"),
  buildFieldKey("DoesAsphaltShingleWindRatingMeetASTMClassFOrClassH"),
  buildFieldKey("AsphaltShingleHailRatingType"),
  buildFieldKey(
    "DoAsphaltShingleNailsMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey(
    "AreAsphaltShinglesInstalledPerManufacturerRecommendationsForHighWindRegions"
  ),
  buildFieldKey("NumberOfInstalledNailsPerAsphaltShingle"),
  buildFieldKey("StartStripInstallationAtEavesType"),
  buildFieldKey("StartStripInstallationAtGlobeRakesType"),
  buildFieldKey("ShingleManufacturerAndProductPhoto"),
  buildFieldKey("ShingleWindRatingPhoto"),
  buildFieldKey("ShingleHailRatingPhoto"),
  buildFieldKey("StarterStripInstallationPhotos"),
];

const asphaltShingleRoofCoverDetailsFieldsFileUploaderFields = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ShingleManufacturerAndProductPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "ShingleManufacturerAndProductPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ShingleWindRatingPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "ShingleWindRatingPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ShingleHailRatingPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "ShingleHailRatingPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StarterStripInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "StarterStripInstallationPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const asphaltShingleRoofCoverDetailsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...asphaltShingleRoofCoverDetailsFields(),
          ...asphaltShingleRoofCoverDetailsFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        asphaltShingleRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 3: Metal Roof Cover Details
const metalRoofCoverDetailsFields = () => [
  buildFieldKey("AreMetalPanelManufacturerAndProductDetailsKnown"),
  buildFieldKey("MetalPanelManufacturerName"),
  buildFieldKey("MetalPanelProductName"),
  buildFieldKey("MetalPanelCertifiedTestReportProductApprovalNumber"),
  buildFieldKey(
    "MetalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey("MetalPanelFirstInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MatelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelSecondInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelThirdInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("InstalledMetalPanelMaterial"),
  buildFieldKey("InstalledMetalPanelGaugeThickness"),
  buildFieldKey("InstalledMetalPanelFastenerTypeAndSize"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone1"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone2"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone3"),
  buildFieldKey("WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards"),
  buildFieldKey("DoesMetalPanelSystemHaveUL2218Class4ImpactRating"),
];

const metalRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreMetalPanelManufacturerAndProductDetailsKnown"),
  buildFieldKey("MetalPanelManufacturerName"),
  buildFieldKey("MetalPanelProductName"),
  buildFieldKey("MetalPanelCertifiedTestReportProductApprovalNumber"),
  buildFieldKey("MetalPanelCertifiedTestReportDocumentation"),
  buildFieldKey(
    "MetalPanelSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey("MetalPanelFirstInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MatelPanelFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelSecondInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("MetalPanelThirdInstalledSystemNumberPerCertifiedTestReport"),
  buildFieldKey(
    "MetalPanelThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "MetalPanelAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoMetalPanelsAndExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("InstalledMetalPanelMaterial"),
  buildFieldKey("InstalledMetalPanelGaugeThickness"),
  buildFieldKey("InstalledMetalPanelFastenerTypeAndSize"),
  buildFieldKey("MetalPanelFastenersPhoto"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone1"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone2"),
  buildFieldKey("InstalledMetalPanelFastenerPatternAndSpacingZone3"),
  buildFieldKey("MetalPanelInstalledFastenerPatternAndSpacingPhotos"),
  buildFieldKey("WasMetalPanelRoofCoverSystemInstalledInAccordanceToStandards"),
  buildFieldKey("DoesMetalPanelSystemHaveUL2218Class4ImpactRating"),
];

const metalRoofCoverDetailsFieldsFileUploaderFields = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("MetalPanelCertifiedTestReportDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MetalPanelCertifiedTestReportDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("MetalPanelFastenersPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "MetalPanelFastenersPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MetalPanelInstalledFastenerPatternAndSpacingPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MetalPanelInstalledFastenerPatternAndSpacingPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const metalRoofCoverDetailsFieldsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...metalRoofCoverDetailsFields(),
          ...metalRoofCoverDetailsFieldsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        metalRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 4: Clay Concrete Tile Roof Cover Details
const clayConcreteTileRoofCoverDetailsFields = () => [
  buildFieldKey("AreTheClayConcreteTileManufacturerAndProductDetailsKnown"),
  buildFieldKey("ClayConcreteTileManufacturerName"),
  buildFieldKey("ClayConcreteTileProductName"),
  buildFieldKey("ClayConcreteTileTestReportApprovalNumber"),
  buildFieldKey("ClayConcreteTileNumberOfInstalledSystemsFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("ClayConcreteTileSystemsIdentifiedFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating"
  ),
  buildFieldKey(
    "ClayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports"
  ),
  buildFieldKey("MechanicalAdhesiveRoofTileAttachmentType"),
  buildFieldKey("MechanicalFastenerType"),
  buildFieldKey("MechanicalFastenerSize"),
  buildFieldKey("MechanicalFastenerQuantityPerTile"),
  buildFieldKey(
    "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("AdhesiveFoamManufacturerName"),
  buildFieldKey("AdhesiveFoamProductName"),
  buildFieldKey("AdhesiveFoamTestReportApprovalNumber"),
  buildFieldKey("IsAdhesiveFoamInstallerCertifiedByFoamManufacturer"),
  buildFieldKey("AdhesiveFoamInstallerCertificationNumber"),
  buildFieldKey("TileUnderlaymentCertifiedTestReportNumber"),
  buildFieldKey("IsTileAndFlashingInstalledPerFRSATRIInstructions"),
  buildFieldKey("IsTileAndFlashingInstalledPerTRIWSRCAInstructions"),
];

const clayConcreteTileRoofCoverDetailsRequiredFields = [
  buildFieldKey("AreTheClayConcreteTileManufacturerAndProductDetailsKnown"),
  buildFieldKey("ClayConcreteTileManufacturerName"),
  buildFieldKey("ClayConcreteTileProductName"),
  buildFieldKey("ClayConcreteTileTestReportApprovalNumber"),
  buildFieldKey("ClayConcreteTileCertifiedTestReportDocumentation"),
  buildFieldKey("ClayConcreteTileNumberOfInstalledSystemsFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "ClayConcreteTileThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey("ClayConcreteTileSystemsIdentifiedFromTestReport"),
  buildFieldKey(
    "ClayConcreteTileAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "DoesClayConcreteTileRoofCoverSystemHaveFM4473Class4ImpactRating"
  ),
  buildFieldKey(
    "ClayConcreteTileCertifiedTestReportProductApprovalNumberForStructuralSupports"
  ),
  buildFieldKey("TileStructuralSupportsCertifiedTestReportDocumentation"),
  buildFieldKey("MechanicalAdhesiveRoofTileAttachmentType"),
  buildFieldKey("MechanicalFastenerType"),
  buildFieldKey("RoofTileFastenersPhoto"),
  buildFieldKey("MechanicalFastenerSize"),
  buildFieldKey("MechanicalFastenerQuantityPerTile"),
  buildFieldKey(
    "DoMechanicalTileFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("AdhesiveFoamManufacturerName"),
  buildFieldKey("AdhesiveFoamProductName"),
  buildFieldKey("AdhesiveFoamTestReportApprovalNumber"),
  buildFieldKey("IsAdhesiveFoamInstallerCertifiedByFoamManufacturer"),
  buildFieldKey("AdhesiveFoamInstallerCertificationNumber"),
  buildFieldKey("TileUnderlaymentCertifiedTestReportNumber"),
  buildFieldKey("IsTileAndFlashingInstalledPerFRSATRIInstructions"),
  buildFieldKey("IsTileAndFlashingInstalledPerTRIWSRCAInstructions"),
];

const clayConcreteTileRoofCoverDetailsFileUploaderFields = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ClayConcreteTileCertifiedTestReportDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ClayConcreteTileCertifiedTestReportDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "TileStructuralSupportsCertifiedTestReportDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "TileStructuralSupportsCertifiedTestReportDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RoofTileFastenersPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofTileFastenersPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const clayConcreteTileRoofCoverDetailsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...clayConcreteTileRoofCoverDetailsFields(),
          ...clayConcreteTileRoofCoverDetailsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        clayConcreteTileRoofCoverDetailsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 5: Wood Shake Shingle Roof Cover Details
const woodShakeShingleRoofCoverDetailsFields = () => [
  buildFieldKey("AreTheWoodShakeShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("WoodShakeShingleManufacturerName"),
  buildFieldKey("WoodShakeShingleProductName"),
  buildFieldKey("WoodShakeShingleTestReportApprovalNumber"),
  buildFieldKey("WoodShakeShingleDesignPressureWindRatingOfInstalledSystem"),
  buildFieldKey(
    "WoodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications"
  ),
  buildFieldKey("WoodShakeShingleMeanRoofHeightLimitation"),
  buildFieldKey("WoodShakeShingleRequiredNumberOfNailsPerShingle"),
  buildFieldKey(
    "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions"
  ),
  buildFieldKey(
    "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating"
  ),
  buildFieldKey("WoodShakeShingleFastenerType"),
  buildFieldKey("WoodShakeShingleFastenerSize"),
  buildFieldKey("WereStaplesUsedToInstallWoodShakesShingles"),
  buildFieldKey("WoodShakeShingleAreStainlessSteelFastenersRequired"),
  buildFieldKey("WereStainlessFastenersUsedToInstallWoodShakesShingles"),
  buildFieldKey("IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingle"),
  buildFieldKey(
    "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport"
  ),
];

const woodShakeShingleRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreTheWoodShakeShingleManufacturerAndProductDetailsKnown"),
  buildFieldKey("WoodShakeShingleManufacturerName"),
  buildFieldKey("WoodShakeShingleProductName"),
  buildFieldKey("WoodShakeShingleTestReportApprovalNumber"),
  buildFieldKey("WoodShakeShingleCertifiedTestReportDocumentation"),
  buildFieldKey("WoodShakeShingleDesignPressureWindRatingOfInstalledSystem"),
  buildFieldKey(
    "WoodShakeShingleIsMeanRoofHeightLimitationPerManufacturerProductSpecifications"
  ),
  buildFieldKey("WoodShakeShingleMeanRoofHeightLimitation"),
  buildFieldKey("WoodShakeShingleRequiredNumberOfNailsPerShingle"),
  buildFieldKey(
    "WoodShakeShingleDoesWindRatingListedInCertifiedTestReportMeetOrExceedDesignConditions"
  ),
  buildFieldKey(
    "DoesWoodShakeShingleHaveUL2218Class4OrFM4473Class4ImpactRating"
  ),
  buildFieldKey("WoodShakeShingleFastenerType"),
  buildFieldKey("WoodShakeShingleFastenersPhoto"),
  buildFieldKey("WoodShakeShingleFastenerSize"),
  buildFieldKey("WereStaplesUsedToInstallWoodShakesShingles"),
  buildFieldKey("WoodShakeShingleAreStainlessSteelFastenersRequired"),
  buildFieldKey("WereStainlessFastenersUsedToInstallWoodShakesShingles"),
  buildFieldKey("IsSealedRoofDeckMaterialCompatibleWithWoodShakeShingle"),
  buildFieldKey(
    "WasWoodShakeShingleRoofCoverSystemInstalledInAccordanceWithCertifiedTestReport"
  ),
];

const woodShakeShingleRoofCoverDetailsFieldsFileUploaderFields = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("WoodShakeShingleCertifiedTestReportDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "WoodShakeShingleCertifiedTestReportDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WoodShakeShingleFastenersPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "WoodShakeShingleFastenersPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const woodShakeShingleRoofCoverDetailsFieldsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...woodShakeShingleRoofCoverDetailsFields(),
          ...woodShakeShingleRoofCoverDetailsFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 6: Low Slope Roof Cover Details
const lowSlopeRoofCoverDetailsFields = () => [
  buildFieldKey("AreTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown"),
  buildFieldKey("LowSlopeRoofCoverManufacturerName"),
  buildFieldKey("LowSlopeRoofCoverProductName"),
  buildFieldKey("LowSlopeRoofCoverTestReportApprovalNumber"),
  buildFieldKey(
    "LowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRating"),
  buildFieldKey("LowSlopeRoofCoverFastenerTypeAndSize"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone1"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone2"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone3"),
  buildFieldKey(
    "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport"
  ),
];

const lowSlopeRoofCoverDetailsFieldsRequiredFields = [
  buildFieldKey("AreTheLowSlopeRoofCoverManufacturerAndProductDetailsKnown"),
  buildFieldKey("LowSlopeRoofCoverManufacturerName"),
  buildFieldKey("LowSlopeRoofCoverProductName"),
  buildFieldKey("LowSlopeRoofCoverTestReportApprovalNumber"),
  buildFieldKey("LowSlopeRoofCoverCertifiedTestReportDocumentation"),
  buildFieldKey(
    "LowSlopeRoofCoverSelectNumberOfDifferentSystemsFromCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverFirstInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverSecondInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemNumberPerCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverThirdInstalledSystemDesignPressureOrMomentResistanceRating"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverAreDesignRatingsOfInstalledSystemsListedInCertifiedTestReport"
  ),
  buildFieldKey(
    "LowSlopeRoofCoverDoExposedFastenersMeetFortifiedCorrosionResistanceRequirements"
  ),
  buildFieldKey("DoesLowSlopeRoofCoverSystemHaveADesignatedImpactRating"),
  buildFieldKey("LowSlopeRoofCoverFastenerTypeAndSize"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone1"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone2"),
  buildFieldKey("LowSlopeRoofCoverFastenerSpacingZone3"),
  buildFieldKey(
    "WasLowSlopeRoofCoverInstalledInAccordanceWithCertifiedTestReport"
  ),
];

const lowSlopeRoofCoverDetailsFieldsFileUploaderFields = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey(
      "LowSlopeRoofCoverCertifiedTestReportDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "LowSlopeRoofCoverCertifiedTestReportDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const lowSlopeRoofCoverDetailsFieldsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...lowSlopeRoofCoverDetailsFields(),
          ...lowSlopeRoofCoverDetailsFieldsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        lowSlopeRoofCoverDetailsFieldsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeNCIUARoofingComplianceRoofCoverFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...roofCoverInformationFields(),
          ...roofCoverInformationFileUploaderFields(form, errors),
          ...asphaltShingleRoofCoverDetailsFields(),
          ...asphaltShingleRoofCoverDetailsFieldsFileUploaderFields(
            form,
            errors
          ),
          ...metalRoofCoverDetailsFields(),
          ...metalRoofCoverDetailsFieldsFileUploaderFields(form, errors),
          ...clayConcreteTileRoofCoverDetailsFields(),
          ...clayConcreteTileRoofCoverDetailsFileUploaderFields(form, errors),
          ...woodShakeShingleRoofCoverDetailsFields(),
          ...woodShakeShingleRoofCoverDetailsFieldsFileUploaderFields(
            form,
            errors
          ),
          ...lowSlopeRoofCoverDetailsFields(),
          ...lowSlopeRoofCoverDetailsFieldsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...roofCoverInformationRequiredFields,
          ...asphaltShingleRoofCoverDetailsFieldsRequiredFields,
          ...metalRoofCoverDetailsFieldsRequiredFields,
          ...clayConcreteTileRoofCoverDetailsRequiredFields,
          ...woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
          ...lowSlopeRoofCoverDetailsFieldsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUAIterationEngineRoofingComplianceRoofCoverFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...roofCoverInformationFields(),
          ...roofCoverInformationFileUploaderFields(form, errors),
          ...asphaltShingleRoofCoverDetailsFields(),
          ...asphaltShingleRoofCoverDetailsFieldsFileUploaderFields(
            form,
            errors
          ),
          ...metalRoofCoverDetailsFields(),
          ...metalRoofCoverDetailsFieldsFileUploaderFields(form, errors),
          ...clayConcreteTileRoofCoverDetailsFields(),
          ...clayConcreteTileRoofCoverDetailsFileUploaderFields(form, errors),
          ...woodShakeShingleRoofCoverDetailsFields(),
          ...woodShakeShingleRoofCoverDetailsFieldsFileUploaderFields(
            form,
            errors
          ),
          ...lowSlopeRoofCoverDetailsFields(),
          ...lowSlopeRoofCoverDetailsFieldsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...roofCoverInformationRequiredFields,
          ...asphaltShingleRoofCoverDetailsFieldsRequiredFields,
          ...metalRoofCoverDetailsFieldsRequiredFields,
          ...clayConcreteTileRoofCoverDetailsRequiredFields,
          ...woodShakeShingleRoofCoverDetailsFieldsRequiredFields,
          ...lowSlopeRoofCoverDetailsFieldsRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};
