import { Icon, IconProps, Tab, Tabs } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React from "react";
import {
  EvaluationStatus,
  EvaluationStep,
} from "../../../../../foritfied/types/evaluation/Evaluation";
import "./styles.scss";

interface IterationEngineChecklistAndTabsProps {
  evaluationStep: EvaluationStep;
  evaluationStatus: EvaluationStatus;
  selectedTabId: string;
  setSelectedTabId: (tabId: string) => void;
  formTabs: { id: string; label: string; disabled?: boolean }[];
  evaluationStepDetails: {
    id: EvaluationStep;
    label: string;
  }[];
  children?: React.ReactNode;
}

const FortifiedIterationEngineChecklistAndTabs: React.FC<
  IterationEngineChecklistAndTabsProps
> = (props) => {
  return (
    <div className="iteration-engine-checklist-and-tabs">
      <div className="steps-container">
        <div className="steps-container__header">Status</div>
        <div className="steps-container__steps">
          {props.evaluationStepDetails.map((step) => (
            <EvaluationStepItem
              key={step.id}
              step={step}
              evaluationStep={props.evaluationStep}
              evaluationStatus={props.evaluationStatus}
              evaluationStepDetails={props.evaluationStepDetails}
            />
          ))}
        </div>
      </div>
      <div className="form-navigation-container">
        <div className="form-navigation-container__header">Forms</div>
        <FormTabs
          selectedTabId={props.selectedTabId}
          setSelectedTabId={props.setSelectedTabId}
          formTabs={props.formTabs}
        />
      </div>
      {props.children}
    </div>
  );
};

const EvaluationStepItem: React.FC<{
  step: { id: EvaluationStep; label: string };
  evaluationStep: EvaluationStep;
  evaluationStatus: EvaluationStatus;
  evaluationStepDetails: { id: EvaluationStep; label: string }[];
}> = (props) => {
  const iconProps = getIcon(
    props.step.id,
    props.evaluationStep,
    props.evaluationStatus,
    props.evaluationStepDetails
  );

  return (
    <div className="steps-container-step" key={props.step.id}>
      <Icon size={20} {...iconProps} />
      <div className="steps-container-step__label">{props.step.label}</div>
    </div>
  );
};

const FormTabs: React.FC<{
  selectedTabId: string;
  setSelectedTabId: (tabId: string) => void;
  formTabs: { id: string; label: string; disabled?: boolean }[];
}> = (props) => (
  <Tabs
    className="form-navigation__tabs"
    vertical
    selectedTabId={props.selectedTabId}
    onChange={(newTabId: string) => props.setSelectedTabId(newTabId)}
  >
    {props.formTabs.map((tab) => (
      <Tab
        className="form-navigation-tab"
        key={tab.id}
        id={tab.id}
        title={tab.label}
        disabled={tab.disabled}
      ></Tab>
    ))}
  </Tabs>
);

const getIcon = (
  step: EvaluationStep,
  evaluationStep: EvaluationStep,
  evaluationStatus: EvaluationStatus,
  evaluationStepDetails: { id: EvaluationStep; label: string }[]
): IconProps => {
  const isCurrentStep = step === evaluationStep;
  const stepIndex = evaluationStepDetails.findIndex(
    (detail) => detail.id === step
  );
  const currentStepIndex = evaluationStepDetails.findIndex(
    (detail) => detail.id === evaluationStep
  );
  const isCompleted = currentStepIndex > stepIndex;
  const isApproved = evaluationStatus === "Approved";
  const isNotApproved = evaluationStatus === "Not Approved";

  if (isCompleted || isApproved) {
    return { icon: IconNames.TICK_CIRCLE };
  }
  if (
    isCurrentStep &&
    (step === EvaluationStep.EligibilityReview ||
      step === EvaluationStep.FinalReview) &&
    isNotApproved
  ) {
    return { icon: IconNames.CROSS_CIRCLE };
  }
  if (isCurrentStep) {
    return { icon: IconNames.CIRCLE };
  }
  return { icon: IconNames.FULL_CIRCLE };
};

export default FortifiedIterationEngineChecklistAndTabs;
