import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "SilverGoldComplianceGeneralInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const silverGoldComplianceGeneralInformationFields = () => [
  buildFieldKey("SiteDesignInformation"),
  buildFieldKey("DidEngineerSpecifyWindPressuresForZone4And5"),
  buildFieldKey("Zone4PositiveRequiredWallWindPressure"),
  buildFieldKey("Zone4NegativeRequiredWallWindPressure"),
  buildFieldKey("Zone5PositiveRequiredWallWindPressure"),
  buildFieldKey("Zone5NegativeRequiredWallWindPressure"),
  buildFieldKey("WindowsAndSkylights"),
  buildFieldKey("AreSkylightsOrLightTunnelsPresent"),
  buildFieldKey("AreSkylightsImpactRated"),
  buildFieldKey("AreWindowsImpactRatedOrProtected"),
  buildFieldKey("EntryDoors"),
  buildFieldKey("AreEntryDoorsImpactRatedOrProtected"),
  buildFieldKey("Garage"),
  buildFieldKey("DoesHomeHaveGarageDoors"),
  buildFieldKey("DoesGarageDoorsHaveGlazing"),
  buildFieldKey("AreGarageDoorsImpactRatedOrProtected"),
  buildFieldKey("IsGarageDoorDesignPressureRated"),
  buildFieldKey("DesignPressureRatingOfGarageDoorsPositive"),
  buildFieldKey("DesignPressureRatingOfGarageDoorsNegative"),
  buildFieldKey("DoesGarageDoorPressureRatingMeetOrExceed"),
  buildFieldKey("GableEnds"),
  buildFieldKey("Soffits"),
  buildFieldKey("SoffitCoverType"),
  buildFieldKey("DoesSoffitOverhangExtendMoreThan12Inches"),
  buildFieldKey("Chimneys"),
  buildFieldKey("AttachedStructures"),
  buildFieldKey("DesignPressureRatedOpenings"),
  buildFieldKey("DoWindowPressureRatingsMeetOrExceed"),
  buildFieldKey("DoEntryDoorPressureRatingsMeetOrExceed"),
  buildFieldKey("DoSkylightsDesignPressuresMeetOrExceed"),
  buildFieldKey("WallSheathing"),
  buildFieldKey("ContinuousLoadPath"),
];

const silverGoldComplianceGeneralInformationFieldsFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("WallWindPressuresDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "WallWindPressuresDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SkylightDebrisImpactRatingPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "SkylightDebrisImpactRatingPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SkylightDebrisImpactRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SkylightDebrisImpactRatingDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WindowLabelsAndImpactRatedCoverPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "WindowLabelsAndImpactRatedCoverPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WindowRatingAndImpactRatedCoverDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "WindowRatingAndImpactRatedCoverDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("EntryDoorLabelAndImpactRatedCoverPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "EntryDoorLabelAndImpactRatedCoverPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("EntryDoorRatingAndImpactRatedCoverDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "EntryDoorRatingAndImpactRatedCoverDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GarageDoorLabelAndImpactRatedCoverPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "GarageDoorLabelAndImpactRatedCoverPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GarageDoorRatingAndImpactRatedCoverDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "GarageDoorRatingAndImpactRatedCoverDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GableEndSheathing"),
    defaultToUpload: false,
    errorMessages: get(errors, "GableEndSheathing.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GableEndBracing"),
    defaultToUpload: false,
    errorMessages: get(errors, "GableEndBracing.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GableEndOverhangs"),
    defaultToUpload: false,
    errorMessages: get(errors, "GableEndOverhangs.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("FlexibleSoffitCoverPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "FlexibleSoffitCoverPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RigidSoffitCoverPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RigidSoffitCoverPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SoffitBracingPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "SoffitBracingPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ChimneyDetails"),
    defaultToUpload: false,
    errorMessages: get(errors, "ChimneyDetails.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ChimneyLocation"),
    defaultToUpload: false,
    errorMessages: get(errors, "ChimneyLocation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ChimneyFramingAndAnchorage"),
    defaultToUpload: false,
    errorMessages: get(errors, "ChimneyFramingAndAnchorage.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("AttachedStructureRoofToBeam"),
    defaultToUpload: false,
    errorMessages: get(errors, "AttachedStructureRoofToBeam.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SingleLevelAttachedStructureBeamToColumn"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SingleLevelAttachedStructureBeamToColumn.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SingleLevelAttachedStructureColumnToFoundation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SingleLevelAttachedStructureColumnToFoundation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureRoofBeamToUpperLevelColumns"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MultiLevelAttachedStructureRoofBeamToUpperLevelColumns.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MultiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MultiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "MultiLevelAttachedStructureLowerLevelColumnToFoundation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MultiLevelAttachedStructureLowerLevelColumnToFoundation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "AdditionalDesignPressureRatingDocumentationForOpenings"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "AdditionalDesignPressureRatingDocumentationForOpenings.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WallSheathingTypeAndThickness"),
    defaultToUpload: false,
    errorMessages: get(errors, "WallSheathingTypeAndThickness.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RoofToWallConnection"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofToWallConnection.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("WallAboveToWallBelowConnection"),
    defaultToUpload: false,
    errorMessages: get(errors, "WallAboveToWallBelowConnection.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("LowerWallToFoundationConnection"),
    defaultToUpload: false,
    errorMessages: get(errors, "LowerWallToFoundationConnection.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("HoldDownsTensionTiesAtBuildingCorners"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "HoldDownsTensionTiesAtBuildingCorners.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const silverGoldComplianceGeneralInformationRequiredFields = [
  buildFieldKey("SiteDesignInformation"),
  buildFieldKey("DidEngineerSpecifyWindPressuresForZone4And5"),
  buildFieldKey("Zone4PositiveRequiredWallWindPressure"),
  buildFieldKey("Zone4NegativeRequiredWallWindPressure"),
  buildFieldKey("Zone5PositiveRequiredWallWindPressure"),
  buildFieldKey("Zone5NegativeRequiredWallWindPressure"),
  buildFieldKey("WindowsAndSkylights"),
  buildFieldKey("AreSkylightsOrLightTunnelsPresent"),
  buildFieldKey("AreSkylightsImpactRated"),
  buildFieldKey("AreWindowsImpactRatedOrProtected"),
  buildFieldKey("EntryDoors"),
  buildFieldKey("AreEntryDoorsImpactRatedOrProtected"),
  buildFieldKey("Garage"),
  buildFieldKey("DoesHomeHaveGarageDoors"),
  buildFieldKey("DoesGarageDoorsHaveGlazing"),
  buildFieldKey("AreGarageDoorsImpactRatedOrProtected"),
  buildFieldKey("IsGarageDoorDesignPressureRated"),
  buildFieldKey("DesignPressureRatingOfGarageDoorsPositive"),
  buildFieldKey("DesignPressureRatingOfGarageDoorsNegative"),
  buildFieldKey("DoesGarageDoorPressureRatingMeetOrExceed"),
  buildFieldKey("GableEnds"),
  buildFieldKey("Soffits"),
  buildFieldKey("SoffitCoverType"),
  buildFieldKey("DoesSoffitOverhangExtendMoreThan12Inches"),
  buildFieldKey("Chimneys"),
  buildFieldKey("AttachedStructures"),
  buildFieldKey("DesignPressureRatedOpenings"),
  buildFieldKey("DoWindowPressureRatingsMeetOrExceed"),
  buildFieldKey("DoEntryDoorPressureRatingsMeetOrExceed"),
  buildFieldKey("DoSkylightsDesignPressuresMeetOrExceed"),
  buildFieldKey("WallSheathing"),
  buildFieldKey("ContinuousLoadPath"),
];

export const engineerConfirmationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...silverGoldComplianceGeneralInformationFields(),
          ...silverGoldComplianceGeneralInformationFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        silverGoldComplianceGeneralInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const silverGoldComplianceGeneralInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...silverGoldComplianceGeneralInformationFields(),
          ...silverGoldComplianceGeneralInformationFieldsFileUploaderFields(
            form,
            errors
          ),
        ],
        onValueChange,
        form,
        errors,
        silverGoldComplianceGeneralInformationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const silverGoldComplianceGeneralInformationFormIterationEngineBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...silverGoldComplianceGeneralInformationFields(),
            ...silverGoldComplianceGeneralInformationFieldsFileUploaderFields(
              form,
              errors
            ),
          ],
          onValueChange,
          form,
          errors,
          silverGoldComplianceGeneralInformationRequiredFields,
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
