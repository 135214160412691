import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { homeNCIUARedesignationAPIClient } from "../evaluation/lib/apiClients/homeNCIUARedesignationAPIClient";
import { Evaluation_HomeNCIUARedesignation } from "../evaluation/types/HomeNCIUARedesignation";

export const useHomeNCIUARedesignation = (evaluationId: string) => {
  const [homeNCIUAEvaluation, setHomeNCIUAEvaluation] =
    useState<Evaluation_HomeNCIUARedesignation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getNCIUARedesignation(evaluationId);
  }, [evaluationId]);

  const getNCIUARedesignation = async (evaluationId: string) => {
    await homeNCIUARedesignationAPIClient
      .getHomeNCIUARedesignation(evaluationId)
      .then((response) => {
        setHomeNCIUAEvaluation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshHomeNCIUAEvaluation = async () => {
    setIsApplicationLoading(true);
    if (homeNCIUAEvaluation?.id) {
      await homeNCIUARedesignationAPIClient
        .getHomeNCIUARedesignation(homeNCIUAEvaluation?.id)
        .then((response) => {
          setHomeNCIUAEvaluation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    homeNCIUAEvaluation,
    refreshHomeNCIUAEvaluation,
    isApplicationLoading,
  };
};
