import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationAPIClient";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormProps } from "./HomeStandardEvaluationSilverGoldComplianceGeneralInformationForm";
import { BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts } from "../../../../../common/types/BaseHomeEvaluationSilverGoldComplianceGeneralInformationForm";
import { homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient";

function useHomeStandardEvaluationSilverGoldComplianceGeneralInformationForm(
  props: HomeStandardEvaluationSilverGoldComplianceGeneralInformationFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeStandardEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
    )[0];

  const isLastPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
    ).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeStandardEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeStandardIterationEngineSilverGoldComplianceGeneralInformationForm;
    }
    switch (props.formPart) {
      case BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts.SilverGoldComplianceGeneralInformation:
        return builders?.HomeStandardSilverGoldComplianceGeneralInformationForm;
      default:
        return builders?.HomeStandardSilverGoldComplianceGeneralInformationForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);

    await (shouldSubmit
      ? homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient.submitHomeStandardSilverGoldComplianceGeneralInformationForm(
          form.id,
          form.homeStandardEvaluation
            .silverGoldComplianceGeneralInformationForm,
          props.formPart
        )
      : homeStandardEvaluationSilverGoldComplianceGeneralInformationAPIClient.updateHomeStandardSilverGoldComplianceGeneralInformationForm(
          form.id,
          form.homeStandardEvaluation
            .silverGoldComplianceGeneralInformationForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);

      if (isLastPartOfForm) {
      } else {
        // Navigate to next part of form
        const nextFormPart = Object.values(
          BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
        )[
          Object.values(
            BaseHomeEvaluationSilverGoldComplianceGeneralInformationFormParts
          ).indexOf(props.formPart!) + 1
        ].valueOf();

        navigate(
          `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/silver-gold-compliance-general-information/${nextFormPart}`
        );
      }

      // }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        AreAllRequiredFieldsComplete,
        SiteDesignInformation,
        DidEngineerSpecifyWindPressuresForZone4And5,
        Zone4PositiveRequiredWallWindPressure,
        Zone4NegativeRequiredWallWindPressure,
        Zone5PositiveRequiredWallWindPressure,
        Zone5NegativeRequiredWallWindPressure,
        WallWindPressuresDocumentation,
        WindowsAndSkylights,
        AreSkylightsOrLightTunnelsPresent,
        AreSkylightsImpactRated,
        SkylightDebrisImpactRatingPhotos,
        SkylightDebrisImpactRatingDocumentation,
        AreWindowsImpactRatedOrProtected,
        WindowLabelsAndImpactRatedCoverPhotos,
        WindowRatingAndImpactRatedCoverDocumentation,
        EntryDoors,
        AreEntryDoorsImpactRatedOrProtected,
        EntryDoorLabelAndImpactRatedCoverPhotos,
        EntryDoorRatingAndImpactRatedCoverDocumentation,
        Garage,
        DoesHomeHaveGarageDoors,
        DoesGarageDoorsHaveGlazing,
        AreGarageDoorsImpactRatedOrProtected,
        IsGarageDoorDesignPressureRated,
        DesignPressureRatingOfGarageDoorsPositive,
        DesignPressureRatingOfGarageDoorsNegative,
        DoesGarageDoorPressureRatingMeetOrExceed,
        GarageDoorLabelAndImpactRatedCoverPhotos,
        GarageDoorRatingAndImpactRatedCoverDocumentation,
        GableEnds,
        GableEndSheathing,
        GableEndBracing,
        GableEndOverhangs,
        Soffits,
        SoffitCoverType,
        FlexibleSoffitCoverPhoto,
        RigidSoffitCoverPhoto,
        DoesSoffitOverhangExtendMoreThan12Inches,
        SoffitBracingPhoto,
        Chimneys,
        ChimneyDetails,
        ChimneyLocation,
        ChimneyFramingAndAnchorage,
        AttachedStructures,
        AttachedStructureRoofToBeam,
        SingleLevelAttachedStructureBeamToColumn,
        SingleLevelAttachedStructureColumnToFoundation,
        MultiLevelAttachedStructureRoofBeamToUpperLevelColumns,
        MultiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams,
        MultiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn,
        MultiLevelAttachedStructureLowerLevelColumnToFoundation,
        DesignPressureRatedOpenings,
        DoWindowPressureRatingsMeetOrExceed,
        DoEntryDoorPressureRatingsMeetOrExceed,
        AdditionalDesignPressureRatingDocumentationForOpenings,
        DoSkylightsDesignPressuresMeetOrExceed,
        WallSheathing,
        WallSheathingTypeAndThickness,
        ContinuousLoadPath,
        RoofToWallConnection,
        WallAboveToWallBelowConnection,
        LowerWallToFoundationConnection,
        HoldDownsTensionTiesAtBuildingCorners,
      } =
        response.homeStandardEvaluation
          .silverGoldComplianceGeneralInformationForm;

      const { canProceedToApplicationFee } = response.homeStandardEvaluation;

      return {
        ...prevState,
        homeStandardEvaluation: {
          ...prevState.homeStandardEvaluation,
          canProceedToApplicationFee,
          silverGoldComplianceGeneralInformationForm: {
            ...prevState.homeStandardEvaluation
              .silverGoldComplianceGeneralInformationForm,
            AreAllRequiredFieldsComplete,
            SiteDesignInformation,
            DidEngineerSpecifyWindPressuresForZone4And5,
            Zone4PositiveRequiredWallWindPressure,
            Zone4NegativeRequiredWallWindPressure,
            Zone5PositiveRequiredWallWindPressure,
            Zone5NegativeRequiredWallWindPressure,
            WallWindPressuresDocumentation,
            WindowsAndSkylights,
            AreSkylightsOrLightTunnelsPresent,
            AreSkylightsImpactRated,
            SkylightDebrisImpactRatingPhotos,
            SkylightDebrisImpactRatingDocumentation,
            AreWindowsImpactRatedOrProtected,
            WindowLabelsAndImpactRatedCoverPhotos,
            WindowRatingAndImpactRatedCoverDocumentation,
            EntryDoors,
            AreEntryDoorsImpactRatedOrProtected,
            EntryDoorLabelAndImpactRatedCoverPhotos,
            EntryDoorRatingAndImpactRatedCoverDocumentation,
            Garage,
            DoesHomeHaveGarageDoors,
            DoesGarageDoorsHaveGlazing,
            AreGarageDoorsImpactRatedOrProtected,
            IsGarageDoorDesignPressureRated,
            DesignPressureRatingOfGarageDoorsPositive,
            DesignPressureRatingOfGarageDoorsNegative,
            DoesGarageDoorPressureRatingMeetOrExceed,
            GarageDoorLabelAndImpactRatedCoverPhotos,
            GarageDoorRatingAndImpactRatedCoverDocumentation,
            GableEnds,
            GableEndSheathing,
            GableEndBracing,
            GableEndOverhangs,
            Soffits,
            SoffitCoverType,
            FlexibleSoffitCoverPhoto,
            RigidSoffitCoverPhoto,
            DoesSoffitOverhangExtendMoreThan12Inches,
            SoffitBracingPhoto,
            Chimneys,
            ChimneyDetails,
            ChimneyLocation,
            ChimneyFramingAndAnchorage,
            AttachedStructures,
            AttachedStructureRoofToBeam,
            SingleLevelAttachedStructureBeamToColumn,
            SingleLevelAttachedStructureColumnToFoundation,
            MultiLevelAttachedStructureRoofBeamToUpperLevelColumns,
            MultiLevelAttachedStructureUpperLevelColumnToLowerLevelColumnOrMiddleFloorBeams,
            MultiLevelAttachedStructureMiddleFloorBeamToLowerLevelColumn,
            MultiLevelAttachedStructureLowerLevelColumnToFoundation,
            DesignPressureRatedOpenings,
            DoWindowPressureRatingsMeetOrExceed,
            DoEntryDoorPressureRatingsMeetOrExceed,
            AdditionalDesignPressureRatingDocumentationForOpenings,
            DoSkylightsDesignPressuresMeetOrExceed,
            WallSheathing,
            WallSheathingTypeAndThickness,
            ContinuousLoadPath,
            RoofToWallConnection,
            WallAboveToWallBelowConnection,
            LowerWallToFoundationConnection,
            HoldDownsTensionTiesAtBuildingCorners,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeStandardEvaluationSilverGoldComplianceGeneralInformationForm;
