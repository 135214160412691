import { ExistingResource } from "../../../../foritfied/types/evaluation/ExistingResource";
import { BaseEvaluation } from "../../../Common/types/Evaluation/BaseEvaluation";

export interface NewEvaluation_CommercialEvaluation extends BaseEvaluation {
  commercialEvaluation?: CommercialEvaluation;
}

export type Evaluation_CommercialEvaluation =
  ExistingResource<NewEvaluation_CommercialEvaluation>;

export interface CommercialEvaluation {
  id: string;
  evaluationId: string;
  commercialEvaluationApplicationForm?: CommercialEvaluationApplicationForm;
  commercialEvaluationComplianceForm?: CommercialEvaluationComplianceForm;
  commercialEvaluationCompanyAssignmentForm?: CommercialEvaluationCompanyAssignmentForm;
  commercialEvaluationFieldInspectionForm?: CommercialEvaluationFieldInspectionForm;
  commercialEvaluationComplianceLetterForm?: CommercialEvaluationComplianceLetterForm;

  //Complex Commercial Evaluation / Parent Child
  isComplexCommercialEvaluationBuildingFormAbleToBeModified: boolean;
  isFCFMFPaymentStep: boolean;
  isComplexCommercialEvaluationAndNeedToFillOutBuildings: boolean;
}

export interface NewComplexCommercialEvaluationBuildingV2
  extends NewEvaluation_CommercialEvaluation {}

export type Evaluation_CommercialEvaluationBuildingV2 =
  ExistingResource<NewComplexCommercialEvaluationBuildingV2>;

export interface CommercialEvaluationComplianceLetterForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  commercialEvaluationComplianceLetterRequiredFieldcount: number;
  isComplianceLetterComplete: boolean;
}

export interface CommercialEvaluationComplianceForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  commercialEvaluationComplianceFormRequiredFieldcount: number;
  isComplianceFormComplete: boolean;
}

export interface CommercialEvaluationApplicationForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  woodFrameRoofEligibilityAcknowledgment?: boolean;
  occupancyType?: number;
  fortifiedCommercialStandardsFamiliarityConfirmation?: boolean;
}

export interface CommercialEvaluationCompanyAssignmentForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  evaluatorCompany?: number;
  isEvaluationCompanyAssignmentFormCompleted: boolean;
}

export interface CommercialEvaluationFieldInspectionForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  evaluationFieldInspectionFormFieldCount: number;
  isFileUploadersComplete: boolean;
}

type CommercialFormFieldKeyTypes =
  | "CommercialEvaluationApplicationForm"
  | "CommercialEvaluationCompanyAssignmentForm"
  | "CommercialEvaluationComplianceForm"
  | "CommercialEvaluationFieldInspectionForm"
  | "CommercialEvaluationComplianceLetterForm";

type CommercialForm = {
  fieldKey: CommercialFormFieldKeyTypes;
  displayName: string;
};

type CommercialFormNameTypes = {
  ApplicationForm: CommercialForm;
  EvaluatorAssignmentForm: CommercialForm;
  ComplianceForm: CommercialForm;
  FieldInspectionForm: CommercialForm;
  ComplianceLetterForm: CommercialForm;
};

export const CommercialFormNameTypes: CommercialFormNameTypes = {
  ApplicationForm: {
    fieldKey: "CommercialEvaluationApplicationForm",
    displayName: "Application Form",
  },
  EvaluatorAssignmentForm: {
    fieldKey: "CommercialEvaluationCompanyAssignmentForm",
    displayName: "Evaluator Assignment Form",
  },
  ComplianceForm: {
    fieldKey: "CommercialEvaluationComplianceForm",
    displayName: "Compliance Form",
  },
  FieldInspectionForm: {
    fieldKey: "CommercialEvaluationFieldInspectionForm",
    displayName: "Field Inspection Form",
  },
  ComplianceLetterForm: {
    fieldKey: "CommercialEvaluationComplianceLetterForm",
    displayName: "Compliance Letter Form",
  },
};
