import { Classes, Dialog } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { evaluationApiClient } from "../../lib/apiClients/evaluation/evaluationApiClient";
import { dialogsViewerStore } from "../../stores/DialogsViewerStore";
import { fortifiedGridStore } from "../../stores/FortifiedGridStore";
import { DialogFooter } from "./DialogFooter";
import { BaseDialogProps } from "./types";
import { MinimalSelectableLabel } from "../Fields/SelectableLabel/MinimalSelectableLabel";
import { RichTextEditorField, RichTextEditorValueType } from "@ucl/library";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";

export interface EvaluatorNotesDialogProps extends BaseDialogProps {}

export const EvaluatorNotesDialog: React.FC<EvaluatorNotesDialogProps> = ({
  isOpen,
  closeDialog,
  submitDialog,
}) => {
  const [localNote, setLocalNote] = useState<string>();

  useEffect(() => {
    if (!!dialogsViewerStore.evaluatorNotesDialogOpenOptions) {
      setLocalNote(
        dialogsViewerStore.evaluatorNotesDialogOpenOptions.evaluatorNotes
      );
    }
    return () => setLocalNote(localNote);
  }, [dialogsViewerStore.evaluatorNotesDialogOpenOptions]);

  return (
    <Dialog
      isOpen={isOpen}
      hasBackdrop={false}
      isCloseButtonShown={true}
      title={"Evaluator Notes"}
      onClose={closeDialog}
      className="evaluator-notes-dialog"
    >
      <div className={Classes.DIALOG_BODY}>
        <MinimalSelectableLabel label="This note is only visible to you." />
        <RichTextEditorField
          className="notes-textarea"
          valueType={RichTextEditorValueType.html}
          value={localNote}
          onSubmit={(value) => {
            setLocalNote(value ?? "");
          }}
          richTextEditorSettings={{
            height: "200px",
            enableHtmlSanitizer: true,
            autoSaveOnIdle: true,
            saveInterval: 50,
            toolbarSettings: {
              items: [
                "OrderedList",
                "UnorderedList",
                "Bold",
                "Underline",
                "Italic",
                "StrikeThrough",
                "Formats",
                "ClearFormat",
              ],
            } as ToolbarSettingsModel,
            pasteCleanupSettings: {
              prompt: false,
              plainText: false,
              keepFormat: true,
              allowedStyleProps: [""],
              deniedAttrs: ["class", "id", "style"],
            },
          }}
        />
      </div>
      <DialogFooter
        closeButtonText="Cancel"
        submitButtonText="Save"
        closeDialog={closeDialog}
        submitDialog={async () => {
          if (!!dialogsViewerStore.evaluatorNotesDialogOpenOptions) {
            await evaluationApiClient
              .updateEvaluatorNotes(
                dialogsViewerStore.evaluatorNotesDialogOpenOptions.evaluationId,
                { evaluatorNotes: localNote }
              )
              .then(() => {
                fortifiedGridStore.refresh();
                submitDialog();
              })
              .finally(() => {
                closeDialog();
              });
          }
        }}
      />
    </Dialog>
  );
};
