import { showSuccessAppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { permissionStore } from "../../../../../foritfied/stores/PermissionStore";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { SendToFieldInspectionsButton } from "../../../common/components/Button/SendToFieldInspections/SendToFieldInspectionsButton";
import { multifamilyRedesignationAPIClient } from "../../lib/apiClients/multifamilyRedesignationAPIClient";
import { Evaluation_MultifamilyRedesignation } from "../../types/MultifamilyRedesignation";
import { Button, Icon, Intent } from "@blueprintjs/core";
import { dialogsViewerStore } from "../../../../../foritfied/stores/DialogsViewerStore";
import { IconNames } from "@blueprintjs/icons";
import RequestChangesButton from "../../../common/components/Button/RequestChanges/RequestChangesActionButton";
import { evaluationStore } from "../../../../../foritfied/stores/EvaluationStore";
import ResubmitEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/ResubmitEvaluationActionButton";
import ClaimEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/ClaimEvaluationActionButton";
import { EvaluationLabel } from "../../../../../foritfied/components/Evaluation/EvaluationActions/EvaluationLabel";
import SendForReviewButton from "../../../common/components/Button/SendForReview/SendForReviewActionButton";
import { SendToIBHSReviewButton } from "../../../common/components/Button/SendToIBHSReview/SendToIBHSReviewButton";
import { SendToComplianceLetterButton } from "../../../common/components/Button/SendToComplianceLetter/SendToComplianceLetterButton";
import DownloadCertificateActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/DownloadCertificateActionButton";
import ViewRejectionReasonsActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/ViewRejectionReasonsActionButton";
import PostDesignationFilesActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/PostDesignationFilesActionButton";
import WithdrawEvaluationActionButton from "../../../../../foritfied/components/Evaluation/EvaluationActions/WithdrawEvaluationActionButton";
import {
  MessageComponent,
  Severity,
} from "@syncfusion/ej2-react-notifications";
import { ChangeRequestV2 } from "../../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";

interface MultifamilyRedesignationIterationEngineHeaderProps {
  evaluationId: string;
  evaluation: Evaluation_MultifamilyRedesignation;
  setEvaluation: (evaluation: Evaluation_MultifamilyRedesignation) => void;
  allChangeRequestsAreResolved: boolean;
  hasNoUnaddressedChangeRequests: boolean;
  addressedAtLeastOneChangeRequest: boolean;
  isEvaluationClaimedByCurrentUser: boolean;
  refreshEvaluation: () => Promise<void>;
  longestOutstandingChangeRequest: ChangeRequestV2 | undefined;
}

const MultifamilyRedesignationIterationEngineHeader: React.FC<
  MultifamilyRedesignationIterationEngineHeaderProps
> = (props) => {
  const canMoveWorkflowForward =
    permissionStore.canViewSendForwardV2 &&
    props.isEvaluationClaimedByCurrentUser;

  const canSendToFieldInspections =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.EvaluatorAssignment;

  const canSendToComplianceLetter =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.FieldInspectionsSubmitted;

  const canSendToIBHSReview =
    canMoveWorkflowForward &&
    props.evaluation.step === EvaluationStep.EvaluatorComplianceLetterSubmitted;

  const hasFinalDetermination = props.evaluation.hasFinalDetermination === true;

  const canSendForReview =
    props.addressedAtLeastOneChangeRequest &&
    props.hasNoUnaddressedChangeRequests &&
    permissionStore.canViewSendForReview;

  const canViewAuditorButtons = () => {
    if (hasFinalDetermination) {
      return false;
    }
    switch (props.evaluation.step) {
      case EvaluationStep.EligibilityReview:
      case EvaluationStep.FinalReview:
        return (
          fortifiedUserStore.hasRole("FC/FMF Auditor") ||
          fortifiedUserStore.hasRole("FH Auditor") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      case EvaluationStep.ComplianceFormSubmitted:
      case EvaluationStep.FieldInspectionsSubmitted:
        return (
          fortifiedUserStore.hasRole("FC/FMF Evaluator") ||
          fortifiedUserStore.hasRole("IBHS Admin") ||
          fortifiedUserStore.hasRole("Relativity Admin") ||
          fortifiedUserStore.hasRole("FC/FMF Admin")
        );
      default:
        return false;
    }
  };

  const canAudit =
    props.isEvaluationClaimedByCurrentUser && canViewAuditorButtons();

  const afterAction = async (
    evaluation: Evaluation_MultifamilyRedesignation
  ) => {
    props.setEvaluation(evaluation);
  };

  const shouldShowBanner =
    props.hasNoUnaddressedChangeRequests &&
    evaluationStore.shouldShowAutoRejectBanner;

  return (
    <div className="wildfire-iteration-engine__header">
      <div className="left-panel">
        {canSendToFieldInspections && (
          <SendToFieldInspectionsButton
            evaluationId={props.evaluationId}
            isDisabled={
              !props.evaluation.multifamilyRedesignation
                ?.redesignationEvaluatorEmailForm?.areAllRequiredFieldsComplete
            }
            sendToFieldInspections={async () => {
              await multifamilyRedesignationAPIClient
                .moveToFieldInspectionsStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster(
                    "Evaluation moved to Field Inspections"
                  );
                });
            }}
          />
        )}
        {canSendToComplianceLetter && (
          <SendToComplianceLetterButton
            evaluationId={props.evaluationId}
            isDisabled={
              !props.evaluation.multifamilyRedesignation?.fieldInspectionForm
                ?.isFormComplete
            }
            sendToComplianceLetter={async () => {
              await multifamilyRedesignationAPIClient
                .moveToComplianceLetterStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster(
                    "Evaluation moved to Compliance Letter"
                  );
                });
            }}
          />
        )}
        {canSendToIBHSReview && (
          <SendToIBHSReviewButton
            evaluationId={props.evaluationId}
            isDisabled={
              !props.evaluation.multifamilyRedesignation?.complianceLetterForm
                ?.isFormComplete
            }
            sendToIBHSReview={async () => {
              await multifamilyRedesignationAPIClient
                .moveToIBHSReviewStep(props.evaluationId)
                .then((evaluation) => {
                  afterAction(evaluation);
                  showSuccessAppToaster("Evaluation moved to IBHS Review");
                });
            }}
          />
        )}
      </div>
      <div className="right-panel">
        {shouldShowBanner && props.longestOutstandingChangeRequest && (
          <MessageComponent
            className="message"
            content={`Evaluation will auto-reject in ${
              props.longestOutstandingChangeRequest
                .daysTillEligibleForAutoRejection + 1
            } days, if all changes are not addressed`}
            severity={Severity.Warning}
            showCloseIcon={true}
          />
        )}
        {permissionStore.canWithdraw(evaluationStore.evaluation) &&
          !hasFinalDetermination && <WithdrawEvaluationActionButton />}
        {props.evaluation.approvedAt && <DownloadCertificateActionButton />}
        {props.evaluation.isApproved === false && (
          <ViewRejectionReasonsActionButton />
        )}
        {props.evaluation.isApproved && (
          <PostDesignationFilesActionButton
            evaluationId={props.evaluation?.id}
            rootBoxFolderId={props.evaluation?.externalFolderId}
            postDesignationBoxFolderId={
              evaluationStore.evaluation?.postDesignationBoxFolderId
            }
          />
        )}
        {evaluationStore.canResubmit && <ResubmitEvaluationActionButton />}
        {permissionStore.canClaimEvaluations && (
          <ClaimEvaluationActionButton
            afterClaimed={props.refreshEvaluation}
            isEvaluationClaimedByCurrentUser={
              props.isEvaluationClaimedByCurrentUser
            }
          />
        )}
        {!evaluationStore.doesEvaluationHaveFinalDetermination &&
          props.evaluation.step &&
          fortifiedUserStore.displayRole && (
            <EvaluationLabel
              step={props.evaluation.step}
              role={fortifiedUserStore.displayRole}
              canViewPendingLabels={permissionStore.canViewPendingLabels}
            />
          )}
        <SendForReviewButton
          shouldDisplaySendForReview={permissionStore.canViewSendForReview}
          isSendForReviewDisabled={!canSendForReview}
          sendForReview={async () =>
            await multifamilyRedesignationAPIClient.sendChangesForReviewEmail(
              props.evaluationId
            )
          }
        />
        {canAudit && (
          <>
            {props.evaluation.step == EvaluationStep.FinalReview && (
              <Button
                text="Approve"
                intent={Intent.SUCCESS}
                icon={IconNames.TICK_CIRCLE}
                disabled={!props.allChangeRequestsAreResolved}
                minimal
                onClick={() => {
                  dialogsViewerStore.setIsAuditorApprovalDialogOpen(true, {
                    afterApproval: props.refreshEvaluation,
                  });
                }}
              />
            )}

            <RequestChangesButton
              isRequestChangesDisabled={props.hasNoUnaddressedChangeRequests}
              requestChanges={async () =>
                await multifamilyRedesignationAPIClient.sendRequestedChangesEmail(
                  props.evaluationId
                )
              }
            />

            <Button
              text="Reject"
              intent={Intent.DANGER}
              icon={IconNames.DELETE}
              minimal
              onClick={() =>
                dialogsViewerStore.setIsAuditorRejectionDialogOpen(true, {
                  afterRejection: props.refreshEvaluation,
                })
              }
              disabled={!props.isEvaluationClaimedByCurrentUser}
            />
          </>
        )}
        {permissionStore.canViewAdminButton && (
          <Button
            minimal
            large
            onClick={() => {
              dialogsViewerStore.setIsAdminDialogOpen(true, {
                evaluationId: props.evaluationId,
              });
            }}
          >
            <Icon icon={IconNames.COG} size={16} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default MultifamilyRedesignationIterationEngineHeader;
