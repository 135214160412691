import { ReportReadDTO } from "../../../common/types/reports";
import wildfireBaseAPIClient from "../wildfireBaseApiClient";

class WildfireReportApiClient {
  getReports = async (): Promise<ReportReadDTO[]> => {
    return await wildfireBaseAPIClient.get<ReportReadDTO[]>(`wildfire/reports`);
  };

  getReport = async (slug: string): Promise<ReportReadDTO> => {
    return await wildfireBaseAPIClient.get<ReportReadDTO>(
      `wildfire/reports/${slug}`
    );
  };
}

export const wildfireReportApiClient = new WildfireReportApiClient();
