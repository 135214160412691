import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineerConfirmationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const engineerConfirmationFields = () => [
  buildFieldKey("CertifiedLicensedProfessionalEngineerClaim"),
  buildFieldKey("UnderstandFalseInformationSubjectToCriminalPenaltiesClaim"),
  buildFieldKey("Company"),
  buildFieldKey("PhoneNumber"),
  buildFieldKey("LicenseNumber"),
];

const engineerConfirmationAddressDetailsFields = () => [
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,
];

const engineerConfirmationFieldsFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("SignedAndSealedEngineeringComplianceForms"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SignedAndSealedEngineeringComplianceForms.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const engineerConfirmationRequiredFields = [
  buildFieldKey("CertifiedLicensedProfessionalEngineerClaim"),
  buildFieldKey("UnderstandFalseInformationSubjectToCriminalPenaltiesClaim"),
  buildFieldKey("Company"),
  buildFieldKey("PhoneNumber"),
  buildFieldKey("Address"),
  buildFieldKey("LicenseNumber"),
  buildFieldKey("SignedAndSealedEngineeringComplianceForms"),
];

export const engineerConfirmationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...engineerConfirmationFields(),
          ...engineerConfirmationAddressDetailsFields(),
          ...engineerConfirmationFieldsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        engineerConfirmationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const engineerConfirmationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...engineerConfirmationFields(),
          ...engineerConfirmationAddressDetailsFields(),
          ...engineerConfirmationFieldsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        engineerConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const engineerConfirmationFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...engineerConfirmationFields(),
          ...engineerConfirmationAddressDetailsFields(),
          ...engineerConfirmationFieldsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        engineerConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
