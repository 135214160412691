import {
  AddressFieldValue,
  EnumRadioFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationEngineerConfirmationFormParts {
  EngineerConfirmation = "engineer-confirmation",
}

export interface BaseHomeEvaluationEngineerConfirmationForm
  extends IEngineerConfirmation {}

export interface IEngineerConfirmation {
  CertifiedLicensedProfessionalEngineerClaim: EnumRadioFieldValue;
  UnderstandFalseInformationSubjectToCriminalPenaltiesClaim: EnumRadioFieldValue;
  Company: TextFieldValue;
  PhoneNumber: TextFieldValue;
  Address: AddressFieldValue;
  LicenseNumber: TextFieldValue;
  SignedAndSealedEngineeringComplianceForms: WildfireBoxContentExplorerValue;
}
