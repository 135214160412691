import { Navigate, createBrowserRouter } from "react-router-dom";
import { WithParams, utils } from "@ucl/library";
import ErrorWrapper from "../../../common/Components/Error/ErrorWrapper";
import { WildfireBoardPage } from "../WildfireBoardPage";
import { WildfirePagesLayout } from "./WildfirePagesLayout";
import {
  faChartSimple,
  faList,
  faSliders,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { logOutWildfireUser } from "../../../common/lib/msal/wildfireMsal";
import ApplicationFormPage from "../forms/ApplicationFormPage";
import { ApplicationFormParts } from "../../forms/ApplicationForm/ApplicationFormModel";
import { WildfireAdminPage } from "../WildfireAdminPage";
import { WildfireIterationEnginePage } from "../WildfireIterationEnginePage";
import FieldEvaluationFormPage from "../forms/FieldEvaluationFormPage";
import { RoofFieldEvaluationFormParts } from "../../forms/RoofFieldEvaluationForm/RoofFieldEvaluationFormModel";
import { LandscapeFieldEvaluationFormParts } from "../../forms/LandscapeFieldEvaluationForm/LandscapeFieldEvaluationFormModel";
import { HomeExteriorFieldEvaluationFormParts } from "../../forms/HomeExteriorFieldEvaluationForm/HomeExteriorFieldEvaluationFormModel";
import { OtherFieldEvaluationFormParts } from "../../forms/OtherFieldEvaluationForm/OtherFieldEvaluationFormModel";
import { WildfireReportsPage } from "../WildfireReportsPage";
import { WildfireReportViewerPage } from "../reports/WildfireReportViewerPage";
import { UnSavedChangesBlockerProvider } from "../../components/Provider/UnsavedChangesBlockerContext";

const errorWrapper = (
  <div className="wildfire-page">
    <ErrorWrapper logOutUser={logOutWildfireUser} />
  </div>
);
export const wildfireRoutePrefix = "/wfp";

export const uclRouteObject = (
  hasPermission: (permission: PermissionsKey) => boolean
): utils.routes.UclRouteObject[] => {
  return [
    {
      path: "/",
      label: "Home",
      isAvailable: true,
      showInNavigation: false,
      errorElement: errorWrapper,
      element: <Navigate to={`${wildfireRoutePrefix}/board`} replace />,
    },
    {
      path: `${wildfireRoutePrefix}`,
      label: "Board",
      icon: faList,
      isAvailable: hasPermission("CanViewBoardPanel"),
      showInNavigation: hasPermission("CanViewBoardPanel"),
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <WildfirePagesLayout redirectPath={`${wildfireRoutePrefix}/board`} />
      ),
      children: [
        {
          path: "board",
          label: "",
          isAvailable: hasPermission("CanViewBoardPanel"),
          showInNavigation: false,
          showInSubNavigation: true,
          element: <WildfireBoardPage />,
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${wildfireRoutePrefix}/reports`,
      label: "Reports",
      icon: faChartSimple,
      isAvailable: hasPermission("CanViewReportsPanel"),
      showInNavigation: hasPermission("CanViewReportsPanel"),
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <WildfirePagesLayout redirectPath={`${wildfireRoutePrefix}/reports`} />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: hasPermission("CanViewReportsPanel"),
          showInNavigation: false,
          showInSubNavigation: false,
          element: <WildfireReportsPage />,
          errorElement: errorWrapper,
        },
        {
          path: ":slug",
          label: "",
          isAvailable: hasPermission("CanViewReportsPanel"),
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              slug: string;
            }>>
              {({ slug }) => <WildfireReportViewerPage slug={slug} />}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${wildfireRoutePrefix}/admin`,
      label: "Admin",
      icon: faSliders,
      isAvailable: hasPermission("CanViewAdminPanel"),
      showInNavigation: hasPermission("CanViewAdminPanel"),
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <WildfirePagesLayout redirectPath={`${wildfireRoutePrefix}/admin`} />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: hasPermission("CanViewAdminPanel"),
          showInNavigation: false,
          showInSubNavigation: false,
          element: <WildfireAdminPage />,
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${wildfireRoutePrefix}/:wildfireEvaluationId`,
      label: "Forms",
      icon: faSquare,
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <WildfirePagesLayout redirectPath={`${wildfireRoutePrefix}/board`} />
      ),
      children: [
        {
          path: "application-form",
          label: "Application Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
            }>>
              {({ wildfireEvaluationId }) => (
                <UnSavedChangesBlockerProvider>
                  <ApplicationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
        },
        {
          path: "application-form/:applicationFormPart",
          label: "Application Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
              applicationFormPart: ApplicationFormParts;
            }>>
              {({ wildfireEvaluationId, applicationFormPart }) => (
                <UnSavedChangesBlockerProvider>
                  <ApplicationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                    applicationFormPart={applicationFormPart}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "roof-field-evaluation-form",
          label: "Roof Field Evaluation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
            }>>
              {({ wildfireEvaluationId }) => (
                <UnSavedChangesBlockerProvider>
                  <FieldEvaluationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
        },
        {
          path: "roof-field-evaluation-form/:roofFieldEvaluationFormPart",
          label: "Roof Field Evaluation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
              roofFieldEvaluationFormPart: RoofFieldEvaluationFormParts;
            }>>
              {({ wildfireEvaluationId, roofFieldEvaluationFormPart }) => (
                <UnSavedChangesBlockerProvider>
                  <FieldEvaluationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                    roofFormPart={roofFieldEvaluationFormPart}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "home-exterior-field-evaluation-form/:homeExteriorFieldEvaluationFormPart",
          label: "Home Exterior Field Evaluation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
              homeExteriorFieldEvaluationFormPart: HomeExteriorFieldEvaluationFormParts;
            }>>
              {({
                wildfireEvaluationId,
                homeExteriorFieldEvaluationFormPart,
              }) => (
                <UnSavedChangesBlockerProvider>
                  <FieldEvaluationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                    homeExteriorFormPart={homeExteriorFieldEvaluationFormPart}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "landscape-field-evaluation-form",
          label: "Landscape Field Evaluation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
            }>>
              {({ wildfireEvaluationId }) => (
                <UnSavedChangesBlockerProvider>
                  <FieldEvaluationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
        },
        {
          path: "landscape-field-evaluation-form/:landscapeFieldEvaluationFormPart",
          label: "Landscape Field Evaluation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
              landscapeFieldEvaluationFormPart: LandscapeFieldEvaluationFormParts;
            }>>
              {({ wildfireEvaluationId, landscapeFieldEvaluationFormPart }) => (
                <UnSavedChangesBlockerProvider>
                  <FieldEvaluationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                    landscapeFormPart={landscapeFieldEvaluationFormPart}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
        {
          path: "other-field-evaluation-form",
          label: "Other Field Evaluation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
            }>>
              {({ wildfireEvaluationId }) => (
                <UnSavedChangesBlockerProvider>
                  <FieldEvaluationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
        },
        {
          path: "other-field-evaluation-form/:otherFieldEvaluationFormPart",
          label: "Other Field Evaluation Form",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              wildfireEvaluationId: string;
              otherFieldEvaluationFormPart: OtherFieldEvaluationFormParts;
            }>>
              {({ wildfireEvaluationId, otherFieldEvaluationFormPart }) => (
                <UnSavedChangesBlockerProvider>
                  <FieldEvaluationFormPage
                    wildfireEvaluationId={wildfireEvaluationId || ""}
                    otherFormPart={otherFieldEvaluationFormPart}
                  />
                </UnSavedChangesBlockerProvider>
              )}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
    {
      path: `${wildfireRoutePrefix}/iteration-engine/:evaluationId`,
      label: "Iteration Engine",
      isAvailable: true,
      showInNavigation: false,
      showInSubNavigation: false,
      errorElement: errorWrapper,
      element: (
        <WildfirePagesLayout redirectPath={`${wildfireRoutePrefix}/board`} />
      ),
      children: [
        {
          path: "",
          label: "",
          isAvailable: true,
          showInNavigation: false,
          showInSubNavigation: false,
          element: (
            <WithParams<{
              evaluationId: string;
            }>>
              {({ evaluationId }) => {
                return (
                  <WildfireIterationEnginePage evaluationId={evaluationId} />
                );
              }}
            </WithParams>
          ),
          errorElement: errorWrapper,
        },
      ],
    },
  ] as utils.routes.UclRouteObject[];
};

export const wildfireBrowserRouter = (
  hasPermission: (permission: PermissionsKey) => boolean
) =>
  createBrowserRouter(uclRouteObject(hasPermission), {
    basename: process.env.REACT_APP_PATH_PREFIX,
  });
