import {
  homeSCSHBasicInformationFormBuilder,
  homeSCSHConfirmationAffirmationsBuilder,
  homeSCSHHomeownerInformationBuilder,
  homeSCSHPropertyDetailsBuilder,
} from "../../../scsh/components/Forms/BasicInformationForm/HomeSCSHBasicInformationFormBuilder";
import {
  homeSCSHEvaluationFormInformationBuilder,
  homeSCSHEvaluationInformationBuilder,
} from "../../../scsh/components/Forms/SCSHEvaluationForm/HomeSCSHEvaluationFormBuilder";

export const HomeSCSHBasicInformationForm = [
  "HomeSCSHBasicInformationForm",
  "HomeSCSHHomeownerInformation",
  "HomeSCSHPropertyDetails",
  "HomeSCSHConfirmationAffirmations",
  "HomeSCSHEvaluationInformation",
  "HomeSCSHEvaluationForm",
];

export const HomeSCSHFormsConfig = {
  HomeSCSHBasicInformationForm: undefined,
  HomeSCSHHomeownerInformation: undefined,
  HomeSCSHPropertyDetails: undefined,
  HomeSCSHConfirmationAffirmations: undefined,
  HomeSCSHEvaluationInformation: undefined,
  HomeSCSHEvaluationForm: undefined,
};

export const HomeSCSHBuilders = {
  HomeSCSHBasicInformationForm: homeSCSHBasicInformationFormBuilder,
  HomeSCSHHomeownerInformation: homeSCSHHomeownerInformationBuilder,
  HomeSCSHPropertyDetails: homeSCSHPropertyDetailsBuilder,
  HomeSCSHConfirmationAffirmations: homeSCSHConfirmationAffirmationsBuilder,
  HomeSCSHEvaluationInformation: homeSCSHEvaluationInformationBuilder,
  HomeSCSHEvaluationForm: homeSCSHEvaluationFormInformationBuilder,
};
