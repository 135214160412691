import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEngineerConfirmationFormContainerProps } from "./HomeStandardEngineerConfirmationFormContainer";
import { BaseHomeEvaluationEngineerConfirmationFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineerConfirmationForm";

function useHomeStandardEngineerConfirmationFormContainer(
  props: HomeStandardEngineerConfirmationFormContainerProps
) {
  const [commercialSchemaFactory, setCommercialSchemaFactory] =
    useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setCommercialSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!commercialSchemaFactory) {
      getSchema();
    }
    //return () => {
    //     setDefaultHeader();
    //   };
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.engineerConfirmationFormPart]);

  // const { setWildfireEvaluationHeader, setDefaultHeader } =
  //   useContext(AppHeaderContext);
  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeEvaluationEngineerConfirmationFormParts | undefined
  >(props.engineerConfirmationFormPart);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const engineerConfirmationForm =
    evaluationFormModel?.homeStandardEvaluation.engineerConfirmationForm;

  const getNextUnsubmittedFormPart = () => {
    if (!engineerConfirmationForm) {
      return null;
    }

    if (!engineerConfirmationForm.isEngineerConfirmationComplete) {
      return BaseHomeEvaluationEngineerConfirmationFormParts.EngineerConfirmation;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart =
    (): BaseHomeEvaluationEngineerConfirmationFormParts => {
      // Default to the first part of the form if we can't find a better option
      const defaultFormPart = Object.values(
        BaseHomeEvaluationEngineerConfirmationFormParts
      )[0];

      // If the application form part is provided (by URL), use it
      if (!!props.engineerConfirmationFormPart) {
        return props.engineerConfirmationFormPart;
      }

      // Otherwise, try to find the first unsubmitted part of the form
      // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
      return getNextUnsubmittedFormPart() ?? defaultFormPart;
    };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeEvaluationEngineerConfirmationFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeEvaluationEngineerConfirmationFormParts.EngineerConfirmation:
        return engineerConfirmationForm?.isEngineerConfirmationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeEvaluationEngineerConfirmationFormParts>[] =
    [
      {
        formPart:
          BaseHomeEvaluationEngineerConfirmationFormParts.EngineerConfirmation,
        displayName: "Engineer Confirmation",
        requiredFieldCount:
          engineerConfirmationForm?.EngineerConfirmationRequiredCount,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart: formPart,
    getBestFormPart,
    commercialSchemaFactory,
  };
}

export default useHomeStandardEngineerConfirmationFormContainer;
