import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { FCFMFComplianceLetterParts } from "../../../common/types/Evaluation/BaseFCFMFComplianceLetter";
import { MultifamilyRedesignationComplianceLetterFormModel } from "../../types/MultifamilyRedesignationComplianceLetterFormModel";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../types/MultifamilyRedesignationFormModel";

class MultifamilyRedesignationComplianceLetterAPIClient {
  updateCommercialComplianceLetter = async (
    evaluationId: string,
    complianceLetter: MultifamilyRedesignationComplianceLetterFormModel,
    fieldKey?: string
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    return baseApiClient.put<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-compliance-letter/${evaluationId}`,
      {
        complianceLetter,
        fieldKey,
      }
    );
  };

  submitCommercialComplianceLetter = async (
    wildfireApplicationId: string,
    complianceLetter: MultifamilyRedesignationComplianceLetterFormModel,
    complianceLetterPart?: FCFMFComplianceLetterParts
  ): Promise<Evaluation_MultifamilyRedesignationFormModel> => {
    const complianceLetterPartValue = (
      complianceLetterPart ??
      (Object.values(
        FCFMFComplianceLetterParts
      ).pop() as FCFMFComplianceLetterParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_MultifamilyRedesignationFormModel>(
      `/multifamily/redesignation-compliance-letter/${wildfireApplicationId}/${complianceLetterPartValue}`,
      complianceLetter
    );
  };
}

export const multifamilyRedesignationComplianceLetterAPIClient =
  new MultifamilyRedesignationComplianceLetterAPIClient();
