import {
  useEvaluationChangeRequest,
  UseEvaluationChangeRequestProps,
} from "../../../../../common/customHooks/IterationEngine/ChangeRequests/useEvaluationChangeRequest";
import {
  UseEvaluationCommentsProps,
  useEvaluationIterationEngineComments,
} from "../../../../../common/customHooks/IterationEngine/Comments/useEvaluationIterationEngineComments";
import { FCFMFSchema } from "../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { useEffect, useRef, useState } from "react";
import { getThirdLevelNesting } from "../../../../Common/customHooks/useFortifiedChangeRequestNavigator";
import { ChangeRequestV2 } from "../../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { fortifiedEvaluationCommentAPIClient } from "../../../../Common/lib/apiClients/fortifiedCommentAPIClient";
import { fortifiedChangeRequestApiClient } from "../../../../Common/lib/apiClients/fortifiedChangeRequestAPIClient";
import { useMultifamilyEvaluation } from "../../customHooks/useMultifamilyEvaluation";
import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { useNavigate } from "react-router";
import { permissionStore } from "../../../../../foritfied/stores/PermissionStore";
import { MultifamilyFormNameTypes } from "../../types/MultifamilyEvaluation";

export const useMultifamilyIterationEngine = (
  evaluationId: string,
  commercialSchema: FCFMFSchema
) => {
  const {
    multifamilyEvaluation,
    refreshMultifamilyEvaluation,
    setMultifamilyEvaluation,
    isApplicationLoading,
  } = useMultifamilyEvaluation(evaluationId);

  const useEvaluationIterationEngineCommentsProps: UseEvaluationCommentsProps =
    {
      getEvaluationComments:
        fortifiedEvaluationCommentAPIClient.getEvaluationComments,
      createEvaluationComments:
        fortifiedEvaluationCommentAPIClient.createEvaluationComments,
      createEvaluationComment:
        fortifiedEvaluationCommentAPIClient.createEvaluationComment,
      deleteEvaluationComment:
        fortifiedEvaluationCommentAPIClient.deleteEvaluationComment,
    };

  const {
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  } = useEvaluationIterationEngineComments(
    useEvaluationIterationEngineCommentsProps
  );

  const useEvaluationChangeRequestProps: UseEvaluationChangeRequestProps = {
    evaluationId: evaluationId,
    schema: commercialSchema,
    getChangeRequest: fortifiedChangeRequestApiClient.getChangeRequest,
    getChangeRequestsByEvaluation:
      fortifiedChangeRequestApiClient.getChangeRequestsByEvaluation,
    resolveChangeRequest: fortifiedChangeRequestApiClient.resolveChangeRequest,
    unresolveChangeRequest:
      fortifiedChangeRequestApiClient.unresolveChangeRequest,
  };

  const {
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
  } = useEvaluationChangeRequest(useEvaluationChangeRequestProps);

  const defaultTabId = MultifamilyFormNameTypes.ApplicationForm.fieldKey;

  const [
    selectedIterationEngineFormTabId,
    setSelectedIterationEngineFormTabId,
  ] = useState<string>(defaultTabId);
  const hasSetInitialTab = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (unresolvedChangeRequests.length === 0) {
      navigateToFormTab();
    }
  }, [multifamilyEvaluation]);

  const navigateToFormTab = () => {
    if (multifamilyEvaluation?.step === EvaluationStep.EvaluatorAssignment) {
      setSelectedIterationEngineFormTabId(
        MultifamilyFormNameTypes.EvaluatorAssignmentForm.fieldKey
      );
    } else if (
      multifamilyEvaluation?.step === EvaluationStep.ComplianceFormSubmitted
    ) {
      setSelectedIterationEngineFormTabId(
        MultifamilyFormNameTypes.ComplianceForm.fieldKey
      );
    } else if (
      multifamilyEvaluation?.step === EvaluationStep.FieldInspectionsSubmitted
    ) {
      setSelectedIterationEngineFormTabId(
        MultifamilyFormNameTypes.FieldInspectionForm.fieldKey
      );
    } else if (
      multifamilyEvaluation?.step ===
        EvaluationStep.EvaluatorComplianceLetterSubmitted ||
      multifamilyEvaluation?.step === EvaluationStep.FinalReview ||
      multifamilyEvaluation?.step === EvaluationStep.CertificateFee
    ) {
      setSelectedIterationEngineFormTabId(
        MultifamilyFormNameTypes.ComplianceLetterForm.fieldKey
      );
    }
  };

  useEffect(() => {
    if (permissionStore.shouldNavigateToBidsPage) {
      navigate(`/fc-fmf/bid/${evaluationId}`);
    }
  }, [multifamilyEvaluation?.step]);

  useEffect(() => {
    if (unresolvedChangeRequests.length === 0 || hasSetInitialTab.current) {
      return;
    }

    const initialTab = getInitialTabFromChangeRequests(
      unresolvedChangeRequests
    );

    setSelectedIterationEngineFormTabId(initialTab);
    hasSetInitialTab.current = true;
  }, [allChangeRequests]);

  const getInitialTabFromChangeRequests = (
    unresolvedChangeRequests: ChangeRequestV2[]
  ): string => {
    return unresolvedChangeRequests.length > 0
      ? getThirdLevelNesting(unresolvedChangeRequests[0].evaluationFieldKey) ||
          defaultTabId
      : defaultTabId;
  };

  const canEditForm = fortifiedUserStore.IsFCFMFAdmin;

  const stepOrder: Record<EvaluationStep, number> = {
    [EvaluationStep.Application]: 1,
    [EvaluationStep.ApplicationFee]: 2,
    [EvaluationStep.EligibilityReview]: 3,
    [EvaluationStep.EvaluatorAssignment]: 4,
    [EvaluationStep.ComplianceFormSubmitted]: 5,
    [EvaluationStep.FieldInspectionsSubmitted]: 6,
    [EvaluationStep.EvaluatorComplianceLetterSubmitted]: 7,
    [EvaluationStep.FinalReview]: 8,
    [EvaluationStep.CertificateFee]: 9,
    [EvaluationStep.ApplicationForms]: 10,
    [EvaluationStep.Payment]: 11,
  };

  // Helper function to check if a tab should be disabled
  const isTabDisabled = (
    requiredStep: EvaluationStep,
    currentStep: EvaluationStep
  ): boolean => stepOrder[currentStep] < stepOrder[requiredStep];

  // Define the current step once for reuse
  const currentStep = multifamilyEvaluation?.step || EvaluationStep.Application;

  // Map baseTabs with conditional `disabled` property
  const baseTabs = [
    {
      id: MultifamilyFormNameTypes.ApplicationForm.fieldKey,
      label: MultifamilyFormNameTypes.ApplicationForm.displayName,
      requiredStep: EvaluationStep.Application,
    },
    {
      id: MultifamilyFormNameTypes.EvaluatorAssignmentForm.fieldKey,
      label: MultifamilyFormNameTypes.EvaluatorAssignmentForm.displayName,
      requiredStep: EvaluationStep.EvaluatorAssignment,
    },
    {
      id: MultifamilyFormNameTypes.ComplianceForm.fieldKey,
      label: MultifamilyFormNameTypes.ComplianceForm.displayName,
      requiredStep: EvaluationStep.ComplianceFormSubmitted,
    },
    {
      id: MultifamilyFormNameTypes.FieldInspectionForm.fieldKey,
      label: MultifamilyFormNameTypes.FieldInspectionForm.displayName,
      requiredStep: EvaluationStep.FieldInspectionsSubmitted,
    },
    {
      id: MultifamilyFormNameTypes.ComplianceLetterForm.fieldKey,
      label: MultifamilyFormNameTypes.ComplianceLetterForm.displayName,
      requiredStep: EvaluationStep.EvaluatorComplianceLetterSubmitted,
    },
  ].map((tab) => ({
    ...tab,
    disabled: isTabDisabled(tab.requiredStep, currentStep),
  }));

  // Conditionally add complex evaluation tabs
  const formTabs = [...baseTabs];

  const parentFieldKey = "Evaluation__MultifamilyEvaluation";

  const evaluationNonPaymentSteps = [
    {
      id: EvaluationStep.Application,
      label: "Application",
    },
    {
      id: EvaluationStep.EligibilityReview,
      label: "IBHS Eligibility Review",
    },
    {
      id: EvaluationStep.EvaluatorAssignment,
      label: "Evaluator Assignment",
    },
    {
      id: EvaluationStep.ComplianceFormSubmitted,
      label: "Compliance Forms",
    },
    {
      id: EvaluationStep.FieldInspectionsSubmitted,
      label: "Field Inspections",
    },
    {
      id: EvaluationStep.EvaluatorComplianceLetterSubmitted,
      label: "Compliance Letter",
    },
    {
      id: EvaluationStep.FinalReview,
      label: "IBHS Review",
    },
  ];

  const evaluationPaymentSteps = [
    {
      id: EvaluationStep.Application,
      label: "Application",
    },
    {
      id: EvaluationStep.ApplicationFee,
      label: "Application Fee",
    },
    {
      id: EvaluationStep.EligibilityReview,
      label: "IBHS Eligibility Review",
    },
    {
      id: EvaluationStep.EvaluatorAssignment,
      label: "Evaluator Assignment",
    },
    {
      id: EvaluationStep.ComplianceFormSubmitted,
      label: "Compliance Forms",
    },
    {
      id: EvaluationStep.FieldInspectionsSubmitted,
      label: "Field Inspections",
    },
    {
      id: EvaluationStep.EvaluatorComplianceLetterSubmitted,
      label: "Compliance Letter",
    },
    {
      id: EvaluationStep.FinalReview,
      label: "IBHS Review",
    },
    {
      id: EvaluationStep.CertificateFee,
      label: "Certificate Fee",
    },
  ];

  const evaluationSteps = multifamilyEvaluation?.hasFCFMFPaymentWorkflow
    ? evaluationPaymentSteps
    : evaluationNonPaymentSteps;

  const fieldsToEnable = () => {
    switch (multifamilyEvaluation?.step) {
      case EvaluationStep.EvaluatorAssignment:
        return [
          `${parentFieldKey}__MultifamilyEvaluationCompanyAssignmentForm__EvaluatorCompany`,
        ];
      case EvaluationStep.ComplianceFormSubmitted:
        return [
          `${parentFieldKey}__MultifamilyEvaluationComplianceForm__ComplianceFormPhotoUploader`,
        ];
      case EvaluationStep.FieldInspectionsSubmitted:
        if (fortifiedUserStore.user?.role === "FC/FMF Evaluator") {
          return [
            `${parentFieldKey}__MultifamilyEvaluationFieldInspectionForm__FieldInspectionPhotos`,
          ];
        } else {
          return [];
        }
      case EvaluationStep.EvaluatorComplianceLetterSubmitted:
        if (fortifiedUserStore.user?.role === "FC/FMF Evaluator") {
          return [
            `${parentFieldKey}__MultifamilyEvaluationComplianceLetterForm__ComplianceLetterPhotoUploader`,
          ];
        } else {
          return [];
        }
      default:
        [];
    }
    return [];
  };

  return {
    //Evaluation
    multifamilyEvaluation,
    refreshMultifamilyEvaluation,
    setMultifamilyEvaluation,
    isApplicationLoading,
    //Comments
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
    defaultTabId,
    fieldsToEnable,
    evaluationSteps,
  };
};
