import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "InstallerComplianceAttachedStructureForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form General Information
const installerComplianceFormGeneralInformationFields = () => [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

const installerComplianceFormGeneralInformationRequiredFields = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("DateInstallationWasCompleted"),
];

export const installerComplianceFormAttachedStructureGeneralInformationBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [...installerComplianceFormGeneralInformationFields()],
          onValueChange,
          form,
          errors,
          installerComplianceFormGeneralInformationRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Part 2: Installer Compliance Form Engineered Attached Structure
const installerComplianceFormEngineeredAttachedStructureFields = () => [
  buildFieldKey("Attestation298"),
  buildFieldKey("Attestation299"),
  buildFieldKey("Attestation3002"),
  buildFieldKey("Attestation3012"),
  buildFieldKey("Attestation3022"),
  buildFieldKey("Attestation303"),
  buildFieldKey("Attestation304"),
  buildFieldKey("Attestation305"),
  buildFieldKey("Attestation306"),
  buildFieldKey("Attestation307"),
  buildFieldKey("Attestation308"),
];

const installerComplianceFormEngineeredAttachedStructureRequiredFields = [
  buildFieldKey("Attestation298"),
  buildFieldKey("Attestation299"),
  buildFieldKey("Attestation3002"),
  buildFieldKey("Attestation3012"),
  buildFieldKey("Attestation3022"),
  buildFieldKey("Attestation303"),
  buildFieldKey("Attestation304"),
  buildFieldKey("Attestation305"),
  buildFieldKey("Attestation306"),
  buildFieldKey("Attestation307"),
  buildFieldKey("Attestation308"),
];

export const installerComplianceFormEngineeredAttachedStructureBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [...installerComplianceFormEngineeredAttachedStructureFields()],
        onValueChange,
        form,
        errors,
        installerComplianceFormEngineeredAttachedStructureRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 3: Installer Compliance Form Prescriptive Attached Structure
const installerComplianceFormPrescriptiveAttachedStructureFields = () => [
  buildFieldKey("AreSingleLevelAttachedStructuresRetrofittedPerSection5Dot8"),
  buildFieldKey("Attestation309"),
  buildFieldKey("UpliftLoadForInsideColumnToFoundation"),
  buildFieldKey("UpliftLoadForInsideColumnToBeam"),
  buildFieldKey("UpliftLoadForCornerColumnToFoundation"),
  buildFieldKey("UpliftLoadForCornerColumnToBeam"),
  buildFieldKey("UpliftLoadForEachRoofMemberToBeam"),
  buildFieldKey("ManufacturerConnectorSelectedForInsideColumnToFoundation"),
  buildFieldKey("ManufacturerConnectorSelectedForInsideColumnToBeam"),
  buildFieldKey("ManufacturerConnectorSelectedForCornerColumnToFoundation"),
  buildFieldKey("ManufacturerConnectorSelectedForCornerColumnToBeam"),
  buildFieldKey("ManufacturerConnectorSelectedForEachRoofMemberToBeam"),
  buildFieldKey("Attestation320"),
];

const prescriptiveAttachedStructureFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey(
      "SingleLevelPrescriptiveAttachedStructureLoadsDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SingleLevelPrescriptiveAttachedStructureLoadsDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const installerComplianceFormPrescriptiveAttachedStructureRequiredFields = [
  buildFieldKey("AreSingleLevelAttachedStructuresRetrofittedPerSection5Dot8"),
  buildFieldKey("Attestation309"),
  buildFieldKey("UpliftLoadForInsideColumnToFoundation"),
  buildFieldKey("UpliftLoadForInsideColumnToBeam"),
  buildFieldKey("UpliftLoadForCornerColumnToFoundation"),
  buildFieldKey("UpliftLoadForCornerColumnToBeam"),
  buildFieldKey("UpliftLoadForEachRoofMemberToBeam"),
  buildFieldKey("ManufacturerConnectorSelectedForInsideColumnToFoundation"),
  buildFieldKey("ManufacturerConnectorSelectedForInsideColumnToBeam"),
  buildFieldKey("ManufacturerConnectorSelectedForCornerColumnToFoundation"),
  buildFieldKey("ManufacturerConnectorSelectedForCornerColumnToBeam"),
  buildFieldKey("ManufacturerConnectorSelectedForEachRoofMemberToBeam"),
  buildFieldKey("Attestation320"),
  buildFieldKey("SingleLevelPrescriptiveAttachedStructureLoadsDocumentation"),
];

export const installerComplianceFormPrescriptiveAttachedStructureBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormPrescriptiveAttachedStructureFields(),
          ...prescriptiveAttachedStructureFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        installerComplianceFormPrescriptiveAttachedStructureRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardInstallerComplianceAttachedStructureBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...installerComplianceFormGeneralInformationFields(),
          ...installerComplianceFormEngineeredAttachedStructureFields(),
          ...installerComplianceFormPrescriptiveAttachedStructureFields(),
          ...prescriptiveAttachedStructureFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        [
          ...installerComplianceFormGeneralInformationRequiredFields,
          ...installerComplianceFormEngineeredAttachedStructureRequiredFields,
          ...installerComplianceFormPrescriptiveAttachedStructureRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineInstallerComplianceAttachedStructureFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...installerComplianceFormGeneralInformationFields(),
            ...installerComplianceFormEngineeredAttachedStructureFields(),
            ...installerComplianceFormPrescriptiveAttachedStructureFields(),
            ...prescriptiveAttachedStructureFileUploaderFields(form, errors),
          ],
          onValueChange,
          form,
          errors,
          [
            ...installerComplianceFormGeneralInformationRequiredFields,
            ...installerComplianceFormEngineeredAttachedStructureRequiredFields,
            ...installerComplianceFormPrescriptiveAttachedStructureRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
