import { useCommercialRedesignation } from "../../customHooks/useCommercialRedesignation";
import {
  useEvaluationChangeRequest,
  UseEvaluationChangeRequestProps,
} from "../../../../../common/customHooks/IterationEngine/ChangeRequests/useEvaluationChangeRequest";
import {
  UseEvaluationCommentsProps,
  useEvaluationIterationEngineComments,
} from "../../../../../common/customHooks/IterationEngine/Comments/useEvaluationIterationEngineComments";
import { FCFMFSchema } from "../../../common/customHooks/useFCFMFFieldSchemaFactory";
import { useEffect, useRef, useState } from "react";
import { getThirdLevelNesting } from "../../../../Common/customHooks/useFortifiedChangeRequestNavigator";
import { ChangeRequestV2 } from "../../../../../wildfire/components/IterationEngine/types/ChangeRequestV2";
import { fortifiedUserStore } from "../../../../../foritfied/stores/FortifiedUserStore";
import { fortifiedEvaluationCommentAPIClient } from "../../../../Common/lib/apiClients/fortifiedCommentAPIClient";
import { fortifiedChangeRequestApiClient } from "../../../../Common/lib/apiClients/fortifiedChangeRequestAPIClient";

export const useCommercialRedesignationIterationEngine = (
  evaluationId: string,
  commercialSchema: FCFMFSchema
) => {
  const {
    commercialRedesignation,
    refreshCommercialRedesignation,
    isApplicationLoading,
    setCommercialRedesignation,
  } = useCommercialRedesignation(evaluationId);

  const useEvaluationIterationEngineCommentsProps: UseEvaluationCommentsProps =
    {
      getEvaluationComments:
        fortifiedEvaluationCommentAPIClient.getEvaluationComments,
      createEvaluationComments:
        fortifiedEvaluationCommentAPIClient.createEvaluationComments,
      createEvaluationComment:
        fortifiedEvaluationCommentAPIClient.createEvaluationComment,
      deleteEvaluationComment:
        fortifiedEvaluationCommentAPIClient.deleteEvaluationComment,
    };

  const {
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
  } = useEvaluationIterationEngineComments(
    useEvaluationIterationEngineCommentsProps
  );

  const useEvaluationChangeRequestProps: UseEvaluationChangeRequestProps = {
    evaluationId: evaluationId,
    schema: commercialSchema,
    getChangeRequest: fortifiedChangeRequestApiClient.getChangeRequest,
    getChangeRequestsByEvaluation:
      fortifiedChangeRequestApiClient.getChangeRequestsByEvaluation,
    resolveChangeRequest: fortifiedChangeRequestApiClient.resolveChangeRequest,
    unresolveChangeRequest:
      fortifiedChangeRequestApiClient.unresolveChangeRequest,
  };

  const {
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
  } = useEvaluationChangeRequest(useEvaluationChangeRequestProps);

  const defaultTabId = "CommercialRedesignationForm";

  const [
    selectedIterationEngineFormTabId,
    setSelectedIterationEngineFormTabId,
  ] = useState<string>(defaultTabId);
  const hasSetInitialTab = useRef(false);

  useEffect(() => {
    if (unresolvedChangeRequests.length === 0 || hasSetInitialTab.current) {
      return;
    }

    const initialTab = getInitialTabFromChangeRequests(
      unresolvedChangeRequests
    );

    setSelectedIterationEngineFormTabId(initialTab);
    hasSetInitialTab.current = true;
  }, [allChangeRequests]);

  const getInitialTabFromChangeRequests = (
    unresolvedChangeRequests: ChangeRequestV2[]
  ): string => {
    return unresolvedChangeRequests.length > 0
      ? getThirdLevelNesting(unresolvedChangeRequests[0].evaluationFieldKey) ||
          defaultTabId
      : defaultTabId;
  };

  const canEditForm = fortifiedUserStore.IsFCFMFAdmin;

  const baseTabs = [
    { id: "CommercialRedesignationForm", label: "Redesignation Form" },
    {
      id: "CommercialRedesignationEvaluatorEmailForm",
      label: "Evaluator Email Form",
    },
    {
      id: "CommercialRedesignationFieldInspectionForm",
      label: "Field Inspection Form",
    },
    {
      id: "CommercialRedesignationComplianceLetterForm",
      label: "Compliance Letter Form",
    },
  ];

  const formTabs = [...baseTabs];

  return {
    //Evaluation
    commercialRedesignation,
    refreshCommercialRedesignation,
    isApplicationLoading,
    setCommercialRedesignation,
    //Comments
    comments,
    setComments,
    createComment,
    deleteComment,
    selectedKey,
    selectedField,
    setSelectedFieldAndGetComments,
    setSelectedKeyAndGetComments,
    //ChangeRequests
    allChangeRequests,
    setAllChangeRequests,
    updateChangeRequest,
    resolveChangeRequest,
    unresolveChangeRequest,
    unresolvedChangeRequests,
    hasNoUnaddressedChangeRequests,
    addressedAtLeastOneChangeRequest,
    resolvedAllChangeRequests,
    getChangeRequests,
    changeRequestCount,
    resolvedChangeRequestCount,
    getEvaluationFieldKeysForAllUnresolvedChangeRequests,
    resolvedChangeRequestsPercentage,
    hasOneNewChangeRequest,
    longestOutstandingChangeRequest,
    //Iteration Engine
    setSelectedIterationEngineFormTabId,
    selectedIterationEngineFormTabId,
    canEditForm,
    formTabs,
    defaultTabId,
  };
};
