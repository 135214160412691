import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationEngineeringComplianceGablesFormParts } from "../../common/types/BaseHomeEvaluationEngineeringComplianceGablesForm";
import HomeStandardEngineeringComplianceGablesFormContainer from "../components/Forms/EngineeringComplianceGablesForm/Container/HomeStandardEngineeringComplianceGablesFormContainer";

export interface HomeStandardEngineeringComplianceGablesPageProps {
  evaluationId?: string;
  engineeringComplianceGablesFormPart?: BaseHomeEvaluationEngineeringComplianceGablesFormParts;
}

const HomeStandardEngineeringComplianceGablesPageComponent: FC<
  HomeStandardEngineeringComplianceGablesPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardEngineeringComplianceGablesFormContainer
            evaluationId={props.evaluationId}
            engineeringComplianceGablesFormPart={
              props.engineeringComplianceGablesFormPart
            }
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardEngineeringComplianceGablesPage = withAITracking(
  reactPlugin,
  HomeStandardEngineeringComplianceGablesPageComponent,
  "HomeStandardEngineeringComplianceGablesPage",
  "tracking-container"
);
