import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_MultifamilyEvaluation } from "../../types/MultifamilyEvaluation";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../types/MultifamilyEvaluationFormModel";

const baseMultifamilyURL = "multifamily/evaluation";

class MultifamilyEvaluationAPIClient {
  createMultifamilyEvaluation = async () => {
    return await baseApiClient.post<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}`
    );
  };

  getMultifamilyEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}`
    );
  };

  getMultifamilyEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_MultifamilyEvaluationFormModel>(
      `${baseMultifamilyURL}/${evaluationId}`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `${baseMultifamilyURL}/${evaluationId}/payment/application-fee/credit-details`
    );
  };

  getCertificateFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `${baseMultifamilyURL}/${evaluationId}/payment/certificate-fee/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/payment/application-fee/credit`
    );
  };

  applyCertificateFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/payment/certificate-fee/credit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `${baseMultifamilyURL}/${evaluationId}/payment/application-fee/stripe-checkout`
    );
  };

  getCertificateFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `${baseMultifamilyURL}/${evaluationId}/payment/certificate-fee/stripe-checkout`
    );
  };

  moveToEvaluatorAssignmentStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/evaluator-assignment`
    );
  };

  moveToComplianceFormsStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/compliance-forms`
    );
  };

  moveToFieldInspectionsStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/field-inspections`
    );
  };

  moveToComplianceLetterStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/compliance-letter`
    );
  };

  moveToIBHSReviewStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/ibhs-review`
    );
  };

  moveToCertificateFeeStep = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${evaluationId}/certificate-fee`
    );
  };

  sendRequestedChangesEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `${baseMultifamilyURL}/${evaluationId}/requested-changes-email`
    );
  };
  sendChangesForReviewEmail = async (evaluationId: string) => {
    return await baseApiClient.post<void>(
      `${baseMultifamilyURL}/${evaluationId}/changes-for-review-email`
    );
  };
  //Complex Commercial Evaluation / Parent Child
  getComplexCommercialEvaluationBuildingIds = async (
    complexCommercialEvaluationId: string
  ) => {
    return await baseApiClient.get<string[]>(
      `${baseMultifamilyURL}/${complexCommercialEvaluationId}/complex-commercial-evaluation-building-ids`
    );
  };
  createComplexCommercialEvaluationBuilding = async (
    complexCommercialEvaluationId: string
  ) => {
    return await baseApiClient.post<Evaluation_MultifamilyEvaluation>(
      `${baseMultifamilyURL}/${complexCommercialEvaluationId}/complex-commercial-evaluation-building`
    );
  };
  convertCommercialEvaluationToMultifamilyEvaluation = async (
    commercialEvaluationId: string
  ) => {
    return await baseApiClient.post(
      `${baseMultifamilyURL}/${commercialEvaluationId}/convert`
    );
  };
}

export const multifamilyEvaluationAPIClient =
  new MultifamilyEvaluationAPIClient();
