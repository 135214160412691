import {
  commercialRoutePrefix,
  multifamilyRoutePrefix,
} from "../../../foritfied/pages/index/fortifiedCommercialMultifamilyRouteConfig";
import {
  fortifiedHomeSCSHRoutePrefix,
  fortifiedHomeStandardRoutePrefix,
} from "../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { EvaluationStep } from "../../../foritfied/types/evaluation/Evaluation";
import { commercialEvaluationAPIClient } from "../../FCFMF/commercial/lib/apiClients/commercialEvaluationAPIClient";
import { ProductSubtypes } from "../types/Evaluation/BaseEvaluation";

export const getHomeStandardPaymentPageRoute = (
  evaluationId: string
): string => {
  return `${fortifiedHomeStandardRoutePrefix}/${evaluationId}/payment`;
};

export const getHomeSCSHPaymentPageRoute = (evaluationId: string): string => {
  return `${fortifiedHomeSCSHRoutePrefix}/${evaluationId}/payment`;
};

export const getCommercialApplicationFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${commercialRoutePrefix}/${evaluationId}/payment/application-fee`;
};

export const getCommercialCertificateFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${commercialRoutePrefix}/${evaluationId}/payment/certificate-fee`;
};

export const getCommercialPaymentPageRoute = (
  evaluationId: string,
  step: EvaluationStep
): string | undefined => {
  switch (step) {
    case EvaluationStep.ApplicationFee:
      return getCommercialApplicationFeePaymentPageRoute(evaluationId);
    case EvaluationStep.CertificateFee:
      return getCommercialCertificateFeePaymentPageRoute(evaluationId);
  }
};

export const getMultifamilyApplicationFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${multifamilyRoutePrefix}/${evaluationId}/payment/application-fee`;
};

export const getMultifamilyCertificateFeePaymentPageRoute = (
  evaluationId: string
): string => {
  return `${multifamilyRoutePrefix}/${evaluationId}/payment/certificate-fee`;
};

export const getMultifamilyPaymentPageRoute = (
  evaluationId: string,
  step: EvaluationStep
): string | undefined => {
  switch (step) {
    case EvaluationStep.ApplicationFee:
      return getMultifamilyApplicationFeePaymentPageRoute(evaluationId);
    case EvaluationStep.CertificateFee:
      return getMultifamilyCertificateFeePaymentPageRoute(evaluationId);
  }
};

export const getFortifiedPaymentPageRoute = (
  productSubtype: ProductSubtypes,
  evaluationId: string,
  step: EvaluationStep
): string | undefined => {
  switch (productSubtype) {
    case ProductSubtypes.HomeStandardEvaluation:
      return getHomeStandardPaymentPageRoute(evaluationId);
    case ProductSubtypes.HomeStandardRedesignation:
      // TODO - When Home V2 is ready
      break;
    case ProductSubtypes.HomeSCSHEvaluation:
      return getHomeSCSHPaymentPageRoute(evaluationId);
    case ProductSubtypes.HomeSCSHRedesignation:
      // TODO - When Home V2 is ready
      break;
    case ProductSubtypes.HomeNCIUAEvaluation:
      // TODO - When Home V2 is ready
      break;
    case ProductSubtypes.HomeNCIUARedesignation:
      // TODO - When Home V2 is ready
      break;
    case ProductSubtypes.CommercialEvaluation:
      return getCommercialPaymentPageRoute(evaluationId, step);
    case ProductSubtypes.CommercialRedesignation:
      // TODO - When Commercial Redesignations are ready
      break;
    case ProductSubtypes.MultifamilyEvaluation:
      return getMultifamilyPaymentPageRoute(evaluationId, step);
    case ProductSubtypes.MultifamilyRedesignation:
      // TODO - When Multifamily Redesignations are ready
      break;
  }
};

export const getCheckoutUrlMethod = (
  paymentType: string | undefined
): ((evaluationId: string) => Promise<string>) | undefined => {
  switch (paymentType) {
    case "application-fee":
      return commercialEvaluationAPIClient.getApplicationFeeStripeCheckoutLink;

    case "certificate-fee":
      return commercialEvaluationAPIClient.getCertificateFeeStripeCheckoutLink;

    default:
      return undefined;
  }
};
