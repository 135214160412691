import {
  homeStandardBasicInformationFormBuilder,
  homeStandardConfirmationAffirmationsBuilder,
  homeStandardHomeownerInformationBuilder,
  homeStandardPropertyDetailsBuilder,
} from "../../../standard/components/Forms/BasicInformationForm/HomeStandardBasicInformationFormBuilder";
import { rooferConfirmationFormBuilder } from "../../../standard/components/Forms/RooferConfirmationForm/HomeStandardRooferConfirmationFormBuilder";
import {
  homeStandardAtticVentsBuilder,
  homeStandardBasicInformationBuilder,
  homeStandardDripEdgeBuilder,
  homeStandardPermitInformationBuilder,
  homeStandardPhotoVoltaicPanelsBuilder,
  homeStandardRoofingComplianceGeneralInformationFormBuilder,
  homeStandardRoofSheathingBuilder,
  homeStandardRoofStructureBuilder,
  homeStandardSealedRoofDeckBuilder,
  homeStandardSiteConditionsBuilder,
  homeStandardSkyLightsBuilder,
} from "../../../standard/components/Forms/RoofingComplianceGeneralInformationForm/HomeStandardRoofingComplianceGeneralInformationFormBuilder";
import {
  asphaltShingleRoofCoverDetailsBuilder,
  clayConcreteTileRoofCoverDetailsBuilder,
  homeStandardRoofingComplianceRoofCoverFormBuilder,
  lowSlopeRoofCoverDetailsFieldsBuilder,
  metalRoofCoverDetailsFieldsBuilder,
  roofingComplianceRoofCoverInformationBuilder,
  woodShakeShingleRoofCoverDetailsFieldsBuilder,
} from "../../../standard/components/Forms/RoofingComplianceRoofCoverForm/HomeStandardRoofingComplianceRoofCoverFormBuilder";
import { homeAuthorizationFormBuilder } from "../../../standard/components/Forms/HomeownerAuthorizationForm/HomeStandardHomeownerAuthorizationFormBuilder";
import { engineerConfirmationFormBuilder } from "../../../standard/components/Forms/EngineerConfirmationForm/HomeStandardEngineerConfirmationFormBuilder";
import { silverGoldComplianceGeneralInformationFormBuilder } from "../../../standard/components/Forms/SilverGoldComplianceGeneralInformationForm/HomeStandardSilverGoldComplianceGeneralInformationFormBuilder";
import { installingContractorConfirmationFormBuilder } from "../../../standard/components/Forms/InstallingContractorConfirmationForm/HomeStandardInstallingContractorConfirmationFormBuilder";
import {
  homeStandardInstallerComplianceGablesBuilder,
  installerComplianceFormEngineeredGablesBuilder,
  installerComplianceFormGeneralInformationBuilder,
  installerComplianceFormPrescriptiveGablesBuilder,
} from "../../../standard/components/Forms/InstallerComplianceGablesForm/HomeStandardInstallerComplianceGablesFormBuilder";
import {
  homeStandardInstallerComplianceContinuousLoadPathBuilder,
  installerComplianceFormContinuousLoadPathForNewConstructionBuilder,
  installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  installerComplianceFormContinuousLoadPathForWoodFramingBuilder,
  installerComplianceFormContinuousLoadPathGeneralInformationBuilder,
} from "../../../standard/components/Forms/InstallerComplianceContinuousLoadPathForm/HomeStandardInstallerComplianceContinuousLoadPathFormBuilder";
import {
  homeStandardInstallerComplianceChimneyBuilder,
  installerComplianceFormChimneyGeneralInformationBuilder,
  installerComplianceFormEngineeredChimneyBuilder,
  installerComplianceFormPrescriptiveChimneyBuilder,
} from "../../../standard/components/Forms/InstallerComplianceChimneyForm/HomeStandardInstallerComplianceChimneyFormBuilder";
import {
  homeStandardInstallerComplianceAttachedStructureBuilder,
  installerComplianceFormAttachedStructureGeneralInformationBuilder,
  installerComplianceFormEngineeredAttachedStructureBuilder,
  installerComplianceFormPrescriptiveAttachedStructureBuilder,
} from "../../../standard/components/Forms/InstallerComplianceAttachedStructureForm/HomeStandardInstallerComplianceAttachedStructureFormBuilder";
import { engineeringComplianceGeneralInformationFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceGeneralInformationForm/HomeStandardEngineeringComplianceGeneralInformationFormBuilder";
import { engineeringComplianceGablesFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceGablesForm/HomeStandardEngineeringComplianceGablesFormBuilder";
import {
  engineeringComplianceFormContinuousLoadPathForNewConstructionBuilder,
  engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  homeStandardEngineeringComplianceContinuousLoadPathBuilder,
} from "../../../standard/components/Forms/EngineeringComplianceContinuousLoadPathForm/HomeStandardEngineeringComplianceContinuousLoadPathFormBuilder";
import { homeStandardEngineeringComplianceChimneyFormBuilder } from "../../../standard/components/Forms/EngineeringComplianceChimneyForm/HomeStandardEngineeringComplianceChimneyFormBuilder";
import { homeStandardEngineeringComplianceAttachedStructureBuilder } from "../../../standard/components/Forms/EngineeringComplianceAttachedStructureForm/HomeStandardEngineeringComplianceAttachedStructureFormBuilder";
import {
  homeStandardDesignationTypeAndLevelInformationFieldsBuilder,
  homeStandardEligibilityFormBuilder,
  homeStandardEligibilityInformationBuilder,
} from "../../../standard/components/Forms/EligibilityForm/HomeStandardEligibilityFormBuilder";

export const HomeStandardBasicInformationForm = [
  "HomeStandardBasicInformationForm",
  "HomeStandardHomeownerInformation",
  "HomeStandardPropertyDetails",
  "HomeStandardConfirmationAffirmations",
];

export const HomeStandardEligibilityForm = [
  "HomeStandardEligibilityForm",
  "HomeStandardDesignationTypeAndLevelInformation",
  "HomeStandardEligibilityInformation",
];

export const HomeStandardRoofingComplianceGeneralInformationForm = [
  "HomeStandardRoofingComplianceGeneralInformationForm",
  "HomeStandardRoofingComplianceBasicInformationForm",
  "HomeStandardRoofStructureForm",
  "HomeStandardRoofSheathingForm",
  "HomeStandardDripEdgeForm",
  "HomeStandardSkyLightsForm",
  "HomeStandardPhotoVoltaicPanelsForm",
  "HomeStandardAtticVentsForm",
  "HomeStandardPermitInformationForm",
  "HomeStandardSiteConditionsForm",
  "HomeStandardSealedRoofDeckForm",
];

export const HomeStandardRoofingComplianceRoofCoverForm = [
  "HomeStandardRoofingComplianceRoofCoverForm",
  "HomeStandardRoofCoverInformation",
  "HomeStandardAsphaltShingleRoofCoverDetails",
  "HomeStandardMetalRoofCoverDetails",
  "HomeStandardClayConcreteTileRoofCoverDetails",
  "HomeStandardWoodShakeSingleRoofCoverDetails",
  "HomeStandardLowSlopeRoofCoverDetails",
];

export const HomeStandardInstallerComplianceGablesForm = [
  "HomeStandardInstallerComplianceGablesForm",
  "HomeStandardInstallerComplianceFormGeneralInformationForm",
  "HomeStandardInstallerComplianceFormEngineeredGables",
  "HomeStandardInstallerComplianceFormPrescriptiveGables",
];

export const HomeStandardInstallerComplianceContinuousLoadPathForm = [
  "HomeStandardInstallerComplianceContinuousLoadPathForm",
  "HomeStandardInstallerComplianceFormContinuousLoadPathGeneralInformation",
  "HomeStandardInstallerComplianceFormContinuousLoadPathForNewConstruction",
  "HomeStandardInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome",
  "HomeStandardInstallerComplianceFormContinuousLoadPathForWoodFraming",
];

export const HomeStandardInstallerComplianceChimneyForm = [
  "HomeStandardInstallerComplianceChimneyForm",
  "HomeStandardInstallerComplianceChimneyFormGeneralInformation",
  "HomeStandardInstallerComplianceChimneyFormEngineeredChimney",
  "HomeStandardInstallerComplianceChimneyFormPrescriptiveChimney",
];

export const HomeStandardInstallerComplianceAttachedStructureForm = [
  "HomeStandardInstallerComplianceAttachedStructureForm",
  "HomeStandardInstallerComplianceAttachedStructureFormGeneralInformation",
  "HomeStandardInstallerComplianceAttachedStructureFormEngineeredAttachedStructure",
  "HomeStandardInstallerComplianceAttachedStructureFormPrescriptiveAttachedStructure",
];

export const HomeStandardEngineeringComplianceContinuousLoadPathForm = [
  "HomeStandardEngineeringComplianceContinuousLoadPathForm",
  "HomeStandardEngineeringComplianceFormContinuousLoadPathForNewConstruction",
  "HomeStandardEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome",
];

export const HomeStandardRooferConfirmationForm = [
  "HomeStandardRooferConfirmationForm",
];

export const HomeStandardHomeownerAuthorizationForm = [
  "HomeStandardHomeownerAuthorizationForm",
];

export const HomeStandardEngineerConfirmationForm = [
  "HomeStandardEngineerConfirmationForm",
];

export const HomeStandardSilverGoldComplianceGeneralInformationForm = [
  "HomeStandardSilverGoldComplianceGeneralInformationForm",
];

export const HomeStandardInstallingContractorConfirmationForm = [
  "HomeStandardInstallingContractorConfirmationForm",
];

export const HomeStandardEngineeringComplianceGeneralInformationForm = [
  "HomeStandardEngineeringComplianceGeneralInformationForm",
];

export const HomeStandardEngineeringComplianceGablesForm = [
  "HomeStandardEngineeringComplianceGablesForm",
];

export const HomeStandardEngineeringComplianceChimneyForm = [
  "HomeStandardEngineeringComplianceChimneyForm",
];

export const HomeStandardEngineeringComplianceAttachedStructureForm = [
  "HomeStandardEngineeringComplianceAttachedStructureForm",
];

export const HomeStandardForms = [
  ...HomeStandardBasicInformationForm,
  ...HomeStandardEligibilityForm,
  ...HomeStandardRooferConfirmationForm,
  ...HomeStandardRoofingComplianceGeneralInformationForm,
  ...HomeStandardRoofingComplianceRoofCoverForm,
  ...HomeStandardHomeownerAuthorizationForm,
  ...HomeStandardEngineerConfirmationForm,
  ...HomeStandardSilverGoldComplianceGeneralInformationForm,
  ...HomeStandardInstallingContractorConfirmationForm,
  ...HomeStandardInstallerComplianceGablesForm,
  ...HomeStandardInstallerComplianceContinuousLoadPathForm,
  ...HomeStandardInstallerComplianceChimneyForm,
  ...HomeStandardInstallerComplianceAttachedStructureForm,
  ...HomeStandardEngineeringComplianceGeneralInformationForm,
  ...HomeStandardEngineeringComplianceGablesForm,
  ...HomeStandardEngineeringComplianceContinuousLoadPathForm,
  ...HomeStandardEngineeringComplianceChimneyForm,
  ...HomeStandardEngineeringComplianceAttachedStructureForm,
];

export const HomeStandardFormsConfig = {
  HomeStandardBasicInformationForm: undefined,
  HomeStandardHomeownerInformation: undefined,
  HomeStandardPropertyDetails: undefined,
  HomeStandardConfirmationAffirmations: undefined,
  HomeStandardEligibilityForm: undefined,
  HomeStandardDesignationTypeAndLevelInformation: undefined,
  HomeStandardEligibilityInformation: undefined,
  HomeStandardRooferConfirmationForm: undefined,
  HomeStandardRoofingComplianceGeneralInformationForm: undefined,
  HomeStandardRoofingComplianceBasicInformationForm: undefined,
  HomeStandardPermitInformationForm: undefined,
  HomeStandardSiteConditionsForm: undefined,
  HomeStandardRoofStructureForm: undefined,
  HomeStandardRoofSheathingForm: undefined,
  HomeStandardSealedRoofDeckForm: undefined,
  HomeStandardDripEdgeForm: undefined,
  HomeStandardAtticVentsForm: undefined,
  HomeStandardPhotoVoltaicPanelsForm: undefined,
  HomeStandardSkyLightsForm: undefined,
  HomeStandardRoofingComplianceRoofCoverForm: undefined,
  HomeStandardRoofCoverInformation: undefined,
  HomeStandardAsphaltShingleRoofCoverDetails: undefined,
  HomeStandardMetalRoofCoverDetails: undefined,
  HomeStandardClayConcreteTileRoofCoverDetails: undefined,
  HomeStandardWoodShakeSingleRoofCoverDetails: undefined,
  HomeStandardLowSlopeRoofCoverDetails: undefined,
  HomeStandardHomeownerAuthorizationForm: undefined,
  HomeStandardEngineerConfirmationForm: undefined,
  HomeStandardSilverGoldComplianceGeneralInformationForm: undefined,
  HomeStandardInstallingContractorConfirmationForm: undefined,
  HomeStandardInstallerComplianceGablesForm: undefined,
  HomeStandardInstallerComplianceFormGeneralInformationForm: undefined,
  HomeStandardInstallerComplianceFormEngineeredGables: undefined,
  HomeStandardInstallerComplianceFormPrescriptiveGables: undefined,
  HomeStandardInstallerComplianceContinuousLoadPathForm: undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathGeneralInformation:
    undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathForNewConstruction:
    undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    undefined,
  HomeStandardInstallerComplianceFormContinuousLoadPathForWoodFraming:
    undefined,
  HomeStandardInstallerComplianceChimneyForm: undefined,
  HomeStandardInstallerComplianceChimneyFormGeneralInformation: undefined,
  HomeStandardInstallerComplianceChimneyFormEngineeredChimney: undefined,
  HomeStandardInstallerComplianceChimneyFormPrescriptiveChimney: undefined,
  HomeStandardInstallerComplianceAttachedStructureForm: undefined,
  HomeStandardInstallerComplianceAttachedStructureFormGeneralInformation:
    undefined,
  HomeStandardInstallerComplianceAttachedStructureFormEngineeredAttachedStructure:
    undefined,
  HomeStandardInstallerComplianceAttachedStructureFormPrescriptiveAttachedStructure:
    undefined,
  HomeStandardEngineeringComplianceGeneralInformationForm: undefined,
  HomeStandardEngineeringComplianceGablesForm: undefined,
  HomeStandardEngineeringComplianceContinuousLoadPathForm: undefined,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForNewConstruction:
    undefined,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    undefined,
  HomeStandardEngineeringComplianceChimneyForm: undefined,
  HomeStandardEngineeringComplianceAttachedStructureForm: undefined,
};

export const HomeStandardBuilders = {
  HomeStandardBasicInformationForm: homeStandardBasicInformationFormBuilder,
  HomeStandardHomeownerInformation: homeStandardHomeownerInformationBuilder,
  HomeStandardPropertyDetails: homeStandardPropertyDetailsBuilder,
  HomeStandardConfirmationAffirmations:
    homeStandardConfirmationAffirmationsBuilder,
  HomeStandardEligibilityForm: homeStandardEligibilityFormBuilder,
  HomeStandardDesignationTypeAndLevelInformation:
    homeStandardDesignationTypeAndLevelInformationFieldsBuilder,
  HomeStandardEligibilityInformation: homeStandardEligibilityInformationBuilder,
  HomeStandardRooferConfirmationForm: rooferConfirmationFormBuilder,
  HomeStandardRoofingComplianceGeneralInformationForm:
    homeStandardRoofingComplianceGeneralInformationFormBuilder,
  HomeStandardRoofingComplianceBasicInformationForm:
    homeStandardBasicInformationBuilder,
  HomeStandardPermitInformationForm: homeStandardPermitInformationBuilder,
  HomeStandardSiteConditionsForm: homeStandardSiteConditionsBuilder,
  HomeStandardRoofStructureForm: homeStandardRoofStructureBuilder,
  HomeStandardRoofSheathingForm: homeStandardRoofSheathingBuilder,
  HomeStandardSealedRoofDeckForm: homeStandardSealedRoofDeckBuilder,
  HomeStandardDripEdgeForm: homeStandardDripEdgeBuilder,
  HomeStandardAtticVentsForm: homeStandardAtticVentsBuilder,
  HomeStandardPhotoVoltaicPanelsForm: homeStandardPhotoVoltaicPanelsBuilder,
  HomeStandardSkyLightsForm: homeStandardSkyLightsBuilder,
  HomeStandardRoofingComplianceRoofCoverForm:
    homeStandardRoofingComplianceRoofCoverFormBuilder,
  HomeStandardRoofCoverInformation:
    roofingComplianceRoofCoverInformationBuilder,
  HomeStandardAsphaltShingleRoofCoverDetails:
    asphaltShingleRoofCoverDetailsBuilder,
  HomeStandardMetalRoofCoverDetails: metalRoofCoverDetailsFieldsBuilder,
  HomeStandardClayConcreteTileRoofCoverDetails:
    clayConcreteTileRoofCoverDetailsBuilder,
  HomeStandardWoodShakeSingleRoofCoverDetails:
    woodShakeShingleRoofCoverDetailsFieldsBuilder,
  HomeStandardLowSlopeRoofCoverDetails: lowSlopeRoofCoverDetailsFieldsBuilder,
  HomeStandardHomeownerAuthorizationForm: homeAuthorizationFormBuilder,
  HomeStandardEngineerConfirmationForm: engineerConfirmationFormBuilder,
  HomeStandardSilverGoldComplianceGeneralInformationForm:
    silverGoldComplianceGeneralInformationFormBuilder,
  HomeStandardInstallingContractorConfirmationForm:
    installingContractorConfirmationFormBuilder,
  HomeStandardInstallerComplianceGablesForm:
    homeStandardInstallerComplianceGablesBuilder,
  HomeStandardInstallerComplianceFormGeneralInformationForm:
    installerComplianceFormGeneralInformationBuilder,
  HomeStandardInstallerComplianceFormEngineeredGables:
    installerComplianceFormEngineeredGablesBuilder,
  HomeStandardInstallerComplianceFormPrescriptiveGables:
    installerComplianceFormPrescriptiveGablesBuilder,
  HomeStandardInstallerComplianceContinuousLoadPathForm:
    homeStandardInstallerComplianceContinuousLoadPathBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathGeneralInformation:
    installerComplianceFormContinuousLoadPathGeneralInformationBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathForNewConstruction:
    installerComplianceFormContinuousLoadPathForNewConstructionBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    installerComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  HomeStandardInstallerComplianceFormContinuousLoadPathForWoodFraming:
    installerComplianceFormContinuousLoadPathForWoodFramingBuilder,
  HomeStandardInstallerComplianceChimneyForm:
    homeStandardInstallerComplianceChimneyBuilder,
  HomeStandardInstallerComplianceChimneyFormGeneralInformation:
    installerComplianceFormChimneyGeneralInformationBuilder,
  HomeStandardInstallerComplianceChimneyFormEngineeredChimney:
    installerComplianceFormEngineeredChimneyBuilder,
  HomeStandardInstallerComplianceChimneyFormPrescriptiveChimney:
    installerComplianceFormPrescriptiveChimneyBuilder,
  HomeStandardInstallerComplianceAttachedStructureForm:
    homeStandardInstallerComplianceAttachedStructureBuilder,
  HomeStandardInstallerComplianceAttachedStructureFormGeneralInformation:
    installerComplianceFormAttachedStructureGeneralInformationBuilder,
  HomeStandardInstallerComplianceAttachedStructureFormEngineeredAttachedStructure:
    installerComplianceFormEngineeredAttachedStructureBuilder,
  HomeStandardInstallerComplianceAttachedStructureFormPrescriptiveAttachedStructure:
    installerComplianceFormPrescriptiveAttachedStructureBuilder,
  HomeStandardEngineeringComplianceGeneralInformationForm:
    engineeringComplianceGeneralInformationFormBuilder,
  HomeStandardEngineeringComplianceGablesForm:
    engineeringComplianceGablesFormBuilder,
  HomeStandardEngineeringComplianceContinuousLoadPathForm:
    homeStandardEngineeringComplianceContinuousLoadPathBuilder,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForNewConstruction:
    engineeringComplianceFormContinuousLoadPathForNewConstructionBuilder,
  HomeStandardEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome:
    engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder,
  HomeStandardEngineeringComplianceChimneyForm:
    homeStandardEngineeringComplianceChimneyFormBuilder,
  HomeStandardEngineeringComplianceAttachedStructureForm:
    homeStandardEngineeringComplianceAttachedStructureBuilder,
};
