import { PrimaryCard } from "@ucl/library";
import { useEffect, useState } from "react";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";

interface HomeStandardRedesignationIterationEngineFormsProps {
  selectedTabId: string;
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
}

const HomeStandardRedesignationIterationEngineForms: React.FC<
  HomeStandardRedesignationIterationEngineFormsProps
> = (props) => {
  // const [
  //   evaluationFormInitiaized,
  //   //setEvaluationFormInitiaized
  // ] = useState<boolean>();

  const [
    homeownerAuthorizationInitialized,
    //setHomeownerAuthorizationFormInitialized,
  ] = useState<boolean>(false);

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized = homeownerAuthorizationInitialized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [homeownerAuthorizationInitialized]);

  //const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      default:
        return {
          headerContent:
            "Home NCIUA Redesignation Homeowner Authorizatoin Form",
          form: (
            <></>
            // <HomeNCIUARedesignationHomeownerAuthorizationForm
            //   evaluationId={props.evaluationId}
            //   isIterationEnginePage={true}
            //   hideFormControls={true}
            //   onInitialized={() => {
            //     setHomeownerAuthorizationFormInitialized(true);
            //   }}
            //   onFormSave={async (fieldKey) => {
            //     props.onFormSave(fieldKey);
            //   }}
            //   setHasUnsavedChanges={setHasUnsavedChanges}
            // />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default HomeStandardRedesignationIterationEngineForms;
