import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "RoofingComplianceGeneralInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Basic Information
const basicInformationFields = () => [
  buildFieldKey("RoofInstallationDate"),
  buildFieldKey("DistanceToSaltWaterShoreline"),
  buildFieldKey("NumberOfDifferentRoofCoverTypes"),
  buildFieldKey("WasPermitIssued"),
];

const basicInformationRequiredFields = [
  buildFieldKey("RoofInstallationDate"),
  buildFieldKey("NumberOfDifferentRoofCoverTypes"),
  buildFieldKey("WasPermitIssued"),
];

export const homeStandardBasicInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        basicInformationFields(),
        onValueChange,
        form,
        errors,
        basicInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 2: Roof Structure
const roofStructureFields = () => [
  buildFieldKey("MaxRoofStructuralMemberSpacing"),
];

const roofStructureRequiredFields = [
  buildFieldKey("MaxRoofStructuralMemberSpacing"),
  buildFieldKey("MaximumRoofStructuralMemberSpacingPhoto"),
];

const roofstructurefileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("MaximumRoofStructuralMemberSpacingPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MaximumRoofStructuralMemberSpacingPhoto.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardRoofStructureBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...roofStructureFields(),
          ...roofstructurefileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        roofStructureRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 3: Roof Sheathing
const roofSheathingFields = () => [
  buildFieldKey("RoofSheathingType"),
  buildFieldKey("RoofSheathingTypeWhenOther"),
  buildFieldKey("MinimumRoofSheathingThickness"),
  buildFieldKey(
    "SelectedStandardDetailsThatMatchesInstalledSheathingAttachment"
  ),
  buildFieldKey(
    "DoesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements"
  ),
];

const roofSheathingRequiredFields = [
  buildFieldKey("RoofSheathingType"),
  buildFieldKey("RoofSheathingTypeWhenOther"),
  buildFieldKey("MinimumRoofSheathingThickness"),
  buildFieldKey("MinimumRoofSheathingThicknessPhotos"),
  buildFieldKey("RoofSheathingFastenerTypePhoto"),
  buildFieldKey("RoofSheathingAttachmentPhoto"),
  buildFieldKey("RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation"),
  buildFieldKey(
    "SelectedStandardDetailsThatMatchesInstalledSheathingAttachment"
  ),
  buildFieldKey(
    "DoesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements"
  ),
];

const roofSheathingFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("RoofSheathingFastenerTypePhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofSheathingFastenerTypePhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RoofSheathingAttachmentPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofSheathingAttachmentPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardRoofSheathingBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...roofSheathingFields(),
          ...roofSheathingFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        roofSheathingRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 4: Drip Edge
const dripEdgeFields = () => [
  buildFieldKey("AreInstalledDripEdgeNewAndCodeCompliantMaterials"),
  buildFieldKey("StandardDetailThatMatchesDripEdgeInstallation"),
  buildFieldKey(
    "WasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications"
  ),
  buildFieldKey("IsDripEdgeInstalled"),
];

const dripEdgeRequiredFields = [
  buildFieldKey("AreInstalledDripEdgeNewAndCodeCompliantMaterials"),
  buildFieldKey("StandardDetailThatMatchesDripEdgeInstallation"),
  buildFieldKey("NewDripEdgeInstallationPhotos"),
  buildFieldKey(
    "WasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications"
  ),
  buildFieldKey("IsDripEdgeInstalled"),
  buildFieldKey("ExistingDripEdgeInstallationPhotos"),
];

const dripEdgeFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("NewDripEdgeInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "NewDripEdgeInstallationPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ExistingDripEdgeInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "ExistingDripEdgeInstallationPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardDripEdgeBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [...dripEdgeFields(), ...dripEdgeFileUploaderFields(form, errors)],
        onValueChange,
        form,
        errors,
        dripEdgeRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

const skyLightsFields = () => [
  buildFieldKey("AreSkylightsPresent"),
  buildFieldKey("SkylightExistsAndRatingsAndInstallationAreUnknown"),
  buildFieldKey("WereSkylightsInstalledPerManufacturerInstructions"),
  buildFieldKey("SkylightImpactRating"),
];

const skyLightsRequiredFields = [
  buildFieldKey("AreSkylightsPresent"),
  buildFieldKey("SkylightExistsAndRatingsAndInstallationAreUnknown"),
  buildFieldKey("WereSkylightsInstalledPerManufacturerInstructions"),
  buildFieldKey("SkylightImpactRating"),
  buildFieldKey("SkylightHailRatingPhotos"),
  buildFieldKey("SkylightHailRatingDocumentation"),
];

const skylightFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("SkylightHailRatingPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "SkylightHailRatingPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SkylightHailRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "SkylightHailRatingDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardSkyLightsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [...skyLightsFields(), ...skylightFileUploaderFields(form, errors)],
        onValueChange,
        form,
        errors,
        skyLightsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 6: Photo Voltaic Panels
const photoVoltaicPanelsFields = () => [buildFieldKey("PVPanelImpactRating")];

const photoVoltaicPanelsRequiredFields = [
  buildFieldKey("AreRoofMountedPhotoVoltaicPanelsPresent"),
  buildFieldKey("WerePVPanelsInstalledAndAnchoredPerAnchorageDetails"),
  buildFieldKey("PVAnchorageDocumentation"),
  buildFieldKey("PVPanelImpactRating"),
  buildFieldKey("PVHailRatingDocumentation"),
];

const photoVoltaicPanelsFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("PVAnchorageDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "PVAnchorageDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("PVHailRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "PVHailRatingDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardPhotoVoltaicPanelsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...photoVoltaicPanelsFields(),
          ...photoVoltaicPanelsFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        photoVoltaicPanelsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 7: Attic Vents
const atticVentsFields = () => [
  buildFieldKey("AreRoofMountedVentsOrGableEndsPresent"),
  buildFieldKey("RoofVentilation"),
  buildFieldKey("DoAllRoofMountedVentsMeetTAS100A"),
  buildFieldKey("AreAllRoofMountedVentsInstalledPerManufacturerRequirements"),
  buildFieldKey("DoAllGableEndVentsHaveARemovablePerFortified2020HomeStandard"),
];

const atticVentsRequiredFields = [
  buildFieldKey("AreRoofMountedVentsOrGableEndsPresent"),
  buildFieldKey("RoofVentilation"),
  buildFieldKey("DoAllRoofMountedVentsMeetTAS100A"),
  buildFieldKey("RoofVentilationPhoto"),
  buildFieldKey("AreAllRoofMountedVentsInstalledPerManufacturerRequirements"),
  buildFieldKey("DoAllGableEndVentsHaveARemovablePerFortified2020HomeStandard"),
  buildFieldKey("GableEndsVentsPhoto"),
];

const atticVentsFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("RoofVentilationPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofVentilationPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GableEndsVentsPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "GableEndsVentsPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardAtticVentsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [...atticVentsFields(), ...atticVentsFileUploaderFields(form, errors)],
        onValueChange,
        form,
        errors,
        atticVentsRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 8: Permit Information
const permitInformationFields = () => [
  buildFieldKey("PermitNumber"),
  buildFieldKey("PermitDate"),
  buildFieldKey("PermitIssuedBy"),
  buildFieldKey("DateOfFinalBldgDeptInspection"),
];

const permitInformationRequiredFields = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("PermitDate"),
  buildFieldKey("PermitIssuedBy"),
];

export const homeStandardPermitInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        permitInformationFields(),
        onValueChange,
        form,
        errors,
        permitInformationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 9: Site Conditions
const siteConditionsFields = () => [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("ASCE7Edition"),
  buildFieldKey("UltimateSiteDesignWindSpeed"),
  buildFieldKey("SiteExposureCategory"),
  buildFieldKey("MeanRoofHeight"),
];

const siteConditionsRequiredFields = [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("ASCE7Edition"),
  buildFieldKey("UltimateSiteDesignWindSpeed"),
  buildFieldKey("SiteExposureCategory"),
  buildFieldKey("MeanRoofHeight"),
];

export const homeStandardSiteConditionsBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        siteConditionsFields(),
        onValueChange,
        form,
        errors,
        siteConditionsRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Part 10: Sealed Roof Deck
const sealedRoofDeckFields = () => [
  buildFieldKey("IsEntireRoofLowSloped"),
  buildFieldKey("SelectedStandardDetailsThatMatchesSealedRoofDeck"),
  buildFieldKey("DoesSealedRoofDeckNotMatchFortifiedPrescribedRequirements"),
];

const sealedRoofDeckRequiredFields = [
  buildFieldKey("IsEntireRoofLowSloped"),
  buildFieldKey("SelectedStandardDetailsThatMatchesSealedRoofDeck"),
  buildFieldKey("DoesSealedRoofDeckNotMatchFortifiedPrescribedRequirements"),
  buildFieldKey("SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos"),
  buildFieldKey("SealedRoofDeck2PlyInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSelfAdheredMembraneInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSprayFoamInstallationPhotos"),
  buildFieldKey("SealedRoofDeckTapedSeamsWithTileInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos"),
  buildFieldKey("SealedRoofDeckUnderlaymentLabelPhotos"),
  buildFieldKey("SealedRoofDeckNoneOfTheAbovePhotos"),
  buildFieldKey("SealedRoofDeckClosedCellFoamDocumentation"),
  buildFieldKey("FullyInstalledRoofCoverPhotos"),
];

const sealedRoofDeckFileUploaderFields = (
  _form: Evaluation_HomeStandardEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeck2PlyInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeck2PlyInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckSelfAdheredMembraneInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckSelfAdheredMembraneInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckSprayFoamInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckSprayFoamInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckTapedSeamsWithTileInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckTapedSeamsWithTileInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckUnderlaymentLabelPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckUnderlaymentLabelPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckNoneOfTheAbovePhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "SealedRoofDeckNoneOfTheAbovePhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckClosedCellFoamDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckClosedCellFoamDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("FullyInstalledRoofCoverPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "FullyInstalledRoofCoverPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardSealedRoofDeckBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...sealedRoofDeckFields(),
          ...sealedRoofDeckFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        sealedRoofDeckRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeStandardRoofingComplianceGeneralInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...basicInformationFields(),
          ...permitInformationFields(),
          ...roofStructureFields(),
          ...roofSheathingFields(),
          ...dripEdgeFields(),
          ...skyLightsFields(),
          ...photoVoltaicPanelsFields(),
          ...atticVentsFields(),
          ...siteConditionsFields(),
          ...sealedRoofDeckFields(),
        ],
        onValueChange,
        form,
        errors,
        [
          ...basicInformationRequiredFields,
          ...permitInformationRequiredFields,
          ...roofStructureRequiredFields,
          ...roofSheathingRequiredFields,
          ...dripEdgeRequiredFields,
          ...skyLightsRequiredFields,
          ...photoVoltaicPanelsRequiredFields,
          ...atticVentsRequiredFields,
          ...siteConditionsRequiredFields,
          ...sealedRoofDeckRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineRoofingComplianceGeneralInformationFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...basicInformationFields(),
            ...permitInformationFields(),
            ...siteConditionsFields(),
            ...roofStructureFields(),
            ...roofstructurefileUploaderFields(form, errors),
            ...roofSheathingFields(),
            ...roofSheathingFileUploaderFields(form, errors),
            ...sealedRoofDeckFields(),
            ...sealedRoofDeckFileUploaderFields(form, errors),
            ...dripEdgeFields(),
            ...dripEdgeFileUploaderFields(form, errors),
            ...atticVentsFields(),
            ...atticVentsFileUploaderFields(form, errors),
            ...photoVoltaicPanelsFields(),
            ...photoVoltaicPanelsFileUploaderFields(form, errors),
            ...skyLightsFields(),
            ...skylightFileUploaderFields(form, errors),
          ],
          onValueChange,
          form,
          errors,
          [
            ...basicInformationRequiredFields,
            ...permitInformationRequiredFields,
            ...siteConditionsRequiredFields,
            ...roofStructureRequiredFields,
            ...roofSheathingRequiredFields,
            ...sealedRoofDeckRequiredFields,
            ...dripEdgeRequiredFields,
            ...atticVentsRequiredFields,
            ...photoVoltaicPanelsRequiredFields,
            ...skyLightsRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
