import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Header } from "../../components/Header/Header";
import { PageSidebar } from "../../components/PageSidebar/PageSidebar";
import "../styles.scss";
import { reactPlugin } from "../../../AppInsights";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import { useProductTypeHandler } from "../../providers/ProductTypeHandlerProvider";
import { ReportReadDTO } from "../../../common/types/reports";
import { fortifiedReportApiClient } from "../../lib/apiClients/fortifiedReportApiClient";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

export const ReportViewerPageComponent: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();

  const { routePrefix } = useProductTypeHandler();

  if (!slug) {
    errorStore.setErrorMessage(genericErrorMessage);
    return null;
  }

  const [isReportLoading, setIsReportLoading] = useState(true);
  const [report, setReport] = useState<ReportReadDTO>();

  useEffect(() => {
    fortifiedReportApiClient
      .getReport(slug)
      .then((response) => {
        setReport(response);
      })
      .catch((error) => {
        console.log(error);
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsReportLoading(false);
      });
  }, []);

  return (
    <div className="fortified-page board-page">
      <Observer>
        {() => (
          <Header
            breadCrumbs={[
              { href: `${routePrefix}/reports`, text: "Reports" },
              ...(report ? [{ text: report.title }] : []),
            ]}
          />
        )}
      </Observer>
      <div className="content">
        <PageSidebar activePage="reports" />
        {isReportLoading || !report ? (
          <Loading />
        ) : (
          <div className="page-main-content">
            <iframe
              title={report.title}
              width="100%"
              height="100%"
              src={report.reportLink}
              frameBorder="0"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
};

export const ReportViewerPage = withAITracking(
  reactPlugin,
  ReportViewerPageComponent,
  "ReportViewerPage",
  "tracking-container"
);
