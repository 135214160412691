import { SingleCheckboxFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationEngineeringComplianceAttachedStructureFormParts {
  EngineeringComplianceFormAttachedStructure = "engineering-compliance-form-attached-structure",
}

export interface BaseHomeEvaluationEngineeringComplianceAttachedStructureForm
  extends IEngineeringComplianceEngineeringAttachedStructure {}

export interface IEngineeringComplianceEngineeringAttachedStructure {
  attestation223?: SingleCheckboxFieldValue;
  attestation224?: SingleCheckboxFieldValue;
  attestation225?: SingleCheckboxFieldValue;
  attestation226?: SingleCheckboxFieldValue;
  attestation227?: SingleCheckboxFieldValue;
  attestation228?: SingleCheckboxFieldValue;
  attestation229?: SingleCheckboxFieldValue;
  attestation230?: SingleCheckboxFieldValue;
  attestation231?: SingleCheckboxFieldValue;
  attestation232?: SingleCheckboxFieldValue;
  attestation233?: SingleCheckboxFieldValue;
}
