import {
  DateFieldValue,
  EnumSingleSelectFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";

export enum BaseHomeEvaluationInstallerComplianceGablesFormParts {
  InstallerComplianceFormGeneralInformation = "installer-compliance-form-general-information",
  InstallerComplianceFormEngineeredGables = "installer-compliance-form-engineered-gables",
  InstallerComplianceFormPrescriptiveGables = "installer-compliance-form-prescriptive-gables",
}

export interface BaseHomeEvaluationInstallerComplianceGablesForm
  extends IInstallerComplianceFormGeneralInformation,
    IInstallerComplianceFormEngineeredGables,
    IInstallerComplianceFormPrescriptiveGables {}

export interface IInstallerComplianceFormGeneralInformation {
  permitNumber?: TextFieldValue;
  dateInstallationWasCompleted?: DateFieldValue;
}

export interface IInstallerComplianceFormEngineeredGables {
  attestation281?: SingleCheckboxFieldValue;
  attestation282?: SingleCheckboxFieldValue;
  attestation283?: SingleCheckboxFieldValue;
  attestation284?: SingleCheckboxFieldValue;
  attestation285?: SingleCheckboxFieldValue;
  attestation286?: SingleCheckboxFieldValue;
}

export interface IInstallerComplianceFormPrescriptiveGables {
  attestation287?: SingleCheckboxFieldValue;
  attestation288?: SingleCheckboxFieldValue;
  prescriptiveCodeForGableBracingInstallation?: EnumSingleSelectFieldValue;
  prescriptiveCodeForGableBracingInstallationDescription?: TextFieldValue;
  attestation291?: SingleCheckboxFieldValue;
}
