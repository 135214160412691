import { ExistingResource } from "../../../../foritfied/types/evaluation/ExistingResource";
import { BaseEvaluation } from "../../../Common/types/Evaluation/BaseEvaluation";

export interface NewEvaluation_MultifamilyEvaluation extends BaseEvaluation {
  multifamilyEvaluation?: MultifamilyEvaluation;
}

export type Evaluation_MultifamilyEvaluation =
  ExistingResource<NewEvaluation_MultifamilyEvaluation>;

export interface MultifamilyEvaluation {
  id: string;
  evaluationId: string;
  multifamilyEvaluationApplicationForm?: MultifamilyEvaluationApplicationForm;
  multifamilyEvaluationCompanyAssignmentForm?: MultifamilyEvaluationCompanyAssignmentForm;
  multifamilyEvaluationFieldInspectionForm?: MultifamilyEvaluationFieldInspectionForm;
  multifamilyEvaluationComplianceForm?: MultifamilyEvaluationComplianceForm;
  multifamilyEvaluationComplianceLetterForm?: MultifamilyEvaluationComplianceLetterForm;

  //Complex Commercial Evaluation / Parent Child
  isComplexCommercialEvaluationBuildingFormAbleToBeModified: boolean;
  isFCFMFPaymentStep: boolean;
  isComplexCommercialEvaluationAndNeedToFillOutBuildings: boolean;
}

export interface NewComplexMultifamilyEvaluationBuildingV2
  extends NewEvaluation_MultifamilyEvaluation {}

export type Evaluation_MultifamilyEvaluationBuildingV2 =
  ExistingResource<NewComplexMultifamilyEvaluationBuildingV2>;

export interface MultifamilyEvaluationComplianceLetterForm {
  id: string;
  multiifamilyEvaluationId: string;
  evaluationId: string;
  multifamilyEvaluationComplianceLetterRequiredFieldcount: number;
  isFormComplete: boolean;
}

export interface MultifamilyEvaluationComplianceForm {
  id: string;
  multiifamilyEvaluationId: string;
  evaluationId: string;
  multifamilyEvaluationComplianceFormRequiredFieldcount: number;
  isFormComplete: boolean;
}

export interface MultifamilyEvaluationApplicationForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  woodFrameRoofEligibilityAcknowledgment?: boolean;
  occupancyType?: number;
  fortifiedCommercialStandardsFamiliarityConfirmation?: boolean;
}

export interface MultifamilyEvaluationCompanyAssignmentForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  evaluatorCompany: number;
  multifamilyEvaluationCompanyAssignmentFormRequiredFieldCount: number;
  isEvaluationCompanyAssignmentFormComplete: boolean;
}

export interface MultifamilyEvaluationFieldInspectionForm {
  id: string;
  commercialEvaluationId: string;
  evaluationId: string;
  multifamilyEvaluationFieldInspectionFormRequiredFieldCount: number;
  isFileUploadersComplete: boolean;
}

type MultifamilyFormFieldKeyTypes =
  | "MultifamilyEvaluationApplicationForm"
  | "MultifamilyEvaluationCompanyAssignmentForm"
  | "MultifamilyEvaluationComplianceForm"
  | "MultifamilyEvaluationFieldInspectionForm"
  | "MultifamilyEvaluationComplianceLetterForm";

type MultifamilyForm = {
  fieldKey: MultifamilyFormFieldKeyTypes;
  displayName: string;
};

type MultifamilyFormNameTypes = {
  ApplicationForm: MultifamilyForm;
  EvaluatorAssignmentForm: MultifamilyForm;
  ComplianceForm: MultifamilyForm;
  FieldInspectionForm: MultifamilyForm;
  ComplianceLetterForm: MultifamilyForm;
};

export const MultifamilyFormNameTypes: MultifamilyFormNameTypes = {
  ApplicationForm: {
    fieldKey: "MultifamilyEvaluationApplicationForm",
    displayName: "Application Form",
  },
  EvaluatorAssignmentForm: {
    fieldKey: "MultifamilyEvaluationCompanyAssignmentForm",
    displayName: "Evaluator Assignment Form",
  },
  ComplianceForm: {
    fieldKey: "MultifamilyEvaluationComplianceForm",
    displayName: "Compliance Form",
  },
  FieldInspectionForm: {
    fieldKey: "MultifamilyEvaluationFieldInspectionForm",
    displayName: "Field Inspection Form",
  },
  ComplianceLetterForm: {
    fieldKey: "MultifamilyEvaluationComplianceLetterForm",
    displayName: "Compliance Letter Form",
  },
};
