import {
  EmailFieldValue,
  EnumMultiSelectFieldV2Value,
  EnumRadioFieldValue,
  EnumSingleSelectFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum HomeEligibilityFormParts {
  DesignationTypeAndLevelInformation = "designation-type-and-level-information",
  EligibilityInformation = "eligibility-information",
}

export enum NewOrExistingRoofTypes {
  NewRoof = 1,
  ExistingRoof = 2,
}

export enum DesignationLevelTypes {
  Roof = 1,
  Silver = 2,
  Gold = 3,
}

export enum BuildingElementTypes {
  GablesOver3Feet = 1,
  GableOverhangs = 2,
  Chimneys = 3,
  SingleLevelAttachedStructures = 4,
  MultiLevelAttachedStructures = 5,
  ContinuousLoadPath = 6,
  NoneOfTheAbove = 7,
}

export const requiredBuildingElementTypesForSilverAndGold = [
  BuildingElementTypes.GablesOver3Feet,
  BuildingElementTypes.GableOverhangs,
  BuildingElementTypes.Chimneys,
  BuildingElementTypes.SingleLevelAttachedStructures,
  BuildingElementTypes.MultiLevelAttachedStructures,
  BuildingElementTypes.ContinuousLoadPath,
];

export interface BaseHomeEvaluationEligibilityFormModel {
  newConstructionOrExistingHome?: EnumSingleSelectFieldValue;
  newOrExistingRoof?: EnumSingleSelectFieldValue;
  rooferEmail?: EmailFieldValue;
  hasHailSupplement?: EnumRadioFieldValue;
  designationLevel?: EnumSingleSelectFieldValue;
  buildingElementTypes?: EnumMultiSelectFieldV2Value;
  distanceToSaltWaterShorelineType?: EnumSingleSelectFieldValue;
  installingContractorEmail?: EmailFieldValue;
  isEngineerProvidingComplianceDesignInfoForSilverOrGoldRequirements?: EnumRadioFieldValue;
  engineerEmail?: EmailFieldValue;
  buildingElementsEngineerIsProvidingComplianceDesignInfoFor?: EnumMultiSelectFieldV2Value;
  continuousLoadPathPathwayType?: EnumSingleSelectFieldValue;
  dwellingType?: EnumSingleSelectFieldValue;
  dwellingTypePhotos?: WildfireBoxContentExplorerValue;
  isHomeOnADryStackOrHudFoundation?: EnumRadioFieldValue;
  isHomeConnectedToFoundation?: EnumRadioFieldValue;
  foundationEligibilityPhotos?: WildfireBoxContentExplorerValue;
  isProfessionalEngineerProvidingCAndCRoofUplifDesingPressures?: EnumRadioFieldValue;
  isProjectAHomeBuiltByNonProfit?: EnumRadioFieldValue;
  nonProfitEIN?: TextFieldValue;
  letterFromNonProfit?: WildfireBoxContentExplorerValue;
  isThisProjectReleatedToFloridaExistingHomesProject?: EnumRadioFieldValue;
  floridaExistingHomeProjectEligibilityDocuments?: WildfireBoxContentExplorerValue;
  otherDocuments?: WildfireBoxContentExplorerValue;
}
