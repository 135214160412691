import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationInstallerComplianceGablesFormParts } from "../../common/types/BaseHomeEvaluationInstallerComplianceGablesForm";
import HomeStandardInstallerComplianceGablesFormContainer from "../components/Forms/InstallerComplianceGablesForm/Container/HomeStandardInstallerComplianceGablesFormContainer";

export interface HomeStandardInstallerComplianceGablesPageProps {
  evaluationId?: string;
  installerComplianceGablesFormPart?: BaseHomeEvaluationInstallerComplianceGablesFormParts;
}

const HomeStandardInstallerComplianceGablesPageComponent: FC<
  HomeStandardInstallerComplianceGablesPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardInstallerComplianceGablesFormContainer
            evaluationId={props.evaluationId}
            formParts={props.installerComplianceGablesFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardInstallerComplianceGablesPage = withAITracking(
  reactPlugin,
  HomeStandardInstallerComplianceGablesPageComponent,
  "HomeStandardInstallerComplianceGablesPage",
  "tracking-container"
);
