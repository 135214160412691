import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { HomeNCIUAEvaluation_EvaluationFormModel } from "../../types/HomeNCIUAEvaluation_EvaluationFormModel";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../types/HomeNCIUAEvaluationFormModel";

class HomeNCIUAEvaluationFormAPIClient {
  updateHomeNCIUAEvaluationForm = async (
    evaluationId: string,
    homeNCIUAEvaluationForm: HomeNCIUAEvaluation_EvaluationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/evaluation-form/${evaluationId}`,
      {
        homeNCIUAEvaluationForm,
        fieldKey,
      }
    );
  };

  submitHomeNCIUAEvaluationForm = async (
    evaluationId: string,
    homeNCIUAEvaluationForm: HomeNCIUAEvaluation_EvaluationFormModel
  ): Promise<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return baseApiClient.post<Evaluation_HomeNCIUAEvaluationFormModel>(
      `/home-nciua/evaluation-form/${evaluationId}`,
      homeNCIUAEvaluationForm
    );
  };
}

export const homeNCIUAEvaluationFormAPIClient =
  new HomeNCIUAEvaluationFormAPIClient();
