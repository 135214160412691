import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../evaluation/types/HomeNCIUAEvaluationFormModel";

const modelName = "Evaluation";
const subModelName = "HomeNCIUAEvaluation";
const formName = "RoofingComplianceGeneralInformationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Basic Information
const basicInformationFieldsNCIUA = () => [
  buildFieldKey("RoofInstallationDate"),
  buildFieldKey("DistanceToSaltWaterShoreline"),
  buildFieldKey("NumberOfDifferentRoofCoverTypes"),
  buildFieldKey("WasPermitIssued"),
];

const basicInformationRequiredFieldsNCIUA = [
  buildFieldKey("RoofInstallationDate"),
  buildFieldKey("NumberOfDifferentRoofCoverTypes"),
  buildFieldKey("WasPermitIssued"),
];

export const homeStandardBasicInformationBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        basicInformationFieldsNCIUA(),
        onValueChange,
        form,
        errors,
        basicInformationRequiredFieldsNCIUA
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 2: Roof Structure
const roofStructureFieldsNCIUA = () => [
  buildFieldKey("MaxRoofStructuralMemberSpacing"),
];

const roofStructureRequiredFieldsNCIUA = [
  buildFieldKey("MaxRoofStructuralMemberSpacing"),
  buildFieldKey("MaximumRoofStructuralMemberSpacingPhoto"),
];

const roofstructurefileUploaderFieldsNCIUA = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("MaximumRoofStructuralMemberSpacingPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "MaximumRoofStructuralMemberSpacingPhoto.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardRoofStructureBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...roofStructureFieldsNCIUA(),
          ...roofstructurefileUploaderFieldsNCIUA(form, errors),
        ],
        onValueChange,
        form,
        errors,
        roofStructureRequiredFieldsNCIUA,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 3: Roof Sheathing
const roofSheathingFieldsNCIUA = () => [
  buildFieldKey("RoofSheathingType"),
  buildFieldKey("RoofSheathingTypeWhenOther"),
  buildFieldKey("MinimumRoofSheathingThickness"),
  buildFieldKey(
    "SelectedStandardDetailsThatMatchesInstalledSheathingAttachment"
  ),
  buildFieldKey(
    "DoesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements"
  ),
];

const roofSheathingRequiredFieldsNCIUA = [
  buildFieldKey("RoofSheathingType"),
  buildFieldKey("RoofSheathingTypeWhenOther"),
  buildFieldKey("MinimumRoofSheathingThickness"),
  buildFieldKey("MinimumRoofSheathingThicknessPhotos"),
  buildFieldKey("RoofSheathingFastenerTypePhoto"),
  buildFieldKey("RoofSheathingAttachmentPhoto"),
  buildFieldKey("RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation"),
  buildFieldKey(
    "SelectedStandardDetailsThatMatchesInstalledSheathingAttachment"
  ),
  buildFieldKey(
    "DoesRoofDeckAttachmentNotMatchFortifiedPrescribedRequirements"
  ),
];

const roofSheathingFileUploaderFieldsNCIUA = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("RoofSheathingFastenerTypePhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofSheathingFastenerTypePhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RoofSheathingAttachmentPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofSheathingAttachmentPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "RoofDeckAttachmentEngineeringAnalysisRequiredDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardRoofSheathingBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...roofSheathingFieldsNCIUA(),
          ...roofSheathingFileUploaderFieldsNCIUA(form, errors),
        ],
        onValueChange,
        form,
        errors,
        roofSheathingRequiredFieldsNCIUA,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 4: Drip Edge
const dripEdgeFieldsNCIUA = () => [
  buildFieldKey("AreInstalledDripEdgeNewAndCodeCompliantMaterials"),
  buildFieldKey("StandardDetailThatMatchesDripEdgeInstallation"),
  buildFieldKey(
    "WasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications"
  ),
  buildFieldKey("IsDripEdgeInstalled"),
];

const dripEdgeRequiredFieldsNCIUA = [
  buildFieldKey("AreInstalledDripEdgeNewAndCodeCompliantMaterials"),
  buildFieldKey("StandardDetailThatMatchesDripEdgeInstallation"),
  buildFieldKey("NewDripEdgeInstallationPhotos"),
  buildFieldKey(
    "WasNewDripEdgeAndRoofFlashingInstalledPerManufacturerSpecifications"
  ),
  buildFieldKey("IsDripEdgeInstalled"),
  buildFieldKey("ExistingDripEdgeInstallationPhotos"),
];

const dripEdgeFileUploaderFieldsNCIUA = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("NewDripEdgeInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "NewDripEdgeInstallationPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ExistingDripEdgeInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "ExistingDripEdgeInstallationPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardDripEdgeBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...dripEdgeFieldsNCIUA(),
          ...dripEdgeFileUploaderFieldsNCIUA(form, errors),
        ],
        onValueChange,
        form,
        errors,
        dripEdgeRequiredFieldsNCIUA,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

const skyLightsFieldsNCIUA = () => [
  buildFieldKey("AreSkylightsPresent"),
  buildFieldKey("SkylightExistsAndRatingsAndInstallationAreUnknown"),
  buildFieldKey("WereSkylightsInstalledPerManufacturerInstructions"),
  buildFieldKey("SkylightImpactRating"),
];

const skyLightsRequiredFieldsNCIUA = [
  buildFieldKey("AreSkylightsPresent"),
  buildFieldKey("SkylightExistsAndRatingsAndInstallationAreUnknown"),
  buildFieldKey("WereSkylightsInstalledPerManufacturerInstructions"),
  buildFieldKey("SkylightImpactRating"),
  buildFieldKey("SkylightHailRatingPhotos"),
  buildFieldKey("SkylightHailRatingDocumentation"),
];

const skylightFileUploaderFieldsNCIUA = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("SkylightHailRatingPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "SkylightHailRatingPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SkylightHailRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "SkylightHailRatingDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardSkyLightsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...skyLightsFieldsNCIUA(),
          ...skylightFileUploaderFieldsNCIUA(form, errors),
        ],
        onValueChange,
        form,
        errors,
        skyLightsRequiredFieldsNCIUA,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 6: Photo Voltaic Panels
const photoVoltaicPanelsFieldsNCIUA = () => [
  buildFieldKey("PVPanelImpactRating"),
];

const photoVoltaicPanelsRequiredFieldsNCIUA = [
  buildFieldKey("AreRoofMountedPhotoVoltaicPanelsPresent"),
  buildFieldKey("WerePVPanelsInstalledAndAnchoredPerAnchorageDetails"),
  buildFieldKey("PVAnchorageDocumentation"),
  buildFieldKey("PVPanelImpactRating"),
  buildFieldKey("PVHailRatingDocumentation"),
];

const photoVoltaicPanelsFileUploaderFieldsNCIUA = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("PVAnchorageDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "PVAnchorageDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("PVHailRatingDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "PVHailRatingDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardPhotoVoltaicPanelsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...photoVoltaicPanelsFieldsNCIUA(),
          ...photoVoltaicPanelsFileUploaderFieldsNCIUA(form, errors),
        ],
        onValueChange,
        form,
        errors,
        photoVoltaicPanelsRequiredFieldsNCIUA,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 7: Attic Vents
const atticVentsFieldsNCIUA = () => [
  buildFieldKey("AreRoofMountedVentsOrGableEndsPresent"),
  buildFieldKey("RoofVentilation"),
  buildFieldKey("DoAllRoofMountedVentsMeetTAS100A"),
  buildFieldKey("AreAllRoofMountedVentsInstalledPerManufacturerRequirements"),
  buildFieldKey("DoAllGableEndVentsHaveARemovablePerFortified2020HomeStandard"),
];

const atticVentsRequiredFieldsNCIUA = [
  buildFieldKey("AreRoofMountedVentsOrGableEndsPresent"),
  buildFieldKey("RoofVentilation"),
  buildFieldKey("DoAllRoofMountedVentsMeetTAS100A"),
  buildFieldKey("RoofVentilationPhoto"),
  buildFieldKey("AreAllRoofMountedVentsInstalledPerManufacturerRequirements"),
  buildFieldKey("DoAllGableEndVentsHaveARemovablePerFortified2020HomeStandard"),
  buildFieldKey("GableEndsVentsPhoto"),
];

const atticVentsFileUploaderFieldsNCIUA = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("RoofVentilationPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofVentilationPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("GableEndsVentsPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "GableEndsVentsPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardAtticVentsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...atticVentsFieldsNCIUA(),
          ...atticVentsFileUploaderFieldsNCIUA(form, errors),
        ],
        onValueChange,
        form,
        errors,
        atticVentsRequiredFieldsNCIUA,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 8: Permit Information
const permitInformationFieldsNCIUA = () => [
  buildFieldKey("PermitNumber"),
  buildFieldKey("PermitDate"),
  buildFieldKey("PermitIssuedBy"),
  buildFieldKey("DateOfFinalBldgDeptInspection"),
];

const permitInformationRequiredFieldsNCIUA = [
  buildFieldKey("PermitNumber"),
  buildFieldKey("PermitDate"),
  buildFieldKey("PermitIssuedBy"),
];

export const homeStandardPermitInformationBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        permitInformationFieldsNCIUA(),
        onValueChange,
        form,
        errors,
        permitInformationRequiredFieldsNCIUA
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 9: Site Conditions
const siteConditionsFieldsNCIUA = () => [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("ASCE7Edition"),
  buildFieldKey("UltimateSiteDesignWindSpeed"),
  buildFieldKey("SiteExposureCategory"),
  buildFieldKey("MeanRoofHeight"),
];

const siteConditionsRequiredFieldsNCIUA = [
  buildFieldKey("BuildingCodeAndEdition"),
  buildFieldKey("ASCE7Edition"),
  buildFieldKey("UltimateSiteDesignWindSpeed"),
  buildFieldKey("SiteExposureCategory"),
  buildFieldKey("MeanRoofHeight"),
];

export const homeStandardSiteConditionsBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        siteConditionsFieldsNCIUA(),
        onValueChange,
        form,
        errors,
        siteConditionsRequiredFieldsNCIUA
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Part 10: Sealed Roof Deck
const sealedRoofDeckFieldsNCIUA = () => [
  buildFieldKey("IsEntireRoofLowSloped"),
  buildFieldKey("SelectedStandardDetailsThatMatchesSealedRoofDeck"),
  buildFieldKey("DoesSealedRoofDeckNotMatchFortifiedPrescribedRequirements"),
];

const sealedRoofDeckRequiredFieldsNCIUA = [
  buildFieldKey("IsEntireRoofLowSloped"),
  buildFieldKey("SelectedStandardDetailsThatMatchesSealedRoofDeck"),
  buildFieldKey("DoesSealedRoofDeckNotMatchFortifiedPrescribedRequirements"),
  buildFieldKey("SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos"),
  buildFieldKey("SealedRoofDeck2PlyInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSelfAdheredMembraneInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSprayFoamInstallationPhotos"),
  buildFieldKey("SealedRoofDeckTapedSeamsWithTileInstallationPhotos"),
  buildFieldKey("SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos"),
  buildFieldKey("SealedRoofDeckUnderlaymentLabelPhotos"),
  buildFieldKey("SealedRoofDeckNoneOfTheAbovePhotos"),
  buildFieldKey("SealedRoofDeckClosedCellFoamDocumentation"),
  buildFieldKey("FullyInstalledRoofCoverPhotos"),
];

const sealedRoofDeckFileUploaderFieldsNCIUA = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckTapedSeamsAndUnderlaymentInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeck2PlyInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeck2PlyInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckSelfAdheredMembraneInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckSelfAdheredMembraneInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckSprayFoamInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckSprayFoamInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckTapedSeamsWithTileInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckTapedSeamsWithTileInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckSelfAdheredMembraneWithTileInstallationPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckUnderlaymentLabelPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckUnderlaymentLabelPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckNoneOfTheAbovePhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "SealedRoofDeckNoneOfTheAbovePhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("SealedRoofDeckClosedCellFoamDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SealedRoofDeckClosedCellFoamDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("FullyInstalledRoofCoverPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "FullyInstalledRoofCoverPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

export const homeStandardSealedRoofDeckBuilderNCIUA = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...sealedRoofDeckFieldsNCIUA(),
          ...sealedRoofDeckFileUploaderFieldsNCIUA(form, errors),
        ],
        onValueChange,
        form,
        errors,
        sealedRoofDeckRequiredFieldsNCIUA,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included
export const homeNCIUARoofingComplianceGeneralInformationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...basicInformationFieldsNCIUA(),
          ...permitInformationFieldsNCIUA(),
          ...roofStructureFieldsNCIUA(),
          ...roofSheathingFieldsNCIUA(),
          ...dripEdgeFieldsNCIUA(),
          ...skyLightsFieldsNCIUA(),
          ...photoVoltaicPanelsFieldsNCIUA(),
          ...atticVentsFieldsNCIUA(),
          ...siteConditionsFieldsNCIUA(),
          ...sealedRoofDeckFieldsNCIUA(),
        ],
        onValueChange,
        form,
        errors,
        [
          ...basicInformationRequiredFieldsNCIUA,
          ...permitInformationRequiredFieldsNCIUA,
          ...roofStructureRequiredFieldsNCIUA,
          ...roofSheathingRequiredFieldsNCIUA,
          ...dripEdgeRequiredFieldsNCIUA,
          ...skyLightsRequiredFieldsNCIUA,
          ...photoVoltaicPanelsRequiredFieldsNCIUA,
          ...atticVentsRequiredFieldsNCIUA,
          ...siteConditionsRequiredFieldsNCIUA,
          ...sealedRoofDeckRequiredFieldsNCIUA,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUAIterationEngineRoofingComplianceGeneralInformationFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
          modelName,
          [
            ...basicInformationFieldsNCIUA(),
            ...permitInformationFieldsNCIUA(),
            ...siteConditionsFieldsNCIUA(),
            ...roofStructureFieldsNCIUA(),
            ...roofstructurefileUploaderFieldsNCIUA(form, errors),
            ...roofSheathingFieldsNCIUA(),
            ...roofSheathingFileUploaderFieldsNCIUA(form, errors),
            ...sealedRoofDeckFieldsNCIUA(),
            ...sealedRoofDeckFileUploaderFieldsNCIUA(form, errors),
            ...dripEdgeFieldsNCIUA(),
            ...dripEdgeFileUploaderFieldsNCIUA(form, errors),
            ...atticVentsFieldsNCIUA(),
            ...atticVentsFileUploaderFieldsNCIUA(form, errors),
            ...photoVoltaicPanelsFieldsNCIUA(),
            ...photoVoltaicPanelsFileUploaderFieldsNCIUA(form, errors),
            ...skyLightsFieldsNCIUA(),
            ...skylightFileUploaderFieldsNCIUA(form, errors),
          ],
          onValueChange,
          form,
          errors,
          [
            ...basicInformationRequiredFieldsNCIUA,
            ...permitInformationRequiredFieldsNCIUA,
            ...siteConditionsRequiredFieldsNCIUA,
            ...roofStructureRequiredFieldsNCIUA,
            ...roofSheathingRequiredFieldsNCIUA,
            ...sealedRoofDeckRequiredFieldsNCIUA,
            ...dripEdgeRequiredFieldsNCIUA,
            ...atticVentsRequiredFieldsNCIUA,
            ...photoVoltaicPanelsRequiredFieldsNCIUA,
            ...skyLightsRequiredFieldsNCIUA,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
  };
