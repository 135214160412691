import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_HomeNCIUARedesignation } from "../../types/HomeNCIUARedesignation";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../types/HomeNCIUARedesignationFormModel_Model";

class HomeNCIUARedesignationAPIClient {
  createHomeNCIUARedesignation = async (evaluationId: string) => {
    return baseApiClient.post<Evaluation_HomeNCIUARedesignation>(
      `home-nciua/redesignation/${evaluationId}`
    );
  };

  getHomeNCIUARedesignation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUARedesignation>(
      `home-nciua/redesignation/${evaluationId}`
    );
  };

  getHomeNCIUARedesignationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeNCIUARedesignationFormModel>(
      `home-nciua/redesignation/${evaluationId}`
    );
  };
}

export const homeNCIUARedesignationAPIClient =
  new HomeNCIUARedesignationAPIClient();
