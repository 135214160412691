import { SingleCheckboxFieldValue } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationHomeownerAuthorizationFormParts {
  HomeownerAuthorization = "homeowner-authorization",
}

export interface BaseHomeEvaluationHomeownerAuthorizationForm
  extends IHomeownerAuthorization {}

export interface IHomeownerAuthorization {
  attestation?: SingleCheckboxFieldValue;
  homeownerAuthorizationForm?: WildfireBoxContentExplorerValue;
}
