import { withAITracking } from "@microsoft/applicationinsights-react-js";
import React, { useEffect, useState } from "react";
import "../styles.scss";
import { reactPlugin } from "../../../AppInsights";
import {
  errorStore,
  genericErrorMessage,
} from "../../../common/Components/Error/ErrorStore";
import "./styles.scss";
import { BackToReportsPageButton } from "../../components/Button/BackToReportsPageButton/BackToReportsPageButton";
import { ReportReadDTO } from "../../../common/types/reports";
import { wildfireReportApiClient } from "../../lib/apiClients/wildfireReportApiClient";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";

export interface WildfireReportViewerPageProps {
  slug: string | undefined;
}

export const WildfireReportViewerPageComponent: React.FC<
  WildfireReportViewerPageProps
> = ({ slug }: WildfireReportViewerPageProps) => {
  if (!slug) {
    errorStore.setErrorMessage(genericErrorMessage);
    return null;
  }

  const [isReportLoading, setIsReportLoading] = useState(true);
  const [report, setReport] = useState<ReportReadDTO>();

  useEffect(() => {
    wildfireReportApiClient
      .getReport(slug)
      .then((response) => {
        setReport(response);
      })
      .catch((error) => {
        console.log(error);
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsReportLoading(false);
      });
  }, []);

  return (
    <>
      <div className="wildfire-report-header">
        <BackToReportsPageButton />
        {report && <h1>{report.title}</h1>}
      </div>
      {isReportLoading || !report ? (
        <Loading />
      ) : (
        <div className="wildfire-report-viewer">
          <iframe
            title={report.title}
            width="100%"
            height="100%"
            src={report.reportLink}
            frameBorder="0"
          />
        </div>
      )}
    </>
  );
};

export const WildfireReportViewerPage = withAITracking(
  reactPlugin,
  WildfireReportViewerPageComponent,
  "WildfireReportViewerPage",
  "tracking-container"
);
