import { FieldFormV2, FieldSchemaFormBuilderV2 } from "@ucl/library";
import { NonIdealState } from "@blueprintjs/core";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { FCFMFEvaluationFieldInspectionFormParts } from "../../../../../common/types/Evaluation/BaseFCFMFEvaluationFieldInspectionForm";
import { Evaluation_MultifamilyRedesignationFormModel } from "../../../../types/MultifamilyRedesignationFormModel";
import useMultifamilyRedesignationFieldInspectionForm from "./useMultifamilyEvaluationFieldInspectionForm";

export interface MultifamilyRedesignationFieldInspectionFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  formPart?: FCFMFEvaluationFieldInspectionFormParts;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (
    formModel: Evaluation_MultifamilyRedesignationFormModel
  ) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
}

export const MultifamilyRedesignationFieldInspectionForm = (
  props: MultifamilyRedesignationFieldInspectionFormProps
) => {
  const {
    formRef,
    isLoading,
    isSubmitting,
    evaluationFieldInspectionFormModel,
    getEvaluationFieldInspectionFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
  } = useMultifamilyRedesignationFieldInspectionForm(props);

  return (
    <div ref={containerRef} className="wildfire-form-container">
      {noBuilders || isLoading ? (
        <Loading />
      ) : !evaluationFieldInspectionFormModel ? (
        <NonIdealState />
      ) : (
        <>
          <FieldFormV2<Evaluation_MultifamilyRedesignationFormModel>
            isDisabled={isSubmitting || props.disableForm}
            value={evaluationFieldInspectionFormModel}
            fieldSchemaFormBuilder={
              getEvaluationFieldInspectionFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_MultifamilyRedesignationFormModel>
            }
            onFormSubmit={async (form) => {
              await handleFormSubmit(form);
            }}
            ref={formRef}
            onFieldChanged={async (form, value, fieldProps) => {
              await onFormFieldChange(form, value, fieldProps);
            }}
          />
        </>
      )}
    </div>
  );
};

export default MultifamilyRedesignationFieldInspectionForm;
