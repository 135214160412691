import {
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
  SmartAddressFieldProps,
} from "@ucl/library";
import { addressApiClient } from "../../../../../../foritfied/lib/apiClients/address/addressApiClient";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../evaluation/types/HomeNCIUAEvaluationFormModel";

const modelName = "Evaluation";
const subModelName = "HomeNCIUAEvaluation";
const formName = "RooferConfirmationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const rooferConfirmationFields = () => [
  buildFieldKey("IsCertifiedRoofingContractorAndComplianceAccurate"),
  buildFieldKey("UnderstandsFraudulentInformationPenalties"),
  buildFieldKey("PrintedName"),
  buildFieldKey("CompanyName"),
  buildFieldKey("PhoneNumber"),
  buildFieldKey("LicenseOrRegistrationNumber"),
  buildFieldKey("FortifiedWiseRoofingConactorID"),
];

const rooferConfirmationFileUploaderFields = (
  _form: Evaluation_HomeNCIUAEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("SignedRoofingComplianceForms"),
    defaultToUpload: false,
    errorMessages: get(errors, "SignedRoofingComplianceForms.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const rooferConfirmationAddressDetailsFields = () => [
  {
    fieldKey: buildFieldKey("Address"),
    getAddressesByQuery: addressApiClient.getAddressesByQuery,
    getAddressByQuery: addressApiClient.getAddressByQuery,
    isSmartOnly: false,
  } as SmartAddressFieldProps,
];

const rooferConfirmationRequiredFields = [
  buildFieldKey("Address"),
  buildFieldKey("IsCertifiedRoofingContractorAndComplianceAccurate"),
  buildFieldKey("UnderstandsFraudulentInformationPenalties"),
  buildFieldKey("PrintedName"),
  buildFieldKey("CompanyName"),
  buildFieldKey("PhoneNumber"),
  buildFieldKey("LicenseOrRegistrationNumber"),
  buildFieldKey("FortifiedWiseRoofingConactorID"),
  buildFieldKey("SignedRoofingComplianceForms"),
];

export const rooferConfirmationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...rooferConfirmationFields(),
          ...rooferConfirmationAddressDetailsFields(),
          ...rooferConfirmationFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        rooferConfirmationRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included
export const rooferConfirmationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...rooferConfirmationFields(),
          ...rooferConfirmationAddressDetailsFields(),
          ...rooferConfirmationFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        rooferConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const rooferConfirmationNCIUAFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [
          ...rooferConfirmationFields(),
          ...rooferConfirmationAddressDetailsFields(),
          ...rooferConfirmationFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        rooferConfirmationRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};
