import {
  FieldSchemaFormBuilderConfigV2,
  FieldSchemaFormBuilderV2,
  SchemaFactoryV2,
} from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  HomeStandardBuilders,
  HomeStandardForms,
  HomeStandardFormsConfig,
} from "./homeStandardSchemaConfig.data";
import {
  HomeNCIUABuilders,
  HomeNCIUAForms,
  HomeNCIUAFormsConfig,
} from "./homeNCIUASchemaConfig.data";
import {
  HomeSCSHBasicInformationForm,
  HomeSCSHBuilders,
  HomeSCSHFormsConfig,
} from "./homeSCSHSchemaConfig.data";

export interface HomeFieldSchemaModel {}
export type HomeFieldSchemaFormType =
  typeof HomeFieldSchemaFormTypeList[number];
export type HomeFieldSchemaConfiguration = Record<
  HomeFieldSchemaFormType,
  FieldSchemaFormBuilderConfigV2 | undefined
>;

export const HomeFieldSchemaFormTypeList = [
  ...HomeStandardForms,
  ...HomeSCSHBasicInformationForm,
  ...HomeNCIUAForms,
] as const;

export const homeFieldSchemaConfig: HomeFieldSchemaConfiguration = {
  ...HomeStandardFormsConfig,
  ...HomeSCSHFormsConfig,
  ...HomeNCIUAFormsConfig,
};

export type FCFMFCustomFieldSchemaFormBuildersConfig = Record<
  HomeFieldSchemaFormType,
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ) => Partial<FieldSchemaFormBuilderV2<HomeFieldSchemaModel>>
>;

export const homeCustomFieldSchemaFormBuilders: FCFMFCustomFieldSchemaFormBuildersConfig =
  {
    ...HomeStandardBuilders,
    ...HomeSCSHBuilders,
    ...HomeNCIUABuilders,
  };
