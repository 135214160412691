import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_CommercialEvaluationFormModel } from "../../../types/CommercialEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import get from "lodash/get";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { CommercialFormNameTypes } from "../../../types/CommercialEvaluation";

const modelName = "Evaluation";
const subModelName = "CommercialEvaluation";
const formName = CommercialFormNameTypes.ComplianceLetterForm.fieldKey;

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const fileUploaderFields = (
  _form: Evaluation_CommercialEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("ComplianceLetterPhotoUploader"),
    defaultToUpload: false,
    errorMessages: get(errors, "complianceLetterPhotoUploader.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const complianceLetterPhotoUploaderRequiredFields = [
  buildFieldKey("ComplianceLetterPhotoUploader"),
];

export const commercialComplianceLetterBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    return factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
      modelName,
      [...fileUploaderFields(form, errors)],
      onValueChange,
      form,
      errors,
      [...complianceLetterPhotoUploaderRequiredFields],
      {
        FileUploader: WildfireBoxContentExplorer,
      }
    );
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};

export const commercialIterationEngineComplianceLetterBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_CommercialEvaluationFormModel>(
        modelName,
        [...fileUploaderFields(form, errors)],
        onValueChange,
        form,
        errors,
        [...complianceLetterPhotoUploaderRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_CommercialEvaluationFormModel>;
};
