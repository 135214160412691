import {
  DateFieldValue,
  EnumRadioFieldValue,
  NumericFieldValue,
  SingleCheckboxFieldValue,
  TextFieldValue,
} from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorerValue } from "../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

export enum BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts {
  InstallerComplianceFormAttachedStructureGeneralInformation = "installer-compliance-form-attached-structure-general-information",
  InstallerComplianceFormEngineeredAttachedStructure = "installer-compliance-form-engineered-attached-structure",
  InstallerComplianceFormPrescriptiveAttachedStructure = "installer-compliance-form-prescriptive-attached-structure",
}

export interface BaseHomeEvaluationInstallerComplianceAttachedStructureForm
  extends IInstallerComplianceAttachedStructureFormGeneralInformation,
    IInstallerComplianceFormEngineeredAttachedStructure,
    IInstallerComplianceFormPrescriptiveAttachedStructure {}

export interface IInstallerComplianceAttachedStructureFormGeneralInformation {
  permitNumber?: TextFieldValue;
  dateInstallationWasCompleted?: DateFieldValue;
}

export interface IInstallerComplianceFormEngineeredAttachedStructure {
  attestation292?: SingleCheckboxFieldValue;
  attestation293?: SingleCheckboxFieldValue;
  attestation294?: SingleCheckboxFieldValue;
  attestation298?: SingleCheckboxFieldValue;
  attestation299?: SingleCheckboxFieldValue;
  attestation3002?: SingleCheckboxFieldValue;
  attestation3012?: SingleCheckboxFieldValue;
  attestation3022?: SingleCheckboxFieldValue;
  attestation303?: SingleCheckboxFieldValue;
  attestation304?: SingleCheckboxFieldValue;
  attestation305?: SingleCheckboxFieldValue;
  attestation306?: SingleCheckboxFieldValue;
  attestation307?: SingleCheckboxFieldValue;
  attestation308?: SingleCheckboxFieldValue;
}

export interface IInstallerComplianceFormPrescriptiveAttachedStructure {
  areSingleLevelAttachedStructuresRetrofittedPerSection5Dot8?: EnumRadioFieldValue;
  singleLevelPrescriptiveAttachedStructureLoadsDocumentation?: WildfireBoxContentExplorerValue;
  attestation309?: SingleCheckboxFieldValue;
  upliftLoadForInsideColumnToFoundation?: NumericFieldValue;
  upliftLoadForInsideColumnToBeam?: NumericFieldValue;
  upliftLoadForCornerColumnToFoundation?: NumericFieldValue;
  upliftLoadForCornerColumnToBeam?: NumericFieldValue;
  upliftLoadForEachRoofMemberToBeam?: NumericFieldValue;
  manufacturerConnectorSelectedForInsideColumnToFoundation?: TextFieldValue;
  manufacturerConnectorSelectedForInsideColumnToBeam?: TextFieldValue;
  manufacturerConnectorSelectedForCornerColumnToFoundation?: TextFieldValue;
  manufacturerConnectorSelectedForCornerColumnToBeam?: TextFieldValue;
  manufacturerConnectorSelectedForEachRoofMemberToBeam?: TextFieldValue;
  attestation320?: SingleCheckboxFieldValue;
}
