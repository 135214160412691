import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts } from "../../common/types/BaseHomeEvaluationInstallerComplianceAttachedStructureForm";
import HomeStandardInstallerComplianceAttachedStructureFormContainer from "../components/Forms/InstallerComplianceAttachedStructureForm/Container/HomeStandardInstallerComplianceAttachedStructureFormContainer";

export interface HomeStandardInstallerComplianceAttachedStructurePageProps {
  evaluationId?: string;
  installerComplianceAttachedStructureFormPart?: BaseHomeEvaluationInstallerComplianceAttachedStructureFormParts;
}

const HomeStandardInstallerComplianceAttachedStructurePageComponent: FC<
  HomeStandardInstallerComplianceAttachedStructurePageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardInstallerComplianceAttachedStructureFormContainer
            evaluationId={props.evaluationId}
            formParts={props.installerComplianceAttachedStructureFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardInstallerComplianceAttachedStructurePage =
  withAITracking(
    reactPlugin,
    HomeStandardInstallerComplianceAttachedStructurePageComponent,
    "HomeStandardInstallerComplianceAttachedStructurePage",
    "tracking-container"
  );
