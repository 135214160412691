import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { NCIUAEvaluationForm_FormParts } from "../evaluation/types/HomeNCIUAEvaluation_EvaluationFormModel";
import HomeNCIUAEvaluationFormContainer from "../components/Forms/NCIUAEvaluationForm/Container/HomeNCIUAEvaluationFormContainer";

export interface HomeNCIUAEvaluationFormPageProps {
  evaluationId?: string;
  homeNCIUAEvaluationFormPart?: NCIUAEvaluationForm_FormParts;
}

const HomeNCIUAEvaluationFormPageComponent: FC<
  HomeNCIUAEvaluationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeNCIUAEvaluationFormContainer
            evaluationId={props.evaluationId}
            formPart={props.homeNCIUAEvaluationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeNCIUAEvaluationFormPage = withAITracking(
  reactPlugin,
  HomeNCIUAEvaluationFormPageComponent,
  "HomeNCIUAEvaluationFormPage",
  "tracking-container"
);
