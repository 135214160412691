import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { Evaluation_MultifamilyEvaluationFormModel } from "../../../../multifamily/types/MultifamilyEvaluationFormModel";
import { MultifamilyFormNameTypes } from "../../../types/MultifamilyEvaluation";

const modelName = "Evaluation";
const subModelName = "MultifamilyEvaluation";
const formName = MultifamilyFormNameTypes.FieldInspectionForm.fieldKey;

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const fieldInspectionFields = (
  _form: Evaluation_MultifamilyEvaluationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("FieldInspectionPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "fieldInspectionPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const fieldInspectionRequiredFields = [buildFieldKey("FieldInspectionPhotos")];

export const multifamilyEvaluationFieldInspectionFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_MultifamilyEvaluationFormModel>(
        modelName,
        fieldInspectionFields(form, errors),
        onValueChange,
        form,
        errors,
        fieldInspectionRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const multifamilyIterationEngineEvaluationFieldInspectionFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_MultifamilyEvaluationFormModel>(
        modelName,
        [...fieldInspectionFields(form, errors)],
        onValueChange,
        form,
        errors,
        [...fieldInspectionRequiredFields],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_MultifamilyEvaluationFormModel>;
};
