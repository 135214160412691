import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationInstallingContractorConfirmationFormParts } from "../../../common/types/BaseHomeEvaluationInstallingContractorConfirmationForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationInstallingContractorConfirmationFormModel } from "../../types/HomeStandardEvaluationInstallingContractorConfirmationFormModel";

class HomeStandardEvaluationInstallingContractorConfirmationAPIClient {
  updateHomeStandardInstallingContractorConfirmationForm = async (
    evaluationId: string,
    installingContractorConfirmationForm: HomeStandardEvaluationInstallingContractorConfirmationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installing-contractor-confirmation-form/${evaluationId}`,
      {
        installingContractorConfirmationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardInstallingContractorConfirmationForm = async (
    evaluationId: string,
    installingContractorConfirmationForm: HomeStandardEvaluationInstallingContractorConfirmationFormModel,
    formPart?: BaseHomeEvaluationInstallingContractorConfirmationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationInstallingContractorConfirmationFormParts
      ).pop() as BaseHomeEvaluationInstallingContractorConfirmationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/installing-contractor-confirmation-form/${evaluationId}/${formPartValue}`,
      installingContractorConfirmationForm
    );
  };
}

export const homeStandardEvaluationInstallingContractorConfirmationAPIClient =
  new HomeStandardEvaluationInstallingContractorConfirmationAPIClient();
