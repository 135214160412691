import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import HomeStandardRooferConfirmationFormContainer from "../components/Forms/RooferConfirmationForm/Container/HomeStandardRooferConfirmationFormContainer";
import { BaseHomeEvaluationRooferConfirmationFormParts } from "../../common/types/BaseHomeEvaluationRooferConfirmationForm";

export interface HomeStandardRooferConfirmationPageProps {
  evaluationId?: string;
  rooferConfirmationFormPart?: BaseHomeEvaluationRooferConfirmationFormParts;
}

const HomeStandardRooferConfirmationPageComponent: FC<
  HomeStandardRooferConfirmationPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeStandardRooferConfirmationFormContainer
            evaluationId={props.evaluationId}
            rooferConfirmationFormPart={props.rooferConfirmationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeStandardRooferConfirmationPage = withAITracking(
  reactPlugin,
  HomeStandardRooferConfirmationPageComponent,
  "HomeStandardRooferConfirmationPage",
  "tracking-container"
);
