import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { PaymentCreditResponseReadDTO } from "../../../../Common/types/PaymentCredit/PaymentCreditResponseReadDTO";
import { Evaluation_HomeSCSHEvaluation } from "../../types/HomeSCSHEvaluation";
import { Evaluation_HomeSCSHEvaluationFormModel } from "../../types/HomeSCSHEvaluationFormModel";

const baseHomeSCSHURL = "home-scsh/evaluation";

class HomeSCSHEvaluationAPIClient {
  createHomeSCSHEvaluation = async () => {
    return baseApiClient.post<Evaluation_HomeSCSHEvaluation>(
      `${baseHomeSCSHURL}`
    );
  };

  getHomeSCSHEvaluation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeSCSHEvaluation>(
      `${baseHomeSCSHURL}/${evaluationId}`
    );
  };

  getHomeSCSHEvaluationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeSCSHEvaluationFormModel>(
      `${baseHomeSCSHURL}/${evaluationId}`
    );
  };

  getApplicationFeePaymentCreditDetails = async (evaluationId: string) => {
    return await baseApiClient.get<PaymentCreditResponseReadDTO>(
      `${baseHomeSCSHURL}/${evaluationId}/payment/credit-details`
    );
  };

  applyApplicationFeePaymentCredit = async (evaluationId: string) => {
    return await baseApiClient.put<Evaluation_HomeSCSHEvaluation>(
      `${baseHomeSCSHURL}/${evaluationId}/payment/credit`
    );
  };

  getApplicationFeeStripeCheckoutLink = async (evaluationId: string) => {
    return await baseApiClient.get<string>(
      `${baseHomeSCSHURL}/${evaluationId}/payment/stripe-checkout`
    );
  };
}

export const homeSCSHEvaluationAPIClient = new HomeSCSHEvaluationAPIClient();
