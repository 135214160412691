import { EvaluationStep } from "../../../../../foritfied/types/evaluation/Evaluation";
import { ProceedToPaymentActionButtonV2 } from "../../../../Common/components/IterationEngine/ProceedToPaymentActionButtonV2";
import { ProductSubtypes } from "../../../../Common/types/Evaluation/BaseEvaluation";
import { homeSCSHEvaluationAPIClient } from "../../lib/apiClients/homeSCSHEvaluationAPIClient";
import { Evaluation_HomeSCSHEvaluation } from "../../types/HomeSCSHEvaluation";

interface HomeSCSHIterationEngineHeaderProps {
  evaluationId: string;
  evaluation: Evaluation_HomeSCSHEvaluation;
  setEvaluation: (evaluation: Evaluation_HomeSCSHEvaluation) => void;
}

const HomeSCSHIterationEngineHeader: React.FC<
  HomeSCSHIterationEngineHeaderProps
> = (props) => {
  console.log(props.evaluationId);

  return (
    <div className="wildfire-iteration-engine__header">
      {props.evaluation.step === EvaluationStep.Payment &&
        props.evaluation.isPaymentApplicable && (
          <ProceedToPaymentActionButtonV2
            evaluationId={props.evaluationId}
            fortifiedId={props.evaluation.fortifiedId || ""}
            productSubtype={ProductSubtypes.HomeSCSHEvaluation}
            evaluationStep={props.evaluation.step}
            getPaymentDetails={async () =>
              await homeSCSHEvaluationAPIClient.getApplicationFeePaymentCreditDetails(
                props.evaluationId
              )
            }
            setEvaluation={props.setEvaluation}
          />
        )}
      <div className="left-panel"></div>
      <div className="right-panel"></div>
    </div>
  );
};

export default HomeSCSHIterationEngineHeader;
