import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  WildfireBoxContentExplorer,
  WildfireBoxContentExplorerProps,
} from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { get } from "lodash";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../../evaluation/types/HomeNCIUARedesignationFormModel_Model";

const modelName = "Evaluation";
const subModelName = "HomeNCIUARedesignation";
const formName = "RedesignationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const redesignationFormFields = () => [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("HasTheRoofCoverBeenReplacedInLast5Years"),
  buildFieldKey("HasAnyPortionOfRoofBeenRepairedInLast5Years"),
  buildFieldKey("WereAnyStructuralMembersRepaired"),
  buildFieldKey("WereAnyRoofSheathingPanelsReplaced"),
  buildFieldKey("WasAnyPortionOfSealedRoofDeckRepaired"),
  buildFieldKey("WasAnyPortionOfDripEdgeReplaced"),
  buildFieldKey("WasAnyPortionOfRoofCoverReplaced"),
  buildFieldKey("DoesNewRoofCoverMeetHailImpactRequirements"),
  buildFieldKey("HaveThereBeenAnyStructuralAdditionsToHomeInLast5Years"),
  buildFieldKey("DoAnyNewAdditionsQualifyAsAttachedStructure"),
  buildFieldKey("RedesignationUpdatedSystemType"),
  buildFieldKey("IsCorrosionRustVisible"),
];

const redesignationFormFileUploaderFields = (
  _form: Evaluation_HomeNCIUARedesignationFormModel,
  errors?: { [key: string]: string[] }
) => [
  {
    fieldKey: buildFieldKey("OverallRedesignationElevationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "OverallRedesignationElevationPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RoofStructuralMemberRepairPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "RoofStructuralMemberRepairPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "SignedAndSealedRepairDesignFromProfessionalEngineer"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "SignedAndSealedRepairDesignFromProfessionalEngineer.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingTypeThicknessPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ReplacedRoofSheathingTypeThicknessPhoto.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingNailsPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "ReplacedRoofSheathingNailsPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofSheathingNailSpacingPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ReplacedRoofSheathingNailSpacingPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("RepairedSealedRoofDeckPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "RepairedSealedRoofDeckPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedUnderlaymentLabelPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "ReplacedUnderlaymentLabelPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("CertifiedDesignPressureReportForMetalPanelSystem"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "CertifiedDesignPressureReportForMetalPanelSystem.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedDripEdgePhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "ReplacedDripEdgePhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverOverallSectionsPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ReplacedRoofCoverOverallSectionsPhoto.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverInstallationPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "ReplacedRoofCoverInstallationPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverProductLabelPhoto"),
    defaultToUpload: false,
    errorMessages: get(errors, "ReplacedRoofCoverProductLabelPhoto.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ReplacedRoofCoverHailImpactRatingPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ReplacedRoofCoverHailImpactRatingPhoto.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StructuralAdditionsRenovationsPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "StructuralAdditionsRenovationsPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("StructuralAdditionsRenovationsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "StructuralAdditionsRenovationsDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedVentsOrSoffitsPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ModifiedRoofMountedVentsOrSoffitsPhoto.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedVentsOrSoffitsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ModifiedRoofMountedVentsOrSoffitsDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedRoofMountedPhotovoltaicPVSystemPhoto"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ModifiedRoofMountedPhotovoltaicPVSystemPhoto.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey(
      "ModifiedRoofMountedPhotovoltaicPVSystemDocumentation"
    ),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ModifiedRoofMountedPhotovoltaicPVSystemDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedSkylightsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedSkylightsPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedSkylightsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedSkylightsDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedEntryDoorsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedEntryDoorsPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedEntryDoorsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedEntryDoorsDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedWindowsPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedWindowsDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedGarageDoorsPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedGarageDoorsPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedGarageDoorsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(errors, "ModifiedGarageDoorsDocumentation.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowAndDoorShutterSystemsPhotos"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ModifiedWindowAndDoorShutterSystemsPhotos.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("ModifiedWindowAndDoorShutterSystemsDocumentation"),
    defaultToUpload: false,
    errorMessages: get(
      errors,
      "ModifiedWindowAndDoorShutterSystemsDocumentation.FileCount"
    ),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
  {
    fieldKey: buildFieldKey("VisibleCorrosionPhotos"),
    defaultToUpload: false,
    errorMessages: get(errors, "VisibleCorrosionPhotos.FileCount"),
    canDelete: false,
  } as WildfireBoxContentExplorerProps,
];

const redesignationFormRequiredFields = [
  buildFieldKey("DesignationLevel"),
  buildFieldKey("HasTheRoofCoverBeenReplacedInLast5Years"),
  buildFieldKey("HasAnyPortionOfRoofBeenRepairedInLast5Years"),
  buildFieldKey("WereAnyStructuralMembersRepaired"),
  buildFieldKey("WereAnyRoofSheathingPanelsReplaced"),
  buildFieldKey("WasAnyPortionOfSealedRoofDeckRepaired"),
  buildFieldKey("WasAnyPortionOfDripEdgeReplaced"),
  buildFieldKey("WasAnyPortionOfRoofCoverReplaced"),
  buildFieldKey("DoesNewRoofCoverMeetHailImpactRequirements"),
  buildFieldKey("HaveThereBeenAnyStructuralAdditionsToHomeInLast5Years"),
  buildFieldKey("DoAnyNewAdditionsQualifyAsAttachedStructure"),
  buildFieldKey("RedesignationUpdatedSystemType"),
  buildFieldKey("IsCorrosionRustVisible"),
];

export const redesignationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        [
          ...redesignationFormFields(),
          ...redesignationFormFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};

// Full form builder with all parts included
export const homeNCIUARedesignationFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        [
          ...redesignationFormFields(),
          ...redesignationFormFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUARedesignationFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUARedesignationFormModel>(
        modelName,
        [
          ...redesignationFormFields(),
          ...redesignationFormFileUploaderFields(form, errors),
        ],
        onValueChange,
        form,
        errors,
        redesignationFormRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUARedesignationFormModel>;
};
