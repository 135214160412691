import {
  FieldFormV2,
  FieldSchemaFormBuilderV2,
  RelativityTooltip,
} from "@ucl/library";
import { Button, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathForm";
import useHomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm from "./useHomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm";

export interface HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormProps {
  evaluationId: string;
  isIterationEnginePage?: boolean;
  formPart?: BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts;
  onFormSave?: (fieldKey: string, value: any) => Promise<void>;
  setFormModel?: (
    formModel: Evaluation_HomeStandardEvaluationFormModel
  ) => void;
  disableForm?: boolean;
  onInitialized?: () => void;
  setHasUnsavedChanges: (value: boolean) => void;
  hideFormControls?: boolean;
}

export const HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm =
  (
    props: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormProps
  ) => {
    const {
      formRef,
      isLoading,
      isSubmitting,
      setIsSubmitting,
      applicationFormModel,
      isFirstPartOfForm,
      isLastPartOfForm,
      getApplicationFormPartBuilder,
      handleFormSubmit,
      onFormFieldChange,
      noBuilders,
      containerRef,
      setShouldSubmit,
    } =
      useHomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm(
        props
      );

    const navigate = useNavigate();

    return (
      <div ref={containerRef} className="wildfire-form-container">
        {noBuilders || isLoading ? (
          <Loading />
        ) : !applicationFormModel ? (
          <NonIdealState />
        ) : (
          <>
            <FieldFormV2<Evaluation_HomeStandardEvaluationFormModel>
              isDisabled={isSubmitting || props.disableForm}
              value={applicationFormModel}
              fieldSchemaFormBuilder={
                getApplicationFormPartBuilder() as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>
              }
              onFormSubmit={async (form) => {
                await handleFormSubmit(form);
              }}
              ref={formRef}
              onFieldChanged={async (form, value, fieldProps) => {
                await onFormFieldChange(form, value, fieldProps);
              }}
            />
            {!props.hideFormControls && (
              <div className="wildfire-form-footer">
                {!isFirstPartOfForm && (
                  <Button
                    className={
                      "wildfire-form-back-button " +
                      "wildfire-form-navigation-button"
                    }
                    text="Back"
                    icon={IconNames.ARROW_LEFT}
                    onClick={() => {
                      // Navigate to the previous part of the form
                      const previousFormPart = Object.values(
                        BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
                      )[
                        Object.values(
                          BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
                        ).indexOf(props.formPart!) - 1
                      ].valueOf();
                      navigate(
                        `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/engineering-compliance-continuous-load-path/${previousFormPart}`
                      );
                    }}
                  />
                )}
                {!isLastPartOfForm && (
                  <RelativityTooltip shouldShowTooltip={false} content={""}>
                    <Button
                      text={"Continue"}
                      rightIcon={IconNames.ARROW_RIGHT}
                      loading={isSubmitting}
                      onClick={() => {
                        if (formRef.current) {
                          props.setHasUnsavedChanges(false);
                          setShouldSubmit(true);
                          setIsSubmitting(true);
                          formRef.current.submit();
                        }
                      }}
                    />
                  </RelativityTooltip>
                )}
                {isLastPartOfForm && (
                  <RelativityTooltip shouldShowTooltip={false} content={""}>
                    <Button
                      text={
                        "Submit Engineering Compliance Continuous Load Path Form"
                      }
                      rightIcon={IconNames.ARROW_RIGHT}
                      loading={isSubmitting}
                      onClick={() => {
                        if (formRef.current) {
                          props.setHasUnsavedChanges(false);
                          setShouldSubmit(true);
                          setIsSubmitting(true);
                          formRef.current.submit();
                        }
                      }}
                    />
                  </RelativityTooltip>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

export default HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm;
