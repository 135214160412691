import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { Evaluation_HomeStandardRedesignation } from "../../types/HomeStandardRedesignation";
import { Evaluation_HomeStandardRedesignationFormModel } from "../../types/HomeStandardRedesignationFormModel";

class HomeStandardRedesignationAPIClient {
  createHomeStandardRedesignation = async (evaluationId: string) => {
    return baseApiClient.post<Evaluation_HomeStandardRedesignation>(
      `home-standard/redesignation/${evaluationId}`
    );
  };

  getHomeStandardRedesignation = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardRedesignation>(
      `home-standard/redesignation/${evaluationId}`
    );
  };

  getHomeStandardRedesignationFormModel = async (evaluationId: string) => {
    return await baseApiClient.get<Evaluation_HomeStandardRedesignationFormModel>(
      `home-standard/redesignation/${evaluationId}`
    );
  };
}

export const homeStandardRedesignationAPIClient =
  new HomeStandardRedesignationAPIClient();
