import { useEffect, useState } from "react";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../common/Components/Error/ErrorStore";
import { commercialRedesignationAPIClient } from "../lib/apiClients/commercialRedesignationAPIClient";
import { Evaluation_CommercialRedesignation } from "../types/CommercialRedesignation";

export const useCommercialRedesignation = (evaluationId: string) => {
  const [commercialRedesignation, setCommercialRedesignation] =
    useState<Evaluation_CommercialRedesignation | null>();
  const [isApplicationLoading, setIsApplicationLoading] = useState(false);

  useEffect(() => {
    getCommercialEvaluation(evaluationId);
  }, [evaluationId]);

  const getCommercialEvaluation = async (evaluationId: string) => {
    await commercialRedesignationAPIClient
      .getCommercialEvaluation(evaluationId)
      .then((response) => {
        setCommercialRedesignation(response);
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      });
  };

  const refreshCommercialRedesignation = async () => {
    setIsApplicationLoading(true);
    if (commercialRedesignation?.id) {
      await commercialRedesignationAPIClient
        .getCommercialEvaluation(commercialRedesignation?.id)
        .then((response) => {
          setCommercialRedesignation(response);
          //setWildfireEvaluationHeader(response);
        })
        .catch(() => {
          errorStore.setErrorMessage(genericErrorMessage);
        })
        .finally(() => {
          setIsApplicationLoading(false);
        });
    }
  };

  return {
    commercialRedesignation,
    refreshCommercialRedesignation,
    isApplicationLoading,
    setCommercialRedesignation,
  };
};
