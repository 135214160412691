import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceGablesForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

const engineeringComplianceGablesFields = () => [
  buildFieldKey("HowAreGableEndWallsFramed"),
  buildFieldKey("Attestation207"),
  buildFieldKey("Attestation208"),
  buildFieldKey("Attestation209"),
  buildFieldKey("Attestation210"),
  buildFieldKey("GableConnectedBraceTopType"),
  buildFieldKey("Attestation212"),
  buildFieldKey("Attestation213"),
  buildFieldKey("Attestation214"),
  buildFieldKey("GableConnectedBraceBottomType"),
  buildFieldKey("Attestation216"),
  buildFieldKey("GableStudsBracedType"),
  buildFieldKey("Attestation218"),
];

const engineeringComplianceGablesRequiredFields = [
  buildFieldKey("HowAreGableEndWallsFramed"),
  buildFieldKey("Attestation207"),
  buildFieldKey("Attestation208"),
  buildFieldKey("Attestation209"),
  buildFieldKey("Attestation210"),
  buildFieldKey("GableConnectedBraceTopType"),
  buildFieldKey("Attestation212"),
  buildFieldKey("Attestation213"),
  buildFieldKey("Attestation214"),
  buildFieldKey("GableConnectedBraceBottomType"),
  buildFieldKey("Attestation216"),
  buildFieldKey("GableStudsBracedType"),
  buildFieldKey("Attestation218"),
];

// Full form builder with all parts included
export const engineeringComplianceGablesFormBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [...engineeringComplianceGablesFields()],
        onValueChange,
        form,
        errors,
        engineeringComplianceGablesRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const engineeringComplianceGablesFormIterationEngineBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [...engineeringComplianceGablesFields()],
        onValueChange,
        form,
        errors,
        engineeringComplianceGablesRequiredFields,
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};
