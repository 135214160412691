import {
  Footer,
  RichTextEditorField,
  RichTextEditorValueType,
} from "@ucl/library";
import { FC, useEffect, useState } from "react";
import "./styles.scss";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";
import { ToolbarSettingsModel } from "@syncfusion/ej2-dropdowns";

export interface NewGridCommentFormProps {
  onCancel: () => void;
  onSubmit: (comment: string) => Promise<void>;
}

export const NewGridCommentForm: FC<NewGridCommentFormProps> = ({
  onCancel,
  onSubmit,
}: NewGridCommentFormProps) => {
  const [localComment, setLocalComment] = useState<string>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setLocalComment(undefined);
    };
  }, []);

  return (
    <div className="wildfire-new-grid-comment-form">
      <div className="wildfire-new-grid-comment-form__content">
        <div className="disclaimer">
          We appreciate your patience as we address your question. Please review
          our{" "}
          <a
            href="https://wildfireprepared.org/frequently-asked-questions"
            target="_blank"
            rel="noreferrer"
          >
            Frequently Asked Questions
          </a>
          .
        </div>
        <RichTextEditorField
          valueType={RichTextEditorValueType.html}
          value={localComment}
          disabled={isSaving}
          onSubmit={(value) => {
            if (!!value) {
              setLocalComment(value);
            }
          }}
          richTextEditorSettings={{
            height: "160px",
            placeholder: "Add a new comment...",
            enableHtmlSanitizer: true,
            autoSaveOnIdle: true,
            saveInterval: 50,
            toolbarSettings: {
              items: [
                "OrderedList",
                "UnorderedList",
                "Bold",
                "Underline",
                "Italic",
                "StrikeThrough",
                "Formats",
                "ClearFormat",
              ],
            } as ToolbarSettingsModel,
            pasteCleanupSettings: {
              prompt: false,
              plainText: false,
              keepFormat: true,
              allowedStyleProps: [""],
              deniedAttrs: ["class", "id", "style"],
            },
          }}
        />
      </div>

      <Footer>
        <WildfireSecondaryButton
          buttonText="Close"
          disabled={isSaving}
          onClick={onCancel}
        />
        <WildfireSubmitButton
          buttonText="Submit"
          disabled={!localComment}
          isLoading={isSaving}
          onClick={async () => {
            if (localComment) {
              setIsSaving(true);
              await onSubmit(localComment);
              setLocalComment("");
              setIsSaving(false);
            }
          }}
        />
      </Footer>
    </div>
  );
};
