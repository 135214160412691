import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../evaluation/types/HomeNCIUAEvaluationFormModel";

const modelName = "Evaluation";
const subModelName = "HomeNCIUAEvaluation";
const formName = "HomeNCIUAEvaluationForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

//Part 1: Homeowner Information
const informationFields = () => [
  buildFieldKey("MultiUnitAppId"),
  buildFieldKey("BuilderName"),
  buildFieldKey("Latitude"),

  buildFieldKey("Longitude"),
  buildFieldKey("IsRemodeled"),
  buildFieldKey("RemodeledDescription"),
  buildFieldKey("YearBuilt"),

  buildFieldKey("EvaluationDate"),
  buildFieldKey("RoofCoveringInstallationYear"),
  buildFieldKey("RoofCoveringType"),
  buildFieldKey("DwellingType"),
];

export const homeNCIUAEvaluationInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        informationFields(),
        onValueChange,
        form,
        errors,
        []
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

export const homeNCIUAEvaluationFormInformationBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [...informationFields()],
        onValueChange,
        form,
        errors,
        [],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeNCIUAIterationEngineEvaluationFormBuilder = (
  factory: SchemaFactoryV2,
  beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
): FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    let fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeNCIUAEvaluationFormModel>(
        modelName,
        [...informationFields()],
        onValueChange,
        form,
        errors,
        [],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    if (beforeBuild) {
      fields = beforeBuild(fields);
    }
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeNCIUAEvaluationFormModel>;
};
