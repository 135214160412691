import baseApiClient from "../../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationRedesignationFormParts } from "../../../../common/types/BaseHomeEvaluationRedesignationForm";
import { HomeNCIUARedesignationFormModel } from "../../types/HomeNCIUARedesignationFormModel";
import { Evaluation_HomeNCIUARedesignationFormModel } from "../../types/HomeNCIUARedesignationFormModel_Model";

class HomeNCIUARedesignationFormAPIClient {
  updateHomeNCIUARedesignationForm = async (
    evaluationId: string,
    redesignationForm: HomeNCIUARedesignationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeNCIUARedesignationFormModel> => {
    return baseApiClient.put<Evaluation_HomeNCIUARedesignationFormModel>(
      `/home-nciua/redesignation/redesignation-form/${evaluationId}`,
      {
        redesignationForm,
        fieldKey,
      }
    );
  };

  submitHomeNCIUARedesignationForm = async (
    evaluationId: string,
    redesignationForm: HomeNCIUARedesignationFormModel,
    formPart?: BaseHomeEvaluationRedesignationFormParts
  ): Promise<Evaluation_HomeNCIUARedesignationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationRedesignationFormParts
      ).pop() as BaseHomeEvaluationRedesignationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeNCIUARedesignationFormModel>(
      `/home-nciua/redesignation/redesignation-form/${evaluationId}/${formPartValue}`,
      redesignationForm
    );
  };
}

export const homeNCIUARedesignationFormAPIClient =
  new HomeNCIUARedesignationFormAPIClient();
