import { PrimaryCard } from "@ucl/library";
import { EvaluationStep } from "../../../../../../foritfied/types/evaluation/Evaluation";
import { useContext, useEffect, useState } from "react";
import { UnsavedChangesBlockerContext } from "../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import classNames from "classnames";
import MultifamilyApplicationForm from "../ApplicationForm/Form/MultifamilyApplicationForm";
import MultifamilyEvaluationCompanyAssignmentForm from "../EvaluationCompanyAssignmentForm/Form/MultifamilyEvaluationCompanyAssignmentForm";
import MultifamilyEvaluationFieldInspectionForm from "../EvaluationFieldInspectionForm/Form/MultifamilyEvaluationFieldInspectionForm";
import MultifamilyComplianceForm from "../ComplianceForm/Form/MultifamilyComplianceForm";
import MultifamilyComplianceLetter from "../ComplianceLetter/Form/MultifamilyComplianceLetter";
import {
  Evaluation_MultifamilyEvaluation,
  MultifamilyFormNameTypes,
} from "../../../types/MultifamilyEvaluation";

interface MultifamilyIterationEngineFormsProps {
  evaluationId: string;
  evaluationStep: EvaluationStep;
  onFormSave: (fieldKey: string) => Promise<void>;
  onAllFormsInitialized: () => void;
  fieldsContainerClassName: string;
  selectedTabId: string;
  evaluation: Evaluation_MultifamilyEvaluation;
}

const MultifamilyIterationEngineForms: React.FC<
  MultifamilyIterationEngineFormsProps
> = (props) => {
  //const loadEvaluationForms = props.evaluationStep === EvaluationStep.FinalReview;
  const [applicationFormInitiaized, setApplicationFormInitiaized] =
    useState<boolean>();
  const [
    evaluationCompanyAssignmentFormInitiaized,
    setEvaluationCompanyAssignmentFormIntialized,
  ] = useState<boolean>();
  const [
    evaluationFieldInspectionFormIntialized,
    setEvaluationFieldInspectionFormIntialized,
  ] = useState<boolean>();

  const [complianceFormInitialized, setComplianceFormInitialized] =
    useState<boolean>(false);

  const [complianceLetterInitialized, setComplianceLetterInitialized] =
    useState<boolean>(false);

  const [allFormsInitialized, setAllFormsInitialized] = useState(false);

  useEffect(() => {
    const formsInitialized =
      applicationFormInitiaized ||
      evaluationCompanyAssignmentFormInitiaized ||
      evaluationFieldInspectionFormIntialized ||
      applicationFormInitiaized ||
      complianceFormInitialized ||
      complianceLetterInitialized;

    if (formsInitialized) {
      setTimeout(() => {
        setAllFormsInitialized(true);
        props.onAllFormsInitialized();
      }, 600);
    } else {
      setAllFormsInitialized(false);
    }
  }, [
    applicationFormInitiaized,
    evaluationCompanyAssignmentFormInitiaized,
    evaluationFieldInspectionFormIntialized,
    complianceFormInitialized,
    complianceLetterInitialized,
  ]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const getFormAndHeaderContent = () => {
    switch (props.selectedTabId) {
      case MultifamilyFormNameTypes.ComplianceForm.fieldKey:
        return {
          headerContent: "Multifamily Compliance Form",
          form: (
            <MultifamilyComplianceForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setComplianceFormInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case MultifamilyFormNameTypes.ComplianceLetterForm.fieldKey:
        return {
          headerContent: "Multifamily Compliance Letter",
          form: (
            <MultifamilyComplianceLetter
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setComplianceLetterInitialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case MultifamilyFormNameTypes.EvaluatorAssignmentForm.fieldKey:
        return {
          headerContent: "Multifamily Evaluation Evaluator Assignment Form",
          form: (
            <MultifamilyEvaluationCompanyAssignmentForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEvaluationCompanyAssignmentFormIntialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      case MultifamilyFormNameTypes.FieldInspectionForm.fieldKey:
        return {
          headerContent: "Multifamily Evaluation Field Inspection Form",
          form: (
            <MultifamilyEvaluationFieldInspectionForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setEvaluationFieldInspectionFormIntialized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
      default:
        return {
          headerContent: "Multifamily Application Form",
          form: (
            <MultifamilyApplicationForm
              evaluationId={props.evaluationId}
              isIterationEnginePage={true}
              hideFormControls={true}
              onInitialized={() => {
                setApplicationFormInitiaized(true);
              }}
              onFormSave={async (fieldKey) => {
                props.onFormSave(fieldKey);
              }}
              setHasUnsavedChanges={setHasUnsavedChanges}
            />
          ),
        };
    }
  };

  const { headerContent, form } = getFormAndHeaderContent();

  return (
    <>
      {!allFormsInitialized && <Loading />}
      <div
        className={classNames(props.fieldsContainerClassName, {
          "evaluation-forms-container-initialized": allFormsInitialized,
        })}
      >
        <PrimaryCard headerContent={headerContent}>{form}</PrimaryCard>
      </div>
    </>
  );
};

export default MultifamilyIterationEngineForms;
