import { Schema } from "@ucl/library";
import { useContext, useEffect, useState } from "react";
import wildfireBaseApiClient from "../../../../../../../wildfire/lib/wildfireBaseApiClient";
import { UnsavedChangesBlockerContext } from "../../../../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import {
  FormChecklistPart,
  FormChecklistStatus,
} from "../../../../../../../common/types/Forms/FormChecklist";
import { HomeSchemaFactoryV2 } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeRoofingComplianceRoofCoverFormParts } from "../../../../../common/types/BaseHomeEvaluationRoofingComplianceRoofCoverForm";
import { HomeNCIUARoofingRoofCoverFormContainerProps } from "./HomeNCIUARoofingComplianceRoofCoverContainer";
import { Evaluation_HomeNCIUAEvaluationFormModel } from "../../../../evaluation/types/HomeNCIUAEvaluationFormModel";

function useHomeNCIUARoofingComplianceRoofCoverFormContainer(
  props: HomeNCIUARoofingRoofCoverFormContainerProps
) {
  const [schemaFactory, setSchemaFactory] = useState<HomeSchemaFactoryV2>();

  const getSchema = async () => {
    const schema = await wildfireBaseApiClient.get<Schema>(`/wildfire/schema`);
    const newSchemaFactory = new HomeSchemaFactoryV2(schema);
    setSchemaFactory(newSchemaFactory);
  };

  useEffect(() => {
    if (!schemaFactory) {
      getSchema();
    }
  }, []);

  useEffect(() => {
    setFormPart(getBestFormPart());
  }, [props.formParts]);

  const { setHasUnsavedChanges } = useContext(UnsavedChangesBlockerContext);

  const [formPart, setFormPart] = useState<
    BaseHomeRoofingComplianceRoofCoverFormParts | undefined
  >(props.formParts);

  const [evaluationFormModel, setEvaluationFormModel] =
    useState<Evaluation_HomeNCIUAEvaluationFormModel>();

  const formModel =
    evaluationFormModel?.homeNCIUAEvaluation.roofingComplianceRoofCoverForm;

  const getNextUnsubmittedFormPart = () => {
    if (!formModel) {
      return null;
    }

    if (!formModel.isRoofCoverInformationComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation;
    } else if (!formModel.isAsphaltShingleRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails;
    } else if (!formModel.isMetalRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails;
    } else if (!formModel.isClayConcreteTileRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails;
    } else if (!formModel.isWoodShakeSingleRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails;
    } else if (!formModel.isLowSlopeRoofCoverDetailsComplete) {
      return BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails;
    }

    // Form is complete
    return null;
  };

  const getBestFormPart = (): BaseHomeRoofingComplianceRoofCoverFormParts => {
    // Default to the first part of the form if we can't find a better option
    const defaultFormPart = Object.values(
      BaseHomeRoofingComplianceRoofCoverFormParts
    )[0];

    // If the application form part is provided (by URL), use it
    if (!!props.formParts) {
      return props.formParts;
    }

    // Otherwise, try to find the first unsubmitted part of the form
    // TODO: This doesn't work yet, needs to wait for applicationFormModel to load
    return getNextUnsubmittedFormPart() ?? defaultFormPart;
  };

  const getApplicationPartChecklistStatus = (
    formPart: BaseHomeRoofingComplianceRoofCoverFormParts
  ): FormChecklistStatus => {
    if (formPart === getNextUnsubmittedFormPart()) {
      return FormChecklistStatus.NextUp;
    }

    switch (formPart) {
      case BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation:
        return formModel?.isRoofCoverInformationComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails:
        return formModel?.isAsphaltShingleRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails:
        return formModel?.isMetalRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails:
        return formModel?.isClayConcreteTileRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails:
        return formModel?.isWoodShakeSingleRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      case BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails:
        return formModel?.isLowSlopeRoofCoverDetailsComplete
          ? FormChecklistStatus.Submitted
          : FormChecklistStatus.Unsubmitted;
      default:
        return FormChecklistStatus.Unsubmitted;
    }
  };

  const checklistParts: FormChecklistPart<BaseHomeRoofingComplianceRoofCoverFormParts>[] =
    [
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.RoofCoverInformation,
        displayName: "Roof Cover Information",
        requiredFieldCount: formModel?.roofCoverInformationRequiredFieldCount,
        disabled: formModel?.isRoofCoverInformationComplete !== true,
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.AsphaltShingleRoofCoverDetails,
        displayName: "Ashpalt Shingle Roof Cover Details",
        requiredFieldCount:
          formModel?.asphaltShingleRoofCoverDetailsRequiredFieldCount,
        disabled: formModel?.isAsphaltShingleRoofCoverDetailsComplete !== true,
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.MetalRoofCoverDetails,
        displayName: "Metal Roof Cover Details",
        requiredFieldCount: formModel?.metalRoofCoverDetailsRequiredFieldCount,
        disabled: formModel?.isMetalRoofCoverDetailsComplete !== true,
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.ClayConcreteTileRoofCoverDetails,
        displayName: "Roof Structure",
        requiredFieldCount:
          formModel?.clayConcreteTileRoofCoverDetailsRequiredFieldCount,
        disabled:
          formModel?.isClayConcreteTileRoofCoverDetailsComplete !== true,
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.WoodShakeSingleRoofCoverDetails,
        displayName: "Roof Sheathing",
        requiredFieldCount:
          formModel?.woodShakeSingleRoofCoverDetailsRequiredFieldCount,
        disabled: formModel?.isWoodShakeSingleRoofCoverDetailsComplete !== true,
      },
      {
        formPart:
          BaseHomeRoofingComplianceRoofCoverFormParts.LowSlopeRoofCoverDetails,
        displayName: "Sealed Roof Deck",
        requiredFieldCount:
          formModel?.lowSlopeRoofCoverDetailsRequiredFieldCount,
        disabled: formModel?.isLowSlopeRoofCoverDetailsComplete !== true,
      },
    ];

  return {
    applicationChecklistParts: checklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    formPart,
    getBestFormPart,
    schemaFactory,
  };
}

export default useHomeNCIUARoofingComplianceRoofCoverFormContainer;
