import { FieldFormV2Module } from "@ucl/library";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import {
  errorStore,
  genericErrorMessage,
} from "../../../../../../../common/Components/Error/ErrorStore";
import { AppToaster } from "@ucl/library/lib/components/Toast/Toast";
import { Intent } from "@blueprintjs/core";
import { useNavigate } from "react-router";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../../types/HomeEvaluationFormModel";
import { homeStandardEvaluationAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationAPIClient";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { useHomeFieldSchemaFactory } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormProps } from "./HomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm";
import { BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceContinuousLoadPathForm";
import { homeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient } from "../../../../lib/apiClients/homeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient";

function useHomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm(
  props: HomeStandardEvaluationEngineeringComplianceContinuousLoadPathFormProps
) {
  const { builders, wieBuilders } = useHomeFieldSchemaFactory();
  const navigate = useNavigate();

  const formRef =
    useRef<FieldFormV2Module<Evaluation_HomeStandardEvaluationFormModel>>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [applicationFormModel, setApplicationFormModel] =
    useState<Evaluation_HomeStandardEvaluationFormModel>();

  const [isInitialized, setIsInitialized] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isLoading && !isInitialized) {
      setIsInitialized(true);
      props.onInitialized?.();
    }
  }, [isLoading]);

  useEffect(() => {
    if (isInitialized) {
      props.onInitialized?.();
    }
  }, [isInitialized]);

  useEffect(() => {
    //Used to React to External Changes Pushed on the Form (Iteration Engine)
    if (applicationFormModel) {
      setApplicationFormModel({ ...applicationFormModel });
    }
  }, [wieBuilders]);

  useEffect(() => {
    fetchApplicationForm();
  }, []);

  useEffect(() => {
    if (!!applicationFormModel) {
      setApplicationFormModel(applicationFormModel);

      if (props.setFormModel) {
        props.setFormModel(applicationFormModel);
      }
    }
  }, [applicationFormModel]);

  // Differentiates between save and submit
  let shouldSubmit = false;
  const setShouldSubmit = (value: boolean) => {
    shouldSubmit = value;
  };

  const isFirstPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
    )[0];

  const isLastPartOfForm =
    props.formPart ===
    Object.values(
      BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
    ).pop();

  const noBuilders = !builders && !wieBuilders;

  const fetchApplicationForm = async () => {
    setIsLoading(true);
    await homeStandardEvaluationAPIClient
      .getHomeStandardEvaluationFormModel(props.evaluationId)
      .then((response) => {
        setApplicationFormModel((prevModel) => ({
          ...prevModel,
          ...response,
        }));
      })
      .catch(() => {
        errorStore.setErrorMessage(genericErrorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Debounce setup
  const handleFormSubmitDebounced = useCallback(
    debounce(
      async (
        form: Evaluation_HomeStandardEvaluationFormModel,
        _value: any,
        fieldProps: BaseFieldProps<any>
      ) => {
        if (fieldProps.fieldKey) {
          await handleFormSubmit(form, fieldProps.fieldKey).then(async () => {
            await props.onFormSave?.(fieldProps.fieldKey || "", _value);
          });
        }
      },
      2000
    ),
    []
  );

  useEffect(() => {
    // Cleanup the debounce function on component unmount
    return () => handleFormSubmitDebounced.cancel();
  }, [handleFormSubmitDebounced]);

  const onFormFieldChange = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    _value: any,
    fieldProps: BaseFieldProps<any>
  ) => {
    // Call the debounced submit function
    if (!!_value) {
      handleFormSubmitDebounced(form, _value, fieldProps);
      props.setHasUnsavedChanges(true);
    }
  };

  const getApplicationFormPartBuilder = () => {
    if (props.isIterationEnginePage) {
      return wieBuilders?.HomeStandardIterationEngineEngineeringComplianceContinuousLoadPathForm;
    }
    switch (props.formPart) {
      case BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForNewConstruction:
        return builders?.HomeStandardEngineeringComplianceFormContinuousLoadPathForNewConstruction;
      case BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts.ContinuousLoadPathForRetrofitOfExistingHome:
        return builders?.HomeStandardEngineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHome;
      default:
        return builders?.HomeStandardEngineeringComplianceContinuousLoadPathForm;
    }
  };

  const handleFormSubmit = async (
    form: Evaluation_HomeStandardEvaluationFormModel,
    fieldKey?: string
  ) => {
    setApplicationFormModel(form);
    await (shouldSubmit
      ? homeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient.submitHomeStandardEngineeringComplianceContinuousLoadPathForm(
          form.id,
          form.homeStandardEvaluation
            .engineeringComplianceContinuousLoadPathForm,
          props.formPart
        )
      : homeStandardEvaluationEngineeringComplianceContinuousLoadPathAPIClient.updateHomeStandardEngineeringComplianceContinuousLoadPathForm(
          form.id,
          form.homeStandardEvaluation
            .engineeringComplianceContinuousLoadPathForm,
          fieldKey
        )
    )
      .then(async (response) => {
        handleFormResponse(response);
      })
      .catch((error) => {
        if (error.response.status !== 400) {
          console.error(error);
          AppToaster.show({
            message: "Unexpected error occurred while saving the form",
            intent: Intent.DANGER,
          });
        }
        throw error;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleFormResponse = async (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    props.setHasUnsavedChanges(false);

    if (shouldSubmit) {
      setApplicationFormModel(response);

      if (isLastPartOfForm) {
      } else {
        // Navigate to next part of form
        const nextFormPart = Object.values(
          BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
        )[
          Object.values(
            BaseHomeEvaluationEngineeringComplianceContinuousLoadPathFormParts
          ).indexOf(props.formPart!) + 1
        ].valueOf();

        navigate(
          `${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/engineering-compliance-continuous-load-path/${nextFormPart}`
        );
      }

      // }
    } else {
      handlePartialResponseFormUpdate(response);
    }
  };

  const handlePartialResponseFormUpdate = (
    response: Evaluation_HomeStandardEvaluationFormModel
  ) => {
    // This is used to combat save on blur issues. This only updates fields that are essential to the form.
    // These are set in the API response. Field Counts and sub section completion status
    setApplicationFormModel((prevState) => {
      if (!prevState) return prevState;

      const {
        areAllRequiredFieldsComplete,
        ContinuousLoadPathForNewConstructionRequiredFieldCount,
        ContinuousLoadPathForNewConstructionLastSubmittedAt,
        ContinuousLoadPathForRetrofitOfExistingHomeRequiredFieldCount,
        ContinuousLoadPathForRetrofitOfExistingHomeLastSubmittedAt,
        attestation234,
        attestation235,
        attestation236,
        attestation237,
        attestation238,
        attestation239,
        doesHomeIncludeConcreteCMUWalls,
        attestation241,
        attestation242,
        attestation243,
        attestation244,
        installedFoundationType,
        installedFoundationTypeDescription,
        attestation246,
        attestation301,
        attestation247,
        attestation248,
        attestation249,
        werePrescriptiveDesignStandardsUtilized,
        attestation251,
        attestation252,
        attestation253,
        attestation254,
        attestation255,
        installedRoofToWallConnectionsDescription,
        attestation258,
        isHomeMoreThan1Story,
        attestation260,
        installedWallAboveToWallBelowConnectionsDescriptionType,
        attestation262,
        attestation263,
        attestation264,
        attestation265,
        attestation266,
        attestation267,
      } =
        response.homeStandardEvaluation
          .engineeringComplianceContinuousLoadPathForm;

      const { canProceedToApplicationFee } = response.homeStandardEvaluation;

      return {
        ...prevState,
        homeStandardEvaluation: {
          ...prevState.homeStandardEvaluation,
          canProceedToApplicationFee,
          engineeringComplianceContinuousLoadPathForm: {
            ...prevState.homeStandardEvaluation
              .engineeringComplianceContinuousLoadPathForm,
            areAllRequiredFieldsComplete,
            ContinuousLoadPathForNewConstructionRequiredFieldCount,
            ContinuousLoadPathForNewConstructionLastSubmittedAt,
            ContinuousLoadPathForRetrofitOfExistingHomeRequiredFieldCount,
            ContinuousLoadPathForRetrofitOfExistingHomeLastSubmittedAt,
            attestation234,
            attestation235,
            attestation236,
            attestation237,
            attestation238,
            attestation239,
            doesHomeIncludeConcreteCMUWalls,
            attestation241,
            attestation242,
            attestation243,
            attestation244,
            installedFoundationType,
            installedFoundationTypeDescription,
            attestation246,
            attestation301,
            attestation247,
            attestation248,
            attestation249,
            werePrescriptiveDesignStandardsUtilized,
            attestation251,
            attestation252,
            attestation253,
            attestation254,
            attestation255,
            installedRoofToWallConnectionsDescription,
            attestation258,
            isHomeMoreThan1Story,
            attestation260,
            installedWallAboveToWallBelowConnectionsDescriptionType,
            attestation262,
            attestation263,
            attestation264,
            attestation265,
            attestation266,
            attestation267,
          },
        },
      };
    });
  };

  return {
    builders,
    wieBuilders,
    formRef,
    isLoading,
    isSubmitting,
    setIsSubmitting,
    applicationFormModel,
    isFirstPartOfForm,
    isLastPartOfForm,
    getApplicationFormPartBuilder,
    handleFormSubmit,
    onFormFieldChange,
    noBuilders,
    containerRef,
    setShouldSubmit,
  };
}

export default useHomeStandardEvaluationEngineeringComplianceContinuousLoadPathForm;
