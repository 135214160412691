import baseApiClient from "../../../../../foritfied/lib/apiClients/baseApiClient";
import { BaseHomeEvaluationEngineerConfirmationFormParts } from "../../../common/types/BaseHomeEvaluationEngineerConfirmationForm";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../types/HomeEvaluationFormModel";
import { HomeStandardEvaluationEngineerConfirmationFormModel } from "../../types/HomeStandardEvaluationEngineerConfirmationFormModel";

class HomeStandardEvaluationEngineerConfirmationAPIClient {
  updateHomeStandardEngineerConfirmationForm = async (
    evaluationId: string,
    engineerConfirmationForm: HomeStandardEvaluationEngineerConfirmationFormModel,
    fieldKey?: string
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    return baseApiClient.put<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineer-confirmation-form/${evaluationId}`,
      {
        engineerConfirmationForm,
        fieldKey,
      }
    );
  };

  submitHomeStandardEngineerConfirmationForm = async (
    evaluationId: string,
    engineerConfirmationForm: HomeStandardEvaluationEngineerConfirmationFormModel,
    formPart?: BaseHomeEvaluationEngineerConfirmationFormParts
  ): Promise<Evaluation_HomeStandardEvaluationFormModel> => {
    // If applicationFormPart is not provided, default to the last part of the form
    const formPartValue = (
      formPart ??
      (Object.values(
        BaseHomeEvaluationEngineerConfirmationFormParts
      ).pop() as BaseHomeEvaluationEngineerConfirmationFormParts)
    ).valueOf();
    return baseApiClient.post<Evaluation_HomeStandardEvaluationFormModel>(
      `/home-standard/engineer-confirmation-form/${evaluationId}/${formPartValue}`,
      engineerConfirmationForm
    );
  };
}

export const homeStandardEvaluationEngineerConfirmationAPIClient =
  new HomeStandardEvaluationEngineerConfirmationAPIClient();
