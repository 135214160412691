import { PrimaryCard, SecondaryCard } from "@ucl/library";
import { Loading } from "@ucl/library/lib/components/Loading/Loading";
import { Navigate } from "react-router-dom";
import FormChecklistProgress from "../../../../../../Common/components/Forms/FormChecklistProgress/FormChecklistProgress";
import { fortifiedHomeStandardRoutePrefix } from "../../../../../../../foritfied/pages/index/fortifiedHomeRouteConfig";
import { FieldSchemaFactoryProvider } from "../../../../../customHooks/useHomeFieldSchemaFactory";
import { BaseHomeEvaluationEngineeringComplianceGablesFormParts } from "../../../../../common/types/BaseHomeEvaluationEngineeringComplianceGablesForm";
import useHomeStandardEngineeringComplianceGablesFormContainer from "./useHomeStandardEngineeringComplianceGablesFormContainer";
import HomeStandardEvaluationEngineeringComplianceGablesForm from "../Form/HomeStandardEvaluationEngineeringComplianceGablesForm";

export interface HomeStandardEngineeringComplianceGablesFormContainerProps {
  evaluationId: string;
  engineeringComplianceGablesFormPart?: BaseHomeEvaluationEngineeringComplianceGablesFormParts;
}

export const HomeStandardEngineeringComplianceGablesFormContainer = (
  props: HomeStandardEngineeringComplianceGablesFormContainerProps
) => {
  const {
    applicationChecklistParts,
    getApplicationPartChecklistStatus,
    setEvaluationFormModel,
    setHasUnsavedChanges,
    applicationFormPart,
    getBestFormPart,
    commercialSchemaFactory,
  } = useHomeStandardEngineeringComplianceGablesFormContainer(props);

  return (
    <>
      {!applicationFormPart ||
      !Object.values(
        BaseHomeEvaluationEngineeringComplianceGablesFormParts
      ).includes(applicationFormPart) ? (
        // TODO: Show error page if the application form part is not valid
        <Navigate
          to={`${fortifiedHomeStandardRoutePrefix}/${
            props.evaluationId
          }/engineering-compliance-gables/${getBestFormPart().valueOf()}`}
        />
      ) : !commercialSchemaFactory ? (
        <Loading />
      ) : (
        <div className="wildfire-form-page">
          <div className="wildfire-form-progress">
            <div className="wildfire-form-progress__steps">
              <FormChecklistProgress
                checklistParts={applicationChecklistParts}
                evaluationId={props.evaluationId}
                formURL={`${fortifiedHomeStandardRoutePrefix}/${props.evaluationId}/engineering-compliance-gables`}
                title={"Engineer Confirmation"}
                activeFormPart={applicationFormPart}
                getChecklistStatus={getApplicationPartChecklistStatus}
              />
            </div>
          </div>
          <section className="wildfire-form-content">
            <div className="wildfire-form-content__header"></div>
            <PrimaryCard
              className="wildfire-form-primary-card"
              headerContent={
                <div className="wildfire-form-primary-card__header">
                  Home Standard Silver/Gold Compliance General Information Form:{" "}
                  {
                    applicationChecklistParts.find(
                      (value) => value.formPart === applicationFormPart
                    )?.displayName
                  }
                </div>
              }
            >
              <SecondaryCard
                className="wildfire-form-secondary-card"
                hideBody={false}
                isCollapsible={false}
              >
                <FieldSchemaFactoryProvider
                  schemaFactory={commercialSchemaFactory}
                >
                  <HomeStandardEvaluationEngineeringComplianceGablesForm
                    key={applicationFormPart}
                    evaluationId={props.evaluationId}
                    isIterationEnginePage={false}
                    formPart={applicationFormPart}
                    disableForm={false}
                    setFormModel={setEvaluationFormModel}
                    setHasUnsavedChanges={setHasUnsavedChanges}
                  />
                </FieldSchemaFactoryProvider>
              </SecondaryCard>
            </PrimaryCard>
          </section>
        </div>
      )}
    </>
  );
};
export default HomeStandardEngineeringComplianceGablesFormContainer;
