import { FieldSchemaFormBuilderV2, SchemaFactoryV2 } from "@ucl/library";
import { Evaluation_HomeStandardEvaluationFormModel } from "../../../types/HomeEvaluationFormModel";
import { BaseFieldProps } from "@ucl/library/lib/components/Fields/types/fieldTypes";
import { WildfireBoxContentExplorer } from "../../../../../../wildfire/components/Fields/WildfireBoxContentExplorer/WildfireBoxContentExplorer";

const modelName = "Evaluation";
const subModelName = "HomeStandardEvaluation";
const formName = "EngineeringComplianceContinuousLoadPathForm";

const buildFieldKey = (fieldName: string) =>
  `${modelName}__${subModelName}__${formName}__${fieldName}`;

// Part 1: Installer Compliance Form Continuous Load Path For New Construction
const engineeringComplianceFormContinuousLoadPathForNewConstructionFields =
  () => [
    buildFieldKey("Attestation234"),
    buildFieldKey("Attestation235"),
    buildFieldKey("Attestation236"),
    buildFieldKey("Attestation237"),
    buildFieldKey("Attestation238"),
    buildFieldKey("Attestation239"),
    buildFieldKey("DoesHomeIncludeConcreteCMUWalls"),
    buildFieldKey("Attestation241"),
    buildFieldKey("Attestation242"),
    buildFieldKey("Attestation243"),
    buildFieldKey("Attestation244"),
    buildFieldKey("InstalledFoundationType"),
    buildFieldKey("InstalledFoundationTypeDescription"),
    buildFieldKey("Attestation246"),
    buildFieldKey("Attestation301"),
    buildFieldKey("Attestation247"),
    buildFieldKey("Attestation248"),
    buildFieldKey("Attestation249"),
    buildFieldKey("WerePrescriptiveDesignStandardsUtilized"),
    buildFieldKey("Attestation251"),
    buildFieldKey("Attestation252"),
    buildFieldKey("Attestation253"),
    buildFieldKey("Attestation254"),
    buildFieldKey("Attestation255"),
    buildFieldKey("InstalledRoofToWallConnectionsDescription"),
    buildFieldKey("Attestation258"),
    buildFieldKey("IsHomeMoreThan1Story"),
    buildFieldKey("Attestation260"),
    buildFieldKey("InstalledWallAboveToWallBelowConnectionsDescriptionType"),
    buildFieldKey("Attestation262"),
    buildFieldKey("Attestation263"),
    buildFieldKey("Attestation264"),
    buildFieldKey("Attestation265"),
  ];

const engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields =
  [
    buildFieldKey("Attestation234"),
    buildFieldKey("Attestation235"),
    buildFieldKey("Attestation236"),
    buildFieldKey("Attestation237"),
    buildFieldKey("Attestation238"),
    buildFieldKey("Attestation239"),
    buildFieldKey("DoesHomeIncludeConcreteCMUWalls"),
    buildFieldKey("Attestation241"),
    buildFieldKey("Attestation242"),
    buildFieldKey("Attestation243"),
    buildFieldKey("Attestation244"),
    buildFieldKey("InstalledFoundationType"),
    buildFieldKey("InstalledFoundationTypeDescription"),
    buildFieldKey("Attestation246"),
    buildFieldKey("Attestation301"),
    buildFieldKey("Attestation247"),
    buildFieldKey("Attestation248"),
    buildFieldKey("Attestation249"),
    buildFieldKey("WerePrescriptiveDesignStandardsUtilized"),
    buildFieldKey("Attestation251"),
    buildFieldKey("Attestation252"),
    buildFieldKey("Attestation253"),
    buildFieldKey("Attestation254"),
    buildFieldKey("Attestation255"),
    buildFieldKey("InstalledRoofToWallConnectionsDescription"),
    buildFieldKey("Attestation258"),
    buildFieldKey("IsHomeMoreThan1Story"),
    buildFieldKey("Attestation260"),
    buildFieldKey("InstalledWallAboveToWallBelowConnectionsDescriptionType"),
    buildFieldKey("Attestation262"),
    buildFieldKey("Attestation263"),
    buildFieldKey("Attestation264"),
    buildFieldKey("Attestation265"),
  ];

export const engineeringComplianceFormContinuousLoadPathForNewConstructionBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...engineeringComplianceFormContinuousLoadPathForNewConstructionFields(),
          ],
          onValueChange,
          form,
          errors,
          engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Part 2: engineering Compliance Form Continuous Load Path For Retrofit Of Existing Home
const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields =
  () => [buildFieldKey("Attestation266"), buildFieldKey("Attestation267")];

const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields =
  [buildFieldKey("Attestation266"), buildFieldKey("Attestation267")];

export const engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeBuilder =
  (
    factory: SchemaFactoryV2
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      const fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(),
          ],
          onValueChange,
          form,
          errors,
          engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields
        ),
      ];
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };

// Full form builder with all parts included
export const homeStandardEngineeringComplianceContinuousLoadPathBuilder = (
  factory: SchemaFactoryV2
): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
  return ((onValueChange, form, errors) => {
    const fields = [
      ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
        modelName,
        [
          ...engineeringComplianceFormContinuousLoadPathForNewConstructionFields(),
          ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(),
        ],
        onValueChange,
        form,
        errors,
        [
          ...engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
          ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
        ],
        {
          FileUploader: WildfireBoxContentExplorer,
        }
      ),
    ];
    return fields;
  }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
};

// Full form builder with all parts included, for Iteration Engine
export const homeStandardIterationEngineEngineeringComplianceContinuousLoadPathFormBuilder =
  (
    factory: SchemaFactoryV2,
    beforeBuild?: (fields: BaseFieldProps<any>[]) => BaseFieldProps<any>[]
  ): FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel> => {
    return ((onValueChange, form, errors) => {
      let fields = [
        ...factory.buildFieldSchemas<Evaluation_HomeStandardEvaluationFormModel>(
          modelName,
          [
            ...engineeringComplianceFormContinuousLoadPathForNewConstructionFields(),
            ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeFields(),
          ],
          onValueChange,
          form,
          errors,
          [
            ...engineeringComplianceFormContinuousLoadPathForNewConstructionRequiredFields,
            ...engineeringComplianceFormContinuousLoadPathForRetrofitOfExistingHomeRequiredFields,
          ],
          {
            FileUploader: WildfireBoxContentExplorer,
          }
        ),
      ];
      if (beforeBuild) {
        fields = beforeBuild(fields);
      }
      return fields;
    }) as FieldSchemaFormBuilderV2<Evaluation_HomeStandardEvaluationFormModel>;
  };
