import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { FC } from "react";
import { reactPlugin } from "../../../../AppInsights";
import { UnSavedChangesBlockerProvider } from "../../../../wildfire/components/Provider/UnsavedChangesBlockerContext";
import { BaseHomeEvaluationRedesignationFormParts } from "../../common/types/BaseHomeEvaluationRedesignationForm";
import HomeNCIUARedesignationFormContainer from "../components/Forms/RedesignationForm/Container/HomeNCUIARedesignationFormContainer";

export interface HomeNCIUARedesignationFormPageProps {
  evaluationId?: string;
  redesignationFormPart?: BaseHomeEvaluationRedesignationFormParts;
}

const HomeNCIUARedesignationFormPageComponent: FC<
  HomeNCIUARedesignationFormPageProps
> = (props) => {
  return (
    <>
      {props.evaluationId && (
        <UnSavedChangesBlockerProvider>
          <HomeNCIUARedesignationFormContainer
            evaluationId={props.evaluationId}
            redesignationFormPart={props.redesignationFormPart}
          />
        </UnSavedChangesBlockerProvider>
      )}
    </>
  );
};

export const HomeNCIUARedesignationFormPage = withAITracking(
  reactPlugin,
  HomeNCIUARedesignationFormPageComponent,
  "HomeNCIUARedesignationFormPage",
  "tracking-container"
);
